import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'numb',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'base',
    sx: {
      '&:active, &[data-active="true"]': {
        color: 'beta.400',
        textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
        fontWeight: 'extrabold',
        bg: 'alpha.600',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      },
    },
  },
};

export const FlexOdds: CSSObject = {
  fontWeight: 'black',
  alignItems: 'center',
  justifyContent: 'center',
  px: '2',
  py: '1',
  minW: '10',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  mr: '0',
  h: '30px',
  w: '12',
  border: 'none',
  fontSize: 'xs',
  textShadow: '1px 1px 0px rgba(255, 255, 255, 0.20)',
  color: 'gray.500',
  bg: 'gray.300',
  borderRadius: 'base',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '1',
  alignItems: 'center',
  flexDirection: 'row',
};
