import { AxiosResponse } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { keys } from '../../api.keys';
import { queryPromotions } from './deposit';
import {
  TQueryPromotionsParams,
  TQueryPromotionsResponse,
} from './deposit.types';

export const useQueryPromotions = (
  params: TQueryPromotionsParams,
  options?: Omit<
    UseQueryOptions<AxiosResponse<TQueryPromotionsResponse>>,
    'queryKey' | 'queryFn'
  >
) =>
  useQuery([keys.promotions, params], () => queryPromotions(params), options);
