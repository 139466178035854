import { CSSObject } from '@chakra-ui/react';
import bg from '@/assets/fiestabet/images/backgrounds/breadcrumb.png';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    bgImage: `url(${bg})`,
    borderRadius: 'xl',
    py: '2.5',
    px: '3.5',
    boxShadow: '-1px 2px 2px 0px #DC4324 inset, 0px 3px 0px 0px #83090D',
    mb: '3',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
        gap: '2',
      },
    },
  },
  breadcrumbItemBreadcrumb: {
    gap: '1.5',
  },
  breadcrumbLinkItem: {
    color: 'epsilon.100',
    textShadow: '0px 1px 5px rgba(0, 0, 0, 0.40)',
    textDecoration: 'underline',

    _hover: {
      textDecoration: 'none',
    },

    sx: {
      '&:not([href])': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  display: 'block',
  color: 'epsilon.100',
  m: '0',
};
