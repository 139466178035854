import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        w: '14',
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
          color: 'beta.500',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
          border: 'none',
        },
      },

      '&[data-closed="true"]': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'white',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'white',
  textTransform: 'uppercase',
};

export const InfoContainer: CSSObject = {
  my: '0',
};

export const ButtonGroupWrapper: CSSObject = {
  pt: '1',
  pb: '3',
  gap: '1',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'beta.500',
  bg: 'white',
  borderRadius: 'md',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'white',
};

export const ResultedStatusLabel: CSSObject = {
  color: 'beta.900',
  bg: 'beta.500',
  px: '1',
  py: '1',
  fontSize: '2xs',
  fontWeight: 'bold',
  borderRadius: 'base',
};

export const RaceStatusText: CSSObject = {
  bg: 'gray.500',
  p: '1',
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '1',
  fontSize: ['2xs', null, 'sm'],
  fontWeight: 'medium',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  color: 'black',
  fontSize: ['2xs', null, 'sm'],
  '&&': {
    w: '14',
    p: '0',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  pb: '2',
  mr: '2',
  alignItems: 'center',
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const TextRaceConditions: CSSObject = {
  color: 'white',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    pos: 'relative',
    mx: '1.5',
    bg: 'beta.500',
  },
};
