import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckmarkOutline } from 'styled-icons/evaicons-outline';
import { ContentRow } from '../../styles/MyBetsCard.styles';

import {
  EBetTypes,
  EEventType,
  TConfirmedBet,
  TMyBetsMultiBetLegs,
} from '@/lib/DBModels';
import { formatDayDateMonthTime, showCountdownTimer } from '@/lib/Time';
import {
  BetReturn,
  BonusBetCheckMark,
  BonusBetText,
  bottomRowStyles,
  BoxBetReturnText,
  BoxBetReturnValue,
  iconLegProps,
  LinkVenueName,
  RaceTime,
} from './styles/BottomRow.styles';
import Countdown from '../../../../../../../components/Countdown/Countdown';
import { centsToDollars, getStrings } from '@/helpers/utils';
import {
  getEventDetails,
  getEventHref,
  getEventStart,
  getStatusIconStyles,
} from './BottomRow.hooks';
import { getStatus } from '../HeaderRow/HeaderRow.hooks';
import { Button } from '@/components/Button/Button';
import { getPromoDetails } from '@/views/account/MyBets/services/MyBets.utils';
import IconSvg from '@/components/common/IconSvg';

// This component is used for viewing 1) individual multi legs and 2) bets
// in the MyBets view
// If leg param is populated, it's a multi leg
// Otherwise it's a bet
const BottomRow: React.FC<TBottomRow> = ({
  bet,
  isResulted,
  leg,
  onCashoutClick,
}) => {
  const [
    {
      Account: { MyBetsCard: Strings },
    },
  ] = getStrings();

  const eventStart = getEventStart(bet, leg);
  const hasStarted = dayjs().isAfter(dayjs(new Date(eventStart ?? new Date())));
  const isMatch = bet.event_type === EEventType.Match;
  const isSrm = bet.bet_description === EBetTypes.SRMulti;
  const isSGM = bet.bet_description === EBetTypes.SGMulti;
  const isBlended = bet.bet_description === EBetTypes.Blended;

  const isTimerVisible = showCountdownTimer(eventStart);
  const eventHref = getEventHref(bet, leg);
  const eventDetails = getEventDetails(bet, leg);
  const { originalOdds, originalPayout } = getPromoDetails(bet);

  const betLegStatusIcons = () => {
    if (bet?.bet_legs) {
      return bet.bet_legs.map((bLeg) => {
        if (bLeg.bet_description === 'Blended') {
          const legStatus = getStatus(undefined, bLeg);
          const iconStyles = getStatusIconStyles(legStatus);
          return (
            <Icon
              boxSize={iconStyles?.boxSize || '5'}
              as={iconStyles?.icon}
              key={bLeg.bet_leg_id}
              borderRadius="full"
              bg={iconStyles?.bg}
              border={iconStyles?.border}
              borderColor={iconStyles?.borderColor}
              color={iconStyles?.color}
              {...iconLegProps}
            />
          );
        }

        if (bLeg.legs) {
          return bLeg.legs.map((l) => {
            const legStatus = getStatus(undefined, l);
            const iconStyles = getStatusIconStyles(legStatus);
            return (
              <Icon
                boxSize={iconStyles?.boxSize || '5'}
                as={iconStyles?.icon}
                key={l.bet_leg_id}
                borderRadius="full"
                bg={iconStyles?.bg}
                border={iconStyles?.border}
                borderColor={iconStyles?.borderColor}
                color={iconStyles?.color}
                {...iconLegProps}
              />
            );
          });
        }

        // Render the bet leg if it doesn't have nested legs
        const legStatus = getStatus(undefined, bLeg);
        const iconStyles = getStatusIconStyles(legStatus);
        return (
          <Icon
            boxSize={iconStyles?.boxSize || '5'}
            as={iconStyles?.icon}
            key={bLeg.bet_leg_id}
            borderRadius="full"
            bg={iconStyles?.bg}
            border={iconStyles?.border}
            borderColor={iconStyles?.borderColor}
            color={iconStyles?.color}
            {...iconLegProps}
          />
        );
      });
    }
    return null;
  };

  const isOddsBoostBet = bet.promo_tokens?.find(
    (token) => token.token_type === 'odds-boost'
  );

  const moneyBackBet = bet.promotions?.find(
    (promotion) => promotion.type === 'money_back'
  );

  const hasRollover =
    bet?.price_type === 'mystery_bet' && (bet?.bet_legs?.length ?? 0) > 1;

  let potentialReturn;
  switch (bet?.price_type) {
    case 'starting':
    case 'tote_single_mid':
    case 'tote_single_best':
      potentialReturn = <FormattedMessage id="generic.na" />;
      break;
    case 'mystery_bet':
      potentialReturn = bet?.odds
        ? (hasRollover
            ? bet.odds * (bet.odds - (bet.is_bonus ? 1 : 0))
            : bet.odds - (bet.is_bonus ? 1 : 0)) * Number(bet.stake)
        : undefined;

      potentialReturn = centsToDollars(potentialReturn);
      break;
    default:
      potentialReturn = bet?.odds
        ? (bet.odds - (bet.is_bonus ? 1 : 0)) * Number(bet.stake)
        : undefined;
      potentialReturn = centsToDollars(potentialReturn);
      break;
  }

  return (
    <>
      <ContentRow>
        {!leg && bet.bet_legs ? (
          <Flex gap="1" flexWrap="wrap">
            {betLegStatusIcons()}
          </Flex>
        ) : (
          <>
            {isSrm ||
              isBlended ||
              (!isSrm && !isSGM && (
                <Flex {...bottomRowStyles.venueRaceWrapper}>
                  <LinkVenueName
                    data-cy="myBetsCardVenueName"
                    to={eventHref}
                    {...(hasStarted &&
                      isMatch && {
                        as: 'p',
                      })}
                  >
                    {eventDetails}
                  </LinkVenueName>
                  <Flex gap="1" alignItems="baseline">
                    <RaceTime data-cy="myBetsCardRaceTime">
                      {formatDayDateMonthTime(eventStart)}
                    </RaceTime>
                    {isTimerVisible && !isResulted && eventStart && (
                      <Countdown eventTime={eventStart} />
                    )}
                  </Flex>
                </Flex>
              ))}
          </>
        )}
      </ContentRow>
      {isOddsBoostBet && (
        <Flex {...bottomRowStyles.flexOddsBoostBanner}>
          <IconSvg name="GaugeHighSolid" sx={bottomRowStyles.iconOddsBoost} />
          <Text {...bottomRowStyles.textOddsBoost}>
            <FormattedMessage id="account.myBetsCard.boostedOdds" />
          </Text>
        </Flex>
      )}
      {moneyBackBet && moneyBackBet.bonus_amount && (
        <Flex {...bottomRowStyles.flexOddsBoostBanner}>
          <IconSvg name="money-back" sx={bottomRowStyles.iconOddsBoost} />
          <Text {...bottomRowStyles.textOddsBoost}>
            <FormattedMessage id="account.myBetsCard.moneyBack" />
          </Text>
        </Flex>
      )}
      {(bet.is_bonus || bet.status === 'pending') && !leg && (
        <ContentRow mt="1" e>
          {bet.is_bonus && (
            <Flex gap="1" width="-webkit-fill-available">
              <BonusBetCheckMark as={CheckmarkOutline} />
              <BonusBetText>{Strings.BonusApplied}</BonusBetText>
            </Flex>
          )}

          <VStack {...bottomRowStyles.vstackBetsWrapper}>
            {bet.cashout_value ? (
              <Flex {...bottomRowStyles.flexWrapper}>
                <Button
                  onClick={() => onCashoutClick?.(bet, true)}
                  {...bottomRowStyles.buttonCashOutDesc}
                >
                  <IconSvg name="cashout" {...bottomRowStyles.iconCashOut} />
                  <Text {...bottomRowStyles.cashoutText}>
                    <FormattedMessage id="generic.cashOut" />
                  </Text>
                  <Text
                    {...bottomRowStyles.cashoutValue}
                    className="cashoutValue"
                  >
                    {centsToDollars(bet.cashout_value)}
                  </Text>
                </Button>
              </Flex>
            ) : undefined}
            {bet.status === 'pending' && !!bet.odds && !!bet.stake && (
              <Flex gap="1" alignSelf="flex-end">
                <BetReturn data-cy="myBetsCardBetReturn">
                  <Flex>
                    <BoxBetReturnText as="span">
                      <FormattedMessage
                        id={
                          hasRollover
                            ? 'account.mybetscard.combinedEstReturn'
                            : 'account.mybetscard.potentialreturns'
                        }
                      />
                    </BoxBetReturnText>
                    {originalOdds && (
                      <BoxBetReturnValue
                        as="span"
                        textDecoration="line-through"
                        opacity="0.5"
                      >
                        {centsToDollars(originalPayout)}
                      </BoxBetReturnValue>
                    )}
                    <BoxBetReturnValue as="span">
                      {potentialReturn}
                    </BoxBetReturnValue>
                  </Flex>
                </BetReturn>
              </Flex>
            )}
          </VStack>
        </ContentRow>
      )}
    </>
  );
};

export type TBottomRow = {
  bet: TConfirmedBet;
  isResulted: boolean | undefined;
  potentialReturns?: number;
  leg?: TMyBetsMultiBetLegs;
  onCashoutClick?: (bet: TConfirmedBet, isOpen: boolean) => void;
};

export default BottomRow;
