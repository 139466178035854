import { CSSObject } from '@chakra-ui/react';
import betslipBgTexture from '@/assets/templebet/images/feature/feature-race-texture.png';
import { FeaturedRaceSchema } from '@/views/Home/races/components/Featured/styles/Featured.styles';

export const featuredRaceStyles: FeaturedRaceSchema = {
  buttonOddsProps: {
    variant: 'odds',
    borderRadius: 'lg',
    minW: '8',
    w: 'auto',
    fontSize: 'xs',
    px: '2',
  },
};

export const BoxCard: CSSObject = {
  px: '2',
  pt: '0',
  pb: '2',
  display: 'flex',
  flexDirection: 'column',
  alignItems: ['center', null, 'start'],
  '> div': {
    w: '100%',
  },
};

export const FlexFeatureWrapper: CSSObject = {
  bg: `linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%), url(${betslipBgTexture})`,
  boxShadow:
    '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
  bgSize: '100% auto',
  backgroundPositionY: 'top',
  backgroundBlendMode: 'darken',
  borderRadius: '2xl',
  border: '6px solid',
  borderColor: '#FFF1CB',
  flex: '2',
  pos: 'relative',
  transformStyle: 'preserve-3d',
  contain: 'paint',
  flexDir: 'column',
  minH: '220px',
  pt: ['1', '4'],
  pb: '2',
  px: '3',
  mb: '5',
  mt: ['2', '0'],
  mx: '0',
};

export const FlexFeatureTitleWrapper: CSSObject = {
  flexDir: 'row',
  bgPos: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
  pt: '0',
  transformStyle: 'preserve-3d',
  pos: 'relative',
  contain: 'paint',
  flex: '1.5',
  textShadow: 'unset',
  p: {
    color: 'beta.800',
    fontFamily: 'accent',
    fontWeight: 'hairline',
    fontSize: '3xl',
    mr: 'auto',
    mb: '0',
    boxShadow: 'unset',
    m: ['auto', null, 'unset'],
  },
};

export const TextFeatureHeading: CSSObject = {
  display: 'none',
};

export const TextFeatureSubHeading: CSSObject = {
  color: 'beta.800',
  fontFamily: 'accent',
  fontWeight: 'hairline',
  fontSize: '3xl',
  ml: '2',
  mb: '0',
  boxShadow: 'unset',
  span: {
    fontWeight: 'hairline',
  },
};

export const FlexGridRunnerItem: CSSObject = {
  borderBottom: '0',
  py: '0.5',
  px: '1',
};

export const GridCardContent: CSSObject = {
  gap: '2',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: [`"."`, null, null, `". . ."`],
};

export const TextRunner: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextRunnerNumber: CSSObject = {
  color: 'white',
  fontSize: 'xs',
  fontWeight: 'bold',
  _last: {
    fontSize: '2xs',
    fontWeight: 'normal',
    pos: 'relative',
    top: '-2px',
  },
};

export const HeadingSlogan: CSSObject = {
  color: 'beta.400',
  fontFamily: 'heading',
  fontSize: '3xl',
  textTransform: 'uppercase',
};

export const LinkRaceDetails: CSSObject = {
  display: 'none',
};

export const LinkCardHeader: CSSObject = {
  justifyContent: ['center', 'start'],
  gap: '2',
  px: 0,
  pt: [0, 'unset'],
  w: 'fit-content',
  svg: {
    color: 'beta.800',
    boxSize: '22px',
    filter: 'drop-shadow(0px 3px 4px var(--bc-colors-blackAlpha-400))',
  },
};

export const TextCardHeader: CSSObject = {
  fontSize: 'lg',
  color: 'beta.800',
  fontFamily: 'heading',
  fontWeight: 'normal',
  textTransform: 'uppercase',
};

export const FlexFeatureCardWrapper: CSSObject = {
  alignItems: 'unset',
  pt: '4',
};

export const BoxGridHeaderItem: CSSObject = {
  display: 'none',
};

export const TextRaceNumber: CSSObject = {
  fontFamily: 'body',
  bg: 'delta.500',
  borderRadius: 'base',
  color: 'delta.900',
  px: '1',
  h: '4',
  maxH: '4',
  minH: '4',
  lineHeight: '4',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
};

export const FlexButtonsWrap: CSSObject = {
  justifyContent: 'space-between',
  w: 'full',
  minH: '50px',
  bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500), var(--bc-colors-alpha-600))',
  borderRadius: 'lg',
  boxShadow: '0px 4px 10px 0px var(--bc-colors-blackAlpha-400)',
  p: '1',
};

export const LinkFeatureCard: CSSObject = {
  display: 'none',
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'black',
  _hover: {
    textDecor: 'underline',
  },
};

export const TextScratched: CSSObject = {
  fontSize: '2xs',
  color: 'white',
  textTransform: 'uppercase',
};

export const Button = (isActive: boolean): CSSObject => ({
  w: '12',
  height: '34px',
  variant: 'solid',
  fontFamily: 'body',
  bgGradient: 'linear(to-b, beta.700, beta.800)',
  color: 'beta.500',
  textShadow: '0px 4px 4px var(--bc-colors-blackAlpha-20)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  borderRadius: 'md',
  letterSpacing: 'wide',
  fontWeight: 'black',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 1px var(--bc-colors-beta-900) inset,0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset',
  _hover: {
    bgGradient: 'linear(to-b, delta.400, delta.500)',
    color: 'white',
  },
  _focus: {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  _disabled: {
    bg: 'gray.400',
    borderColor: 'gray.600',
    color: 'gray.200',
    textShadow: '0px 1px 4px var(--bc-colors-blackAlpha-300)',
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-500)',
  },
  ...(isActive && {
    bgGradient: 'linear(to-b, beta.900, beta.900)',
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-500)',
  }),
});
