import React from 'react';
import { Global } from '@emotion/core';
import bg from '@/assets/chromabet/images/backgrounds/bg.png';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${bg})`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      }}
    />
  );
}
