/* eslint-disable import/no-mutable-exports */
import React, { ReactNode } from 'react';
import { Show } from '@chakra-ui/react';
import { TTemplate } from '@/helpers/createTemplate';
import {
  BoxHeading,
  BoxWrapper,
  TextTitle,
  TitleContainer,
  WrapperContainer,
} from '../styles/PrimaryWrapper.styles';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';

const template = (
  pageHeader?: ReactNode,
  pageHeaderChildren?: ReactNode,
  children?: any
): TTemplate => ({
  wrapper: <BoxWrapper />,
  pageHeader: (
    <BoxHeading>
      <Show above="sm">
        <Breadcrumb />
      </Show>

      <TitleContainer>
        {typeof pageHeader !== 'string' ? (
          pageHeader
        ) : (
          <TextTitle as="h1">{pageHeader}</TextTitle>
        )}
        {pageHeaderChildren}
      </TitleContainer>
    </BoxHeading>
  ),
  primaryWrapperContainer: <WrapperContainer>{children}</WrapperContainer>,
});

export default template;
