import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.500',
};

export const BoxMoreText: CSSObject = {
  w: '8',
};

export const DeductionsText: CSSObject = {
  color: 'gray.400',
  fontSize: '2xs',
  fontWeight: 'normal',
};
