import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    variant: 'tab',
    boxSize: '8',
    fontSize: 'xs',
    minW: '8',
    borderRadius: 'md',
    boxShadow:
      '0px 0px 0px 3px rgba(0, 0, 0, 0.30) inset, 1px 3px 5px 0px rgba(0, 0, 0, 0.45)',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 14,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        mb: '1',
        mt: '0',
      },
      '&[data-closed="true"]': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'gray.300',
        textShadow: 'unset',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        bg: 'gray.300',
        color: 'blackAlpha.600',
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
  pb: '1',
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  fontFamily: 'body',
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'beta.200',
    pos: 'relative',
    mx: '1.5',
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'delta.700',
  bg: 'beta.400',
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  bg: 'red.500',
};

export const RaceDetailsHeading: CSSObject = {
  color: 'gray.700',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  svg: {
    color: 'gray.700',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  mx: ['2', null, '4'],
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'md',
  bg: 'white',
  p: '2',
  pt: '1',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 4px var(--bc-colors-alpha-800) inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '0',
  pb: '0',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'beta.500',
      pb: '2px',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
  alignItems: 'center',
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '9',
  mr: '2',
  ml: '1',
  mt: ['1', null, 'unset'],
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: ['43px', null, '16'],
  },
  svg: {
    color: 'white',
  },
};

export const RaceRunnerWinPlaceContainer: CSSObject = {
  columnGap: '2',
};
