import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { resetFilters } from '../../../../redux/raceTable.slice';
import { useQueryPunterAccount } from '@/api/punter/punter.hooks';
import { useQueryOfferings } from '@/api/offerings/offerings.hooks';
import { useQueryCompetitions } from '@/api/sports/competitions.hooks';
import { useQuerySportMatches } from '@/api/sportDetailsMatches/sportDetailsMatches.hooks';

export const useRaceMeetingsNew = () => {
  const dispatch = useAppDispatch();
  const { raceMeetingsTime } = useParams();

  const { data: punterAccount } = useQueryPunterAccount();
  const { data: offerings } = useQueryOfferings({
    params: {
      pc: punterAccount?.pc,
    },
  });

  const futureId =
    offerings?.offered_racing_futures &&
    offerings?.offered_racing_futures?.sport_id;

  const queryCompetitions = useQueryCompetitions(
    {
      sport_id: futureId ?? '',
    },
    { enabled: !!futureId }
  );

  const competitionId =
    queryCompetitions.data?.data.competitions &&
    queryCompetitions.data?.data.competitions[0].competition_id;

  const { data: matches } = useQuerySportMatches(
    {
      competition_id: competitionId,
    },
    {
      enabled: !!futureId && !!competitionId,
      staleTime: 60 * 5000,
    }
  );

  const tabs = [
    'Yesterday',
    'Next To Jump',
    'Today',
    'Tomorrow',
    dayjs().add(2, 'day').format('dddd'),
  ];

  if (matches?.length) tabs.push('Futures');

  /**
   * On exit reset the filters
   */
  useEffect(
    () => () => {
      dispatch(resetFilters());
    },
    [dispatch]
  );

  return {
    tabs,
    matches,
    activeTab: raceMeetingsTime?.replaceAll('%20', ' '),
  };
};
