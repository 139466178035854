import React from 'react';
import { Fade } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { getStrings } from '@/helpers/utils';
import Input from '../../../../../../components/FormElements/Input';
import { useSelectionPopup } from '../../services/Exotics.hooks';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { EGeneralStatus } from '../../../../../../lib/DBModels';
import {
  buttonAddToBetslipProps,
  FlexButtonWrapper,
  FlexInputWrapper,
  FlexWrapper,
  InfoHeader,
  InfoText,
  SelectionPopupContainer,
  TextAddToBetslipButton,
} from './styles/SelectionPopup.styles';
import { Button } from '@/components/Button/Button';

const SelectionPopup: React.FC = () => {
  const [
    {
      Racing: { Exotics: Strings },
    },
  ] = getStrings();
  const intl = useIntl();

  const { raceRunnerList } = useAppSelector((state) => state.racing);
  const { validSelectionAmount } = useAppSelector((state) => state.exotics);
  const {
    totalStake,
    handleTotalStakeChange,
    flexi,
    handleFlexiChange,
    handleBetSlipAddition,
    handleClearSelections,
    roundStake,
  } = useSelectionPopup();

  if (raceRunnerList?.raceMeta?.status !== EGeneralStatus.Open) return <></>;

  return (
    <SelectionPopupContainer>
      <Fade in={validSelectionAmount > 0} role="dialog" unmountOnExit>
        <InfoHeader>
          <InfoText
            data-cy="clearSelectionLink"
            cursor="pointer"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
            onClick={handleClearSelections}
          >
            {intl.formatMessage({ id: 'racing.exotics.clearselection' })}
          </InfoText>
          <InfoText>
            {intl.formatMessage({ id: 'racing.exotics.combos' })}:{' '}
            {validSelectionAmount}
          </InfoText>
        </InfoHeader>
        <FlexWrapper>
          <FlexInputWrapper>
            <Input
              name="flexi"
              data-cy="flexiInputField"
              size="md"
              isFormik={false}
              label={intl.formatMessage({ id: 'generic.flexi' })}
              onChange={handleFlexiChange}
              placeholder="100"
              type="text"
              value={flexi || ''}
              InputLeftAddon="%"
            />

            <Input
              name="total-stake"
              data-cy="stakeInputField"
              size="md"
              isFormik={false}
              label={intl.formatMessage({ id: 'generic.stake' })}
              onChange={handleTotalStakeChange}
              onBlur={roundStake}
              placeholder="0.00"
              type="number"
              value={Number.isNaN(Number(totalStake)) ? '' : totalStake}
              InputLeftAddon="$"
            />
          </FlexInputWrapper>

          <FlexButtonWrapper>
            <Button
              data-cy="addToBetSlipExoticButton"
              onClick={() =>
                handleBetSlipAddition(
                  Number.isNaN(Number(totalStake)) ? '' : totalStake
                )
              }
              data-active={handleBetSlipAddition}
              {...buttonAddToBetslipProps}
            >
              <TextAddToBetslipButton as="span">
                {Strings.addToBetSlip}
              </TextAddToBetslipButton>
            </Button>
          </FlexButtonWrapper>
        </FlexWrapper>
      </Fade>
    </SelectionPopupContainer>
  );
};

export default SelectionPopup;
