import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: '9',
    mx: 'auto',
    mb: '4',
    pl: '5',
    pt: '2.5',
    pb: '3',
    borderRadius: 'lg',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
    bgGradient: 'linear(180deg, beta.600, beta.400)',
    sx: {
      '&:before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 'calc(100% - 8px)',
        height: 'calc(100% - 8px)',
        top: '1',
        left: '1',
        borderRadius: 'md',
        bgGradient: 'linear(180deg, beta.400, beta.600)',
        boxShadow: '0px 1px 0px 0px #ED8934',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
        top: '2',
        left: '2',
        borderRadius: 'base',
        bgGradient: 'linear(to-b, alpha.500, alpha.600)',
        boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.40) inset',
      },
    },
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    textDecorationColor: 'white',
    gap: '1.5',
    position: 'relative',
    zIndex: '9999',
    color: 'white',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.50)',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'white',
    zIndex: '9999',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
  pr: '1.5',
};
