import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'alpha.300', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'alpha.300'] },
};

export const FlexHeadingRow: CSSObject = {
  borderBottomWidth: '1px',
  borderBottomColor: 'gray.300',
};

export const FlexHeading: CSSObject = {
  pt: '1.5',
  pb: '2',
  bgGradient: 'linear(to-b, beta.400, beta.500)',
  color: 'alpha.700',
  top: ['12', '84px'],
  position: 'unset',
  borderBottom: 'none',
};

export const TextHeading: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'alpha.600',
  bgGradient: 'linear(180deg, gamma.400, gamma.600)',
  boxShadow:
    '0px 0px 0px 3px #275563 inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.40)',
  borderWidth: '3px',
  borderColor: 'gamma.400',
  borderRadius: 'lg',
  p: '1',
  mr: '2',
};

export const BoxPageWrapper: CSSObject = {
  mx: ['2', '4'],
  mb: ['2', '4'],
  bg: 'white',
  boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.35)',
  borderWidth: '2px',
  borderColor: 'beta.400',
  borderRadius: 'md',
  _last: {
    mb: '0',
  },
};

export const BoxTableWrapper: CSSObject = {
  borderBottomRadius: 'md',
  p: '0',
  borderTopWidth: '0',
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.800',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.300',
};

export const TextRaceResults: CSSObject = {
  color: 'blackAlpha.500',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  color: () => {
    if (state === 'abandoned') {
      return 'beta.400';
    }
    return 'blackAlpha.500';
  },
  bg: (() => {
    if (state === 'abandoned') {
      return 'black';
    }
    return 'transparent';
  })(),
});

export const LinkRaceItem = () => ({
  borderColor: 'beta.200',
  borderTop: 'none',
  bg: 'transparent',
  _last: {
    borderRight: 'none',
  },
  '.chakra-badge.state-prior': {
    color: 'blackAlpha.700',
    bg: 'alpha.200',
  },
});

export const FlexRow: CSSObject = {
  bg: 'white',
  borderStyle: ['dashed', 'solid'],
  borderBottomWidth: '1px',
  borderColor: ['gray.300', 'beta.200'],
  display: ['flex', null, 'inline-flex'],

  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  borderTop: 'none',
  borderRight: [null, null, '1px'],
  borderColor: [null, null, 'beta.200'],
  bg: 'white',
  py: '1.5',
};

export const CenterTH = () => ({
  bg: 'alpha.600',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'medium',
  py: '2.5',
});

export const ButtonChevron: CSSObject = {
  color: 'alpha.700',
  bg: 'beta.400',
  borderRadius: 'base',
  boxSize: '5',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};
