import * as betgalaxy from './ResponsibleGambling.styles.betgalaxy';
import * as betprofessor from './ResponsibleGambling.styles.betprofessor';
import * as betroyale from './ResponsibleGambling.styles.betroyale';
import * as fiestabet from './ResponsibleGambling.styles.fiestabet';
import * as goldenrush from './ResponsibleGambling.styles.goldenrush';
import * as juicybet from './ResponsibleGambling.styles.juicybet';
import * as puntcity from './ResponsibleGambling.styles.puntcity';
import * as puntgenie from './ResponsibleGambling.styles.puntgenie';
import * as questbet from './ResponsibleGambling.styles.questbet';
import * as sterlingparker from './ResponsibleGambling.styles.sterlingparker';
import * as vikingbet from './ResponsibleGambling.styles.vikingbet';
import * as volcanobet from './ResponsibleGambling.styles.volcanobet';
import * as wellbet from './ResponsibleGambling.styles.wellbet';
import * as forsport from './ResponsibleGambling.styles.forsport';
import * as junglebet from './ResponsibleGambling.styles.junglebet';
import * as oldgill from './ResponsibleGambling.styles.oldgill';
import * as sugarcastle from './ResponsibleGambling.styles.sugarcastle';
import * as buffalobet from './ResponsibleGambling.styles.buffalobet';
import * as slambet from './ResponsibleGambling.styles.slambet';
import * as gigabet from './ResponsibleGambling.styles.gigabet';
import * as templebet from './ResponsibleGambling.styles.templebet';
import * as goldenbet888 from './ResponsibleGambling.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  slambet,
  gigabet,
  templebet,
  goldenbet888,
};
