import { ButtonProps, theme } from '@chakra-ui/react';
import { colorScheme } from '@/helpers/colorScheme';
import paperFlags from '@/assets/fiestabet/images/paperFlags.png';

const base = {
  borderRadius: 'lg',
  border: '2px',
  h: '10',
  p: '3',
  '.btn-span': { zIndex: 9 },
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontFamily: 'accentUltra',
  transition: 'all 0.3s linear 0s',

  _disabled: {
    bg: 'gray.300',
    color: 'blackAlpha.700',
    textShadow: 'unset',
    borderColor: 'white',
    boxShadow:
      '0px 0px 0px 3px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
    pointerEvents: 'none',
  },
};

const primary = {
  ...base,
  borderColor: 'epsilon.500',
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  boxShadow:
    '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'beta.500',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.47)',

  // css trick to reduce flickering on hover when using bgGradient
  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(to-b, beta.200, beta.400)',
    boxShadow:
      '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',
    transition: 'all .2s ease-in-out',
    borderRadius: 'lg',
    opacity: 0.8,
  },

  ':hover': {
    bg: 'beta.100',
    color: 'beta.400',
    border: '2px',
    borderColor: 'epsilon.500',
    boxShadow:
      '0px 0px 0px 3px #006352 inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 5px 0px rgba(255, 255, 255, 0.30) inset',

    _after: {
      opacity: 0,
    },
  },

  '&[data-active="true"]': {
    bg: `alpha.600`,
    bgImage: `url(${paperFlags})`,
    bgSize: 'contain',
    color: 'beta.200',
    boxShadow:
      '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px #B96C91 inset, 0px 2px 7px 0px #210A15 inset',
    textShadow: '0px 2px 2px rgba(0, 0, 0, 0.52)',
    border: 'unset',

    _after: {
      opacity: 0,
    },
  },
};

const secondary = {
  ...base,
  bgGradient: 'linear(180deg, delta.300 22.92%, delta.600 91.67%)',
  borderColor: 'epsilon.500',
  boxShadow:
    '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 3px #83090D inset',
  color: 'epsilon.300',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',

  // css trick to reduce flickering on hover when using bgGradient
  _after: {
    content: `''`,
    position: 'absolute',
    inset: 0,
    zIndex: 8,
    bgGradient: 'linear(180deg, delta.300 22.92%, delta.600 91.67%)',
    boxShadow:
      '0px 2px 1px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 3px #83090D inset',
    transition: 'all .2s ease-in-out',
    borderRadius: 'lg',
    opacity: 0.8,
  },

  ':hover': {
    bg: 'delta.300',
    color: 'delta.500',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.20)',

    _after: {
      opacity: 0,
    },
  },
};

const outline = {
  ...base,
  borderColor: 'delta.800',
  bg: 'transparent',
  color: 'epsilon.100',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
  boxShadow: '0px 2px 1px 0px rgba(0, 0, 0, 0.15)',

  ':hover': {
    bg: 'delta.300',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,

      ...(props.colorScheme && colorScheme(props)),
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      fontWeight: 'bold',
      textTransform: 'uppercase',
      fontSize: '2xs',
      minW: 'auto',
      borderRadius: 'lg',
      border: '2px',
      h: '6',
      px: '2',
      py: '1',
      borderColor: 'epsilon.500',
      bgGradient: 'linear(180deg, beta.200, beta.400)',
      boxShadow:
        '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 3px #773053 inset',
      color: 'beta.500',
      fontFamily: 'roboto',

      ':hover': {
        borderColor: 'beta.400',
        bg: 'beta.200',
        color: 'beta.400',
      },

      '&[data-active="true"]': {
        bg: 'alpha.600',
        color: 'beta.200',
        boxShadow:
          '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px -1px 0px 0px #B96C91 inset, 0px 2px 7px 0px #210A15 inset',
        border: 'unset',
        textShadow: 'none',
      },

      '&[data-active="false"]': {
        bgGradient: 'linear(180deg, beta.200, beta.400)',
      },

      '&[data-closed="true"]': {
        bg: 'gray.300',
        boxShadow:
          '0px 0px 0px 3px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        border: '2px',
        borderColor: 'white',
        color: 'blackAlpha.400',
      },
    }),
    filter: {
      border: '2px',
      borderColor: 'alpha.600',
      borderRadius: 'base',
      color: 'alpha.600',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        border: 'none',
        color: 'white',
        bg: 'alpha.600',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      px: '3',
      py: '0.5',
      textTransform: 'uppercase',
      color: 'epsilon.100',

      _active: {
        bg: 'epsilon.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'gamma.700',
      },
    },
    unstyled: {
      cursor: 'auto !important',
    },
    ghost: {},
  },
  defaultProps: {},
};
