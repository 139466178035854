import { CSSObject } from '@chakra-ui/react';
import roof from '@/assets/goldenbet888/images/ntj/roof.png';

export const FlexBannerWrapper: CSSObject = {
  px: '4',
  py: '2.5',
  m: 'auto',
  h: '12',
  w: 'container.xl',
};

export const FlexWrapper: CSSObject = {
  pt: '10',
  boxShadow: 'none',
  _before: {
    bgImg: `url(${roof})`,
    w: 'full',
    h: '36px',
    bgPos: 'center',
    bgRepeat: 'repeat-x',
    bgSize: 'contain',
    content: '""',
    pos: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    zIndex: '5',
    boxShadow:
      '0px 8px 6px 0px rgba(0, 0, 0, 0.2), 0px 2px 0px 0px rgba(0, 0, 0, 0.15)',
  },
};

export const FlexItemsWrapper: CSSObject = {
  mr: '0',
  columnGap: '1',
  '& > div:last-of-type': { mr: '0' },
  minH: 'full',
};

export const CountdownTimer = {
  '&': {
    '.countdown-flex': {
      background: 'transparent',
    },
  },
};

export const LinkItem: CSSObject = {
  w: '40',
  h: 'full',
  py: '2px',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  color: 'gamma.200',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  textOverflow: 'ellipsis',
  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },
  '.chakra-badge': {
    mr: '1',
    mt: '1.5',
    maxW: '10',
    fontSize: '9px',
  },
};

export const RaceNumber: CSSObject = {
  color: 'gamma.200',
};
export const FlexDetails: CSSObject = {
  color: 'gamma.200',
  display: 'block',
  maxW: '16',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const Icon: CSSObject = {
  color: 'gamma.200',
};

export const FlexButtonContainer: CSSObject = {
  bg: 'transparent',
  pos: 'relative',
  p: '0',
  pl: '1',
  gap: '1',
};

export const ButtonChevron: CSSObject = {
  boxSize: '7',
  minW: '7',
  color: 'gamma.200',
  p: '0',
  borderRadius: 'md',
  bgGradient: 'linear(to-b, alpha.200, alpha.400)',
  boxShadow:
    '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },

  '& > svg': { boxSize: '6' },
};
