import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.400',
};

export const DeductionsText: CSSObject = {
  color: 'gray.700',
  fontSize: '2xs',
  fontWeight: '500',
};

export const BoxMoreText: CSSObject = {
  w: '7',
};
