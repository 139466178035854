import { CSSObject } from '@chakra-ui/react';

export const BetSlipEmptyContainer: CSSObject = {
  justifyContent: 'flex-start',
  mt: '9',
};

export const BoxHeadingIconContainer: CSSObject = {
  display: 'flex',
  flexDir: 'column-reverse',
};

export const BetSlipEmptyHeading: CSSObject = {
  fontFamily: 'heading',
  fontSize: 'xl',
  color: 'delta.600',
  textTransform: 'uppercase',
  lineHeight: '78%',
  fontWeight: 'normal',
  textAlign: 'center',
  mt: '2',
  mb: '2',
};

export const BetSlipEmptySubheading: CSSObject = {
  fontWeight: 'heading',
  fontSize: 'sm',
  color: 'beta.800',
  textAlign: 'center',
  'nth-child(n+2)': {
    color: 'red.600',
  },
};
