import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';
import activeBg from '@/assets/juicybet/images/backgrounds/inner.png';

const primary: CSSObject = {
  bgGradient: 'linear(to-b, alpha.400, alpha.500)',
  boxShadow:
    '0px 2px 6px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
  color: 'alpha.700',
  textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
  textTransform: 'uppercase',
  fontSize: 'sm',
  fontFamily: 'Coiny',
  borderRadius: 'md',
  borderWidth: '2px',
  borderColor: 'white',
  letterSpacing: 'wide',
  pt: '0.5',

  '.btn-span': { zIndex: 9 },

  _hover: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _focus: {
    bgGradient: 'linear(to-b, alpha.600, alpha.600)',
  },

  _disabled: {
    bg: 'gray.400',
    borderColor: 'gray.300',
    color: 'blackAlpha.900',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
    boxShadow:
      '0px 0px 0px 5px rgba(255, 255, 255, 0.40) inset, 0px 0px 0px 4px rgba(0, 0, 0, 0.60) inset, 0px 2px 5px 0px rgba(0, 0, 0, 0.40)',
  },

  '&[data-active="true"]': {
    borderColor: 'white',
    bg: '#FF6564',
    color: 'beta.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.37)',
    bgImg: `url(${activeBg})`,
    bgRepeat: 'repeat',
    bgSize: '150%',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {
    lg: { fontSize: 'lg' },
    md: { fontSize: 'md', h: '34px', lineHeight: '34px' },
    sm: { fontSize: 'sm' },
    xs: { fontSize: 'xs' },
  },
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      bgGradient: 'linear(to-b, beta.400, beta.500)',
      color: 'beta.700',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.55)',
      boxShadow:
        '0px 2px 10px 0px rgba(0, 0, 0, 0.40), 0px 1px 0px 0px rgba(255, 255, 255, 0.45) inset, 0px 0px 0px 2px rgba(0, 0, 0, 0.10) inset',
      borderWidth: '2px',
      borderColor: 'white',
      letterSpacing: 'wide',
      pt: '0.5',
      fontFamily: 'Coiny',
      textTransform: 'uppercase',

      _hover: {
        bg: 'beta.300',
      },

      '&[data-active="true"]': {
        color: 'beta.400',
        textShadow: '0px 1px 0px rgba(0, 0, 0, 0.15)',
        bg: 'beta.700',
      },

      _disabled: {
        pointerEvents: 'none',
      },
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      borderRadius: '7px',
      fontWeight: 'normal',
      bg: 'transparent',

      '&:active, &:hover': {
        bgGradient: 'linear(to-b, beta.400, beta.600)',
        bgClip: 'text',
      },
    }),
    unstyled: () => ({
      height: 'auto',
      fontWeight: 'inherit',

      '&&': {
        '&:disabled': {
          bg: 'beta.500',
          color: 'white',
        },
      },
    }),
    ghost: {
      ...primary,
      bg: undefined,
      boxShadow: undefined,
      textShadow: undefined,

      _hover: {
        bg: 'alpha.50',
      },

      _focus: undefined,

      _active: {
        bg: 'alpha.100',
      },
    },
    filter: {
      border: '2px',
      borderColor: 'alpha.400',
      borderRadius: 'base',
      color: 'white',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: 'auto',
      minW: 'auto',
      px: '2.5',
      py: '1',
      textTransform: 'uppercase',
      _hover: {
        color: 'alpha.400',
      },
      _active: {
        color: 'gamma.800',
        border: 'none',
        bgGradient: 'linear(to-b, gamma.400, gamma.500)',
      },
    },
    segment: {
      borderRadius: 'base',
      fontSize: '2xs',
      fontWeight: 'bold',
      h: '5',
      px: '3',
      py: '3px',
      textTransform: 'uppercase',

      _hover: {
        color: 'alpha.500',
      },

      _active: {
        bg: 'beta.500',
        boxShadow:
          '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
        color: 'white',
      },
    },
    tab: {
      ...primary,
      maxH: '7',
      fontFamily: 'Roboto',
      fontSize: '2xs',
      px: '1.5',
      fontWeight: 'black',
      letterSpacing: 'wide',
      minW: 'auto',
    },
  },
  defaultProps: {
    size: 'md',
    p: '5',
    colorScheme: null,
  },
};
