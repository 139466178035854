import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '7px',
    px: '2',
    h: '7',
    mb: '4',
    display: 'flex',
    bgGradient: 'linear(180deg, zeta.400, #E4C180 100%)',
    alignItems: 'center',
    boxShadow:
      '0px 6px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 0px 0px #A08656, 0px 1px 1px 0px rgba(255, 255, 255, 0.20) inset',
    sx: {
      '.chakra-breadcrumb__list': {
        display: 'flex',
        gap: '1.5',
      },
    },
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    textDecorationColor: 'alpha.400',
    gap: '1.5',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'alpha.700',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'alpha.700',
};
