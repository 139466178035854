/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { IframeHTMLAttributes, ReactNode, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  AspectRatio,
  ModalCloseButton,
  ModalContentProps,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { TMessage, usePromoModalDisclosure } from './services/PromoModal.hooks';
import { isAnalyticsEvent } from '@/hooks/usePageAnalytics';
import { PROMO_URL } from '@/constants/promo';
import { getThemeName } from '@/helpers/getThemeConfig';
import { useAppSelector } from '@/hooks/useRedux';
import { trackEvent } from '@/lib/analytics';
import { isPromoName } from './services/PromoModal.utils';
import { promoModalStyles } from './styles/PromoModal.styles';

const theme = getThemeName();

/** Types */
type PromoModalProps = {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  game: string | undefined;
};

type TIFrameProps = {
  style?: IframeHTMLAttributes<any>;
} & Pick<PromoModalProps, 'url'>;

/** View */
const PromoModalView = ({ url, isOpen, onClose, game }: PromoModalProps) => {
  const style = styles[game ?? ''] ?? styles.generic;
  const gamelessPromos = ['bet-slip-bonus'];
  const content: Record<string, ReactNode> = {
    'odds-boost': (
      <>
        <ModalCloseButton zIndex="99" />
        <ModalBody sx={{ p: 0 }}>
          <IFrame url={url} style={style.iframe} />
        </ModalBody>
      </>
    ),
    'spin-wheel': (
      <ModalBody sx={{ p: 0 }}>
        <AspectRatio ratio={7 / 15}>
          <IFrame url={url} style={style.iframe} />
        </AspectRatio>
      </ModalBody>
    ),
    generic: (
      <ModalBody sx={{ p: 0 }}>
        <IFrame url={url} style={style.iframe} />
      </ModalBody>
    ),
  };

  if (game && gamelessPromos.includes(game)) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent {...(style.modalContent ?? {})}>
        {content[game ?? ''] ?? content.generic}
      </ModalContent>
    </Modal>
  );
};

function IFrame({ url, style }: TIFrameProps) {
  return <iframe title="bc-studios-game" src={url} style={style} />;
}

/** Controller */
const usePromoModal = (): PromoModalProps => {
  const { bonusChance } = useAppSelector((state) => state.betSlip);
  const navigate = useNavigate();
  const { isOpen, onClose } = usePromoModalDisclosure();
  const promoPath = promoPaths[bonusChance?.game ?? ''];

  /**
   * 🚨 TO THE NEXT FE DEV WORKING ON THIS. 🚨
   * The contract should have been agreed between BE & Phil. We should only
   * ever be passing in the variables into the IFrame URL. If the response
   * is different to the games variables, thats either BE or Phil's problem.
   * We cant keep adding a new if/else statement each time there's a new game.
   *
   * Ensure from here on out any new games should fall in the "else".
   */
  let urlParameters = '';
  if (bonusChance?.game === 'odds-boost') {
    const oddsBoostData = [
      ['odds', `${bonusChance?.variables?.original_odds ?? ''}`],
      ['potentialWinnings', `${bonusChance?.variables?.original_payout}`],
      ['boostedOdds', `${bonusChance?.variables?.boosted_odds}`],
      ['boostedPotentialWinnings', `${bonusChance?.variables?.new_payout}`],
    ];
    const oddsBoostParams = new URLSearchParams(oddsBoostData);
    urlParameters = oddsBoostParams.toString();
  } else if (bonusChance?.game === 'spin-wheel') {
    const encodedData = encodeURIComponent(
      JSON.stringify(bonusChance?.variables)
    );
    urlParameters = `data=${encodedData}`;
  } else {
    /* ✅ This should be the only statement moving forward ✅ */
    const params = new URLSearchParams();
    Object.entries(bonusChance?.variables ?? {}).forEach(
      ([key, value]: [string, any]) => {
        params.append(key, String(value));
      }
    );
    urlParameters = params.toString();
  }

  useEffect(() => {
    const secondaryActions: Record<string, () => void> = {
      'deposit-match': () => {
        onClose();
        navigate('/account/deposit');
      },
    };

    const listenerFunction = (msg: TMessage) => {
      try {
        if (typeof msg?.data === 'string' && msg?.data.includes('bcStudio')) {
          const parseData = JSON.parse(msg.data);
          const { type, promo } = parseData?.bcStudio ?? {};

          // the close type will close the modal and and we're not interested in tracking that,
          // the other values will also close the modal but will also need to be tracked for analytics
          // to see how many users skip the animation
          if (
            ['close', 'claimBoost', 'skipPromo', 'claimPromoBonus'].includes(
              String(type)
            )
          ) {
            onClose();
          }

          if (type === 'secondary-action') {
            secondaryActions[bonusChance?.game ?? '']?.();
          }

          if (isAnalyticsEvent(type) && isPromoName(promo)) {
            trackEvent(type, { promo });
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    window.addEventListener('message', listenerFunction);

    return () => {
      window.removeEventListener('message', listenerFunction);
    };
  }, [bonusChance?.game, navigate, onClose]);

  return {
    url: `${PROMO_URL}${theme}${promoPath}?${urlParameters}`,
    isOpen,
    onClose,
    game: bonusChance?.game,
  };
};

/** Mapping */
type TStylesSchema = {
  [key: string]: {
    iframe: IframeHTMLAttributes<any> | undefined;
    modalContent: ModalContentProps | undefined;
  };
};

const styles: TStylesSchema = {
  'spin-wheel': {
    iframe: undefined,
    modalContent: undefined,
  },
  'bet-slip-bonus': {
    iframe: undefined,
    modalContent: undefined,
  },
  generic: {
    iframe: promoModalStyles.oddsBoostIFrame,
    modalContent: promoModalStyles.oddsBoostModalContent,
  },
};

const promoPaths: Record<string, string> = {
  'odds-boost': '/oddsboost/index.html',
  'spin-wheel': '/spin-wheel/index.html',
  'deposit-match': '/deposit-match/index.html',
};

/** Container */
export default function PromoModalContainer() {
  const values = usePromoModal();
  return <PromoModalView {...values} />;
}
