import * as betroyale from './InfoModal.styles.betroyale';
import * as fiestabet from './InfoModal.styles.fiestabet';
import * as puntcity from './InfoModal.styles.puntcity';
import * as puntgenie from './InfoModal.styles.puntgenie';
import * as questbet from './InfoModal.styles.questbet';
import * as vikingbet from './InfoModal.styles.vikingbet';
import * as volcanobet from './InfoModal.styles.volcanobet';
import * as oldgill from './InfoModal.styles.oldgill';
import * as slambet from './InfoModal.styles.slambet';
import * as gigabet from './InfoModal.styles.gigabet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betroyale,
  fiestabet,
  puntcity,
  puntgenie,
  questbet,
  vikingbet,
  volcanobet,
  oldgill,
  slambet,
  gigabet,
};
