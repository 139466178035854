import * as puntgenie from './Transactions.styles.puntgenie';
import * as goldenrush from './Transactions.styles.goldenrush';
import * as slambet from './Transactions.styles.slambet';
import * as goldenbet888 from './Transactions.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  puntgenie,
  goldenrush,
  slambet,
  goldenbet888,
};
