import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { CheckCircleOutline } from '@styled-icons/material-rounded/CheckCircleOutline';
import { Stop } from '@styled-icons/octicons/Stop';

export const BetApprovalContainer: CSSObject = {};

export const EstReturnsLabel: CSSObject = {
  color: 'beta.900',
  fontWeight: 'extrabold',
  height: '100%',
  alignItems: 'center',
  py: '0',
  px: '1',
  pl: '1',
  ml: '0',
  width: 'auto',
  borderLeft: '1px solid',
  borderColor: 'blackAlpha.400',
};

export const PendingBetContainer: CSSObject = {
  bg: 'orange.500',
  borderRadius: 'base',
  w: 'full',
  color: 'white',
  justifyContent: 'start',
  boxShadow:
    '0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset',
};

export const ApprovedBetContainer: CSSObject = {
  background: 'green.400',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px var(--bc-colors-accent.50)',
  borderRadius: 'md',
  w: 'full',
  color: 'black',
  justifyContent: 'center',
};

export const RejectedBetContainer: CSSObject = {
  background: 'red.400',
  bgGradient: 'none',
  boxShadow: '0px 3px 8px var(--bc-colors-accent.50)',
  borderRadius: 'md',
  w: 'full',
  color: 'red.900',
  justifyContent: 'center',
};

export const FooterContainer: CSSObject = {
  bg: 'beta.500',
  color: 'beta.600',
  px: '1',
  h: '26px',
  lineHeight: '26px',
  fontSize: '2xs',
  overflow: 'hidden',
  borderRadius: 'base',
  boxShadow: '0px 2px 5px 0px var(--bc-colors-blackAlpha-400)',
  py: '1',
  ml: 'auto',
};

export const FooterText: CSSObject = {
  fontWeight: 'medium',
  color: 'alpha.800',
};

export const BetApprovalText: CSSObject = {
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'delta.900',
  textShadow: '0 1px 1px whiteAlpha-500',
};

export const BetPendingApprovalText: CSSObject = {
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'white',
};

export const IconApprovedBet = () => (
  <Icon as={CheckCircleOutline} boxSize="5" mr="1" color="green.900" />
);

export const IconRejectedBet = () => (
  <Icon as={Stop} boxSize="4" mr="1.5" color="red.900" />
);

export const BetRejectedText: CSSObject = {
  fontFamily: 'body',
  fontWeight: 'bold',
  fontSize: 'xs',
  color: 'red.900',
  textShadow: '0 1px 1px whiteAlpha-500',
};
