import { EThemesOG } from '@/constants/themeConfig';

import * as betgalaxy from './Promos.styles.betgalaxy';
import * as puntgenie from './Promos.styles.puntgenie';
import * as questbet from './Promos.styles.questbet';
import * as wellbet from './Promos.styles.wellbet';
import * as betroyale from './Promos.styles.betroyale';
import * as buffalobet from './Promos.styles.buffalobet';
import * as puntcity from './Promos.styles.puntcity';
import * as sugarcastle from './Promos.styles.sugarcastle';
import * as goldenrush from './Promos.styles.goldenrush';
import * as juicybet from './Promos.styles.juicybet';
import * as vikingbet from './Promos.styles.vikingbet';
import * as volcanobet from './Promos.styles.volcanobet';
import * as oldgill from './Promos.styles.oldgill';
import * as slambet from './Promos.styles.slambet';
import * as templebet from './Promos.styles.templebet';
import * as gigabet from './Promos.styles.gigabet';
import * as goldenbet888 from './Promos.styles.goldenbet888';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  puntgenie,
  questbet,
  wellbet,
  betroyale,
  buffalobet,
  puntcity,
  sugarcastle,
  goldenrush,
  juicybet,
  vikingbet,
  volcanobet,
  oldgill,
  slambet,
  templebet,
  gigabet,
  goldenbet888,
};
