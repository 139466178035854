import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'beta.800',
    fontWeight: 'semibold',
  },
  '.chakra-input__group': {
    borderRadius: 'md',
    border: '2px solid',
    borderColor: 'beta.400',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-beta-400) 0%, var(--bc-colors-beta-500) 100%)',
  },
  '.chakra-input__left-addon': {
    bg: ' transparent',
    h: 'auto',
    border: 'none',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    borderRadius: 'md',
    bg: 'beta.900',
    border: 'none',
    boxShadow: 'none',
    m: '2px',
    fontSize: 'sm',
    fontWeight: 'bold',
    color: 'white',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    _placeholder: {
      fontWeight: 'bold',
    },
    _hover: {
      bg: 'beta.700',
      border: 'none',
    },
    _focus: {
      bg: 'beta.900',
      boxShadow: 'none',
      border: 'none',
    },
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mx: [null, null, '0'],
  px: '1',
  zIndex: 'modal',
  borderRadius: 'md',
  bgColor: 'beta.300',
};

export const InfoHeader: CSSObject = {
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'medium',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'odds',
  fontFamily: 'accent',
  _hover: {
    color: 'white',
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-delta-400) 0%, var(--bc-colors-delta-500) 100%)',
  },
  // bgGradient: 'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
  textTransform: 'uppercase',
  height: 'full',
};

export const TextAddToBetslipButton: CSSObject = {
  fontWeight: 'normal',
  color: 'beta.500',
};
