import { CSSObject } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'alpha.500',
  mb: '1',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
};

export const BoxMoreText: CSSObject = {
  w: '8',
  color: 'alpha.500',
};

export const FooterBodyText: CSSObject = {
  color: 'blackAlpha.500',
};
