import React from 'react';
import { Link, Icon, Box, useStyleConfig } from '@chakra-ui/react';
import { Link as LinkDom } from 'react-router-dom';
import toast from 'react-hot-toast';
import { TListItem } from '../../SideNav.types';
import { TSideNavTheme } from '../../../../theme/base/customComponents/sideNav';
import { IS_MOBILE_APP } from '../../../../constants/isMobileApp';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setIsOpen } from '../../../../redux/sideNav.slice';
import NavIcon from '../NavIcon/NavIcon';
import IconSvg from '../../../common/IconSvg';
import {
  SideNavHeading,
  FlexSideLinkWrapper,
} from '../styles/SideNavLink/SideNavLink.styles';

type TSideNavLink = {
  link: TListItem;
  isLast?: boolean;
};

const SideNavLink: React.FC<TSideNavLink> = ({ link, isLast }) => {
  const styles = useStyleConfig('SideNav') as TSideNavTheme;
  const dispatch = useAppDispatch();

  const isLink = !!link.to;

  if (!isLink && !link.onClick && !link.isDropDown) {
    return (
      <SideNavHeading
        sxProps={{
          ...styles.link,
          ...styles.linkHeader,
          ...link.sx,
        }}
      >
        {link.text}
      </SideNavHeading>
    );
  }

  return (
    <Link
      data-cy={`sideBarNavigation-${link.text}`}
      as={isLink ? LinkDom : 'button'}
      {...(isLink ? { to: link.to } : { variant: 'unstyled' })}
      sx={{
        ...styles.link,
        ...link.sx,
        ...(isLast && { ...styles.lastLink }),
      }}
      className={link.sub ? 'nested' : 'dropDownInner'}
      onClick={async () => {
        try {
          await link.onClick?.();
        } catch {
          toast.error('Unable to perform this action');
        }
        if (!IS_MOBILE_APP && !link.isDropDown) {
          dispatch(setIsOpen(false));
        }
      }}
    >
      <FlexSideLinkWrapper>
        <NavIcon link={link} styles={styles} />
        {link.icon && <Icon as={link.icon} className="icon" sx={styles.icon} />}
        {link.svg && (
          <IconSvg name={link.svg} className="svgIcon" sx={styles.icon} />
        )}
        {link.text}
      </FlexSideLinkWrapper>

      {link.dropDownIcon && (
        <Box
          transform={link.dropDownExpanded ? '' : 'rotate(-90deg)'}
          sx={{ boxSize: styles.icon.boxSize }}
        >
          <Icon
            className="dropDownIcon"
            as={link.dropDownIcon}
            sx={styles.icon}
          />
        </Box>
      )}
    </Link>
  );
};

export default SideNavLink;
