import React from 'react';
import { Global } from '@emotion/react';
import background from '@/assets/templebet/images/backgrounds/background.svg';

export default function index() {
  return (
    <Global
      styles={{
        body: {
          backgroundImage: `url(${background}), linear-gradient(var(--bc-colors-delta-800) 0%, var(--bc-colors-delta-900) 100%)`,
          backgroundPosition: 'top, center',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundSize: 'cover, cover',
          backgroundAttachment: 'fixed',
          '@media (min-width: 1366px)': {
            backgroundPosition: 'top, center',
          },
          '@media (max-width: 1016px)': {
            backgroundPosition: 'top, center',
          },
          '@media (max-width: 390px)': {
            backgroundPosition: 'top, center',
          },
        },
      }}
    />
  );
}
