/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Img,
  Link,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import dayjs from 'dayjs';
import { useQueryPromotions } from '@/api/promotions/promotions.hooks';
import { TData } from '@/api/promotions/promotions.types';
import { Button } from '@/components/Button/Button';
import PromotionModal from '../Modal/Modal';
import { useAuth } from '@/hooks/useAuth';
import { usePunterData } from '@/store/AuthStore';
import { IS_MOBILE_APP } from '@/constants/isMobileApp';
import { EDisplay, EStatus, TMedia } from '@/lib/DBModels';
import { promotionsBaseStyles } from './styles/Promotions.styles';
import { useQueryPromotions as useCustomQueryPromotions } from '@/api/punter/deposit/deposit.hooks';

type TPromotionProps = {
  data?: TData;
  onClick?: () => void;
  platform: keyof TMedia;
};

export default function PromotionsContainer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState<TData | undefined>(
    undefined
  );

  const {
    filteredPromotions,
    tabs,
    isAuthLoading,
    platform,
    promoType,
    setPromoType,
  } = usePromotionView();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isAuthLoading)
    return (
      <Stack p="4">
        <Skeleton height="300px" />
        <Skeleton height="300px" />
        <Skeleton height="300px" />
      </Stack>
    );

  if (!filteredPromotions.length) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      {selectedPromo && (
        <PromotionModal
          promotion={selectedPromo}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
      <Box {...promotionsBaseStyles.boxWrapper}>
        <Text {...promotionsBaseStyles.heading}>
          <FormattedMessage id="helpcentre.promotions.promotions" />
        </Text>
        <Flex {...promotionsBaseStyles.flexTabWrapper}>
          {tabs.map((tab) => (
            <Button
              key={tab}
              variant="tab"
              data-active={promoType === tab || (tab === 'all' && !promoType)}
              onClick={() => setPromoType(tab)}
            >
              {tab}
            </Button>
          ))}
        </Flex>
      </Box>
      <Box {...promotionsBaseStyles.filteredPromotionsWrapper}>
        {filteredPromotions?.map((promo) => (
          <Promotion
            key={promo.id}
            data={promo}
            platform={platform}
            onClick={() => {
              setSelectedPromo(promo);
              openModal();
            }}
          />
        ))}
      </Box>
    </AnimatePresence>
  );
}

export function Promotion({ data, onClick, platform }: TPromotionProps) {
  if (!data) return null;

  return (
    <motion.div
      initial={{ translateY: -10, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
    >
      <Box
        _hover={{
          transform: 'scale(1.02)',
          transition: 'transform 0.3s ease-in-out',
          boxShadow: '2xl',
        }}
        {...promotionsBaseStyles.promotionWrapper}
      >
        <Img src={data.media[platform].publicUrl} w="full" />
        <Flex {...promotionsBaseStyles.flexWrapper}>
          <Text {...promotionsBaseStyles.termsAndConditions}>
            {data.description?.slice(0, 130)}{' '}
            <Text as="u" onClick={onClick}>
              <FormattedMessage id="helpcentre.promotions.termsAndConditions" />
            </Text>
          </Text>
          <Link href={data.button_link}>
            <Button p="5" ml="2" variant="solid">
              {data.button_text}
            </Button>
          </Link>
        </Flex>
      </Box>
    </motion.div>
  );
}

// Controller
const usePromotionView = () => {
  const promotions = useQueryPromotions();
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
  const punterData = usePunterData();
  const [promoType, setPromoType] = useState<string | undefined>(undefined);

  const { data: customPromotions } = useCustomQueryPromotions(
    {
      promo_type: 'money_back',
    },
    { enabled: !!isAuthenticated }
  );

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const platform: keyof TMedia = (() => {
    if (IS_MOBILE_APP) return 'app';

    return isMobile ? 'mobile' : 'desktop';
  })();

  const filteredData =
    promoType === 'all' || !promoType
      ? promotions?.data
      : promotions?.data?.filter((item) =>
          item.available_on?.includes(promoType?.toUpperCase() ?? '')
        );

  const isPromoOffered = (id: string) =>
    customPromotions?.data.find((promo) => promo.id === id);

  const filterPromotions = (promos: TData[] | undefined): TData[] => {
    if (!promos) return [];

    return promos?.filter((promotion) => {
      if (promotion.display === EDisplay.LoggedIn) {
        if (isAuthenticated) {
          if (
            punterData?.state &&
            !promotion.location.includes(punterData.state)
          ) {
            return false;
          }
          if (
            punterData?.pc &&
            !promotion.punter_category.includes(punterData.pc)
          ) {
            return false;
          }
        }
      }

      if (!promotion.available_on?.length) return false;

      if (promotion.has_money_back_promo) {
        if (!isAuthenticated) return false;
        if (isPromoOffered(promotion.money_back_promo_id ?? '')) {
          return customPromotions?.data?.find(
            (promo) => promo.id === promotion.money_back_promo_id
          );
        }
        return false;
      }
      const isActive = () => {
        if (promotion.status === EStatus.Active) return true;
        if (promotion.status === EStatus.Draft) return false;
        if (!promotion.start_date) return false;

        const hasStarted = dayjs().isAfter(
          dayjs(new Date(promotion.start_date))
        );
        const hasNotFinished =
          !promotion.end_date ||
          dayjs().isBefore(dayjs(new Date(promotion.end_date)));

        return hasStarted && hasNotFinished;
      };

      return (
        isActive() &&
        ((isAuthenticated && promotion.display === EDisplay.LoggedIn) ||
          (!isAuthenticated && promotion.display === EDisplay.LoggedOut) ||
          promotion.display === EDisplay.Both)
      );
    });
  };

  const tabs = Array.from(
    new Set(
      filterPromotions(promotions.data ?? [])
        ?.filter(
          (promotion) =>
            promotion.status === EStatus.Active ||
            promotion.status === EStatus.Scheduled
        )
        .flatMap((promotion) => promotion.available_on)
        .filter((promotion) => promotion !== undefined)
    )
  );

  if (tabs.length) tabs.unshift('all');

  const filteredPromotions = filterPromotions(filteredData);

  return {
    filteredPromotions,
    tabs,
    isAuthLoading,
    platform,
    promoType,
    setPromoType,
  };
};
