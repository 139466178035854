import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    border: '2px',
    borderColor: 'whiteAlpha.600',
    borderRadius: 'base',
    px: '2',
    mb: '3',
    h: '7',
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};
