import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  mb: '0',
  mx: ['-2', '0'],
  mr: ['-1', 'unset'],
  px: ['2', '0'],
  mt: '2',
  gap: '1',
  pb: '0',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
