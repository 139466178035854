import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: ['10px', null, null, '16px'],
    pr: [null, null, null, '10px'],
    h: '44px',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'unset',
    whiteSpace: 'normal',
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: '700',
    color: 'beta.200',
    textDecoration: 'none',

    _hover: {
      textDecoration: 'underline',
    },

    sx: {
      '&:not([href])': {
        color: 'alpha.400',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
