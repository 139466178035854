import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/gigabet/images/close-filter.svg';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  color: 'alpha.400',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgb(0 0 0) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(64, 44, 3, 1) inset',
    color: 'alpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  },
  _focus: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
    color: 'alpha.400',
    fontSize: 'sm',
    textTransform: 'capitalize',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset',
  },
  ':active, &[data-active="true"]': {
    bg: 'beta.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
  borderRadius: 'md',
  color: 'alpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.400',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  ':active, &[data-active="true"]': {
    bg: 'beta.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.300 0%, beta.500 400%)',
  boxShadow:
    ' 0px 3px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
  color: 'alpha.400',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  },
  _active: {
    bg: 'beta.800',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 3px 3px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset',
    pointerEvents: 'none',
  },
};

const quickAccess = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  color: 'alpha.400',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset',
  _hover: {
    bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(64, 44, 3, 1) inset',
    color: 'alpha.800',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  },
  ':active, &[aria-expanded="true"]': {
    bg: 'white',
    boxShadow: 'none',
    color: 'beta.700',
    textShadow: 'unset',
    borderBottomRadius: 'none',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 2px rgba(255, 218, 47, 1) inset, 0px 4px 6px 0px rgba(0, 0, 0, 0.3), 0px 0px 0px 5px rgba(255, 255, 255, 0.2) inset, 0px 0px 0px 4px rgba(32, 39, 63, 1) inset',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  bgGradient: 'linear(to-b, alpha.400 0%, alpha.500 100%)',
  borderRadius: 'md',
  color: 'alpha.800',
  fontFamily: 'accent',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.3) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
    color: 'alpha.400',
    textShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'alpha.400',
    textShadow: 'unset',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'gray.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow:
      '0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    pointerEvents: 'none',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const tab = {
  boxShadow: 'none',
  fontSize: 'sm',
  textShadow: 'none',
  fontWeight: 'normal',
  borderRadius: 'none',
  fontFamily: 'accent',
  h: '8',
  textTransform: 'uppercase',
  color: 'white',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  _hover: {
    color: 'alpha.400',
  },
  ':active, &[data-active="true"]': {
    color: 'alpha.400',
    fontWeight: 'normal',
    borderBottom: '3px solid',
    borderBottomColor: 'alpha.400',
  },
};

const filter = {
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'alpha.400',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
    border: 'none',
    '&:after': {
      border: 'none',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '14px',
      top: '7px',
      right: '2',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};

const segment = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    segment: () => ({
      ...segment,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
