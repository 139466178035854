import { CSSObject } from '@chakra-ui/react';
import { TSelected } from './Filters.styles';

export const IconRace = ({ selected }: TSelected) => ({
  bg: 'transparent',
  color: selected ? 'beta.500' : 'beta.800',
});

export const IconCheck = ({ selected }: TSelected) => ({
  bg: 'beta.800',
  color: selected ? 'beta.500' : 'transparent',
  mr: '0.5',
});

export const TextFilterLabel: CSSObject = {
  color: 'white',
  fontWeight: 'bold',
  background:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow: '0px 4px 4px 0px var(--bc-colors-blackAlpha-300)',
  px: '4',
  py: '2',
  borderRadius: 'lg',
};

export const Divider: CSSObject = {
  display: 'none',
  bg: 'beta.500',
};

export const FlexRaceTypeWrapper: CSSObject = {
  mr: ['unset', 'auto'],
};

export const FlexFilterWrapper: CSSObject = {
  px: '0',
  py: '0',
  mt: '0.5',
  mb: '2.5',
  h: ['fit-content', '9'],
  position: 'inherit',
  flexWrap: ['wrap', null],
  justifyContent: 'space-between',
};
