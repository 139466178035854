import { CSSObject } from '@chakra-ui/react';
import { TLinkContainer } from './NextToJumpItem.styles';

export const LinkContainer = ({ isLast }: TLinkContainer) => ({
  borderBottom: !isLast && '1px dashed',
  borderRadius: 'unset',
  px: '0',
  py: '2',
  mb: 'unset',
  mx: '2',
  w: 'auto',
  mt: '-0.5',
  bg: 'transparent',
  borderBottomColor: 'var(--bc-colors-whiteAlpha-500)',
  position: 'relative',
  _after: {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'full',
    borderBottom: '1px',
    borderColor: 'var(--bc-colors-blackAlpha-50)',
    borderStyle: 'dashed',
  },
});

export const BoxHeading: CSSObject = {
  display: 'none',
  border: 'none',
};

export const IconHeading: CSSObject = {
  boxSize: '7',
  color: 'gamma.600',
  bg: 'white',
  borderRadius: 'full',
  p: '1.5',
};

export const Icon: CSSObject = {
  color: 'beta.500',
  boxSize: 'var(--bc-space-7)',
  bg: 'linear-gradient(180deg, var(--bc-colors-beta-700) 0%,var(--bc-colors-beta-800) 100%)',
  boxShadow:
    '0px 1px 0px 0px var(--bc-colors-accent-300),0px 0px 0px 2px var(--bc-colors-whiteAlpha-500) inset,0px 0px 0px 1px var(--bc-colors-beta-900) inset',
  borderRadius: 'lg',
  p: '2',
};

export const TextRace: CSSObject = {
  color: 'beta.800',
  fontFamily: 'heading',
  fontSize: 'xs',
  fontStyle: 'normal',
  lineHeight: 'normal',
  fontWeight: 'normal',
};

export const BoxNextToJumpContainer: CSSObject = {
  bg: 'beta.100',
  borderRadius: 'lg',
  p: '2',
  background: `linear-gradient(180deg, var(--bc-colors-beta-100) 0%, var(--bc-colors-beta-200) 100%)`,
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px var(--bc-colors-beta-100) inset, 0px 0px 0px 5px #C8B277 inset',
};

export const TextNumber: CSSObject = {
  bg: 'delta.500',
  color: 'delta.900',
  px: '1',
  py: '1',
  borderRadius: 'base',
  height: '100%',
  boxShadow: '0px 2px 4px 0px var(--bc-colors-blackAlpha-400)',
  width: 'full',
};
