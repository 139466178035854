import { UpcomingMatchesSchema } from '@/views/sports/UpcomingMatches/styles/UpcomingMatches.styles';

export const upcomingMatchesStyles: UpcomingMatchesSchema = {
  pageHeader: {
    fontSize: 'xl',
    fontFamily: 'accent',
    fontWeight: 'normal',
    color: 'beta.800',
    pl: ['2', '0', '0'],
    mb: ['5', null, '0'],
  },
  stackWrapper: {
    color: 'white',
    mt: ['-1', null, '0', '-2'],
    p: '0',
  },
  boxWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: [null, null, 'space-between'],
    flexDirection: [null, null, 'column-reverse'],
    mt: '-2',
  },
  hStackWrapper: {
    width: 'fit-content',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    position: ['static', 'static', 'static', 'relative'],
    bottom: ['0', '0', '0', '12px'],
  },
  sortByText: {
    fontSize: 'xs',
    fontWeight: 'extrabold',
    textTransform: 'uppercase',
    color: 'beta.800',
    fontFamily: 'body',
  },
  scrollButtonGroup: {
    bg: 'none',
    boxShadow: 'none',
    variant: 'filter',
    sx: {
      '&&': {
        my: '1',
      },
    },
  },
  flexRenderSortingMobile: {
    justifyContent: 'flex-end',
    p: ['2', '0'],
  },
  loadingSkeletonText: {
    my: '4',
    noOfLines: 2,
    spacing: '4',
    skeletonHeight: '2',
  },
  mainStackWrapper: {
    spacing: '4',
    px: ['2', '0'],
  },
  mainStackInner: {
    spacing: '4',
  },
  heading: {
    alignItems: 'center',
    display: 'flex',
    fontSize: 'xl',
    gap: '1',
    fontFamily: 'accent',
    fontWeight: 'normal',
    color: 'beta.800',
  },
  headingIcon: {
    sx: {
      color: 'beta.800',
    },
  },
  propositionButton: {
    variant: 'odds',
    flex: '1',
    height: '38px',
    display: 'initial',
  },
  buttonSport: {
    pr: '25px',
    borderRadius: 'md',
    ml: '0',
    py: '3',
    borderWidth: '2px',
    fontSize: '10px',
    fontWeight: '800',
    sx: {
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        border: '1.2px solid',
        borderColor: 'beta.500',
        boxSize: '2.5',
        top: '7px',
        right: '2',
        borderRadius: 'full',
        pr: '2',
      },
    },
  },
  buttonAllSports: {
    borderColor: 'beta.500',
    borderRadius: 'md',
    fontSize: '10px',
    fontWeight: '800',
    py: '3',
    mr: '-3',
    _active: {
      bg: 'transparent',
      color: 'white',
      fontWeight: '800',
      fontFamily: 'body',
      border: '2px solid',
      borderColor: 'beta.500',
      '&:after': {
        display: 'none',
      },
    },
  },
  pageButtonWellWrapperOverride: {
    bg: 'delta.600',
    h: 'fit-content',
    py: '2',
    borderRadius: 'md',
    boxShadow:
      '0px 1px 0px 0px var(--bc-colors-whiteAlpha-200),0px 1px 5px 0px var(--bc-colors-blackAlpha-400) inset',
  },
  segmentedButtonSortByTime: {
    _hover: {
      color: 'beta.500',
    },
    _active: {
      background: 'beta.500',
      color: 'beta.800',
    },
  },
  segmentedButtonSortBySport: {
    _hover: {
      color: 'beta.500',
    },
    _active: {
      background: 'beta.500',
      color: 'beta.800',
    },
  },
};
