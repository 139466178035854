import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema, TButton } from './Action.styles';
import activeBg from '../../../../../../../assets/vikingbet/images/backgrounds/btn-active-bg.png';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    boxSize: '8',
    color: 'blackAlpha.700',
    sx: {
      '&&': {
        h: '8',
        w: '8',
      },
    },
  },
};

export const Button = ({ isSelected }: TButton): CSSObject => ({
  borderRadius: 'md',
  fontFamily: 'SkranjiBold',
  bgGradient: 'linear(180deg, delta.400, delta.600)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 255, 255, 0.25) inset, 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  borderWidth: '2px',
  borderColor: 'var(--bc-colors-delta-700)',
  borderStyle: 'solid',
  color: 'rgba(0, 0, 0, 0.64)',
  transition: 'all .3s linear',
  lineHeight: '1',
  height: [null, null, '8'],
  boxSizing: 'border-box',
  fontSize: 'sm',
  '&:hover': {
    bgGradient: 'linear(180deg, delta.600, delta.800)',
    boxShadow: 'inset 0px 0px 0px 1px var(--bc-colors-delta-600)',
  },
  '&:disabled': {
    bg: 'blackAlpha.300',
    color: 'blackAlpha.400',
    boxShadow: 'none',
    border: 'none',
  },
  ...(isSelected && {
    bgImg: `url(${activeBg})`,
    color: 'delta.300',
    bgSize: '100% 100%',
    border: 'none',
    boxShadow: '0px 0px 0px 3px rgba(0, 0, 0, 0.45) inset',
    _hover: {
      bgImg: `url(${activeBg})`,
      color: 'delta.500',
      bgSize: '100% 100%',
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.45) inset',
    },
  }),
});

export const FlexOdds: CSSObject = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  alignItems: 'center',
  w: '12',
  justifyContent: 'center',
  px: '1',
  py: ['1', null, '2'],
  minW: '10',
  textAlign: 'center',
  borderRadius: 'base',
  whiteSpace: 'nowrap',
  mr: '0',
  mt: ['2', null, 'unset'],
  bg: 'blackAlpha.300',
  color: 'blackAlpha.400',
  h: '30px',
  textShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
  fontFamily: 'SkranjiRegular',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
};

export const HStackActions: CSSObject = {
  gap: '0',
};
