import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
};

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: 'blackAlpha.500',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 1px 5px 0px rgba(0, 0, 0, 0.25) inset',
  textTransform: 'uppercase',
  mb: '1',
};

export const EachWayText: CSSObject = {
  color: 'black',
  fontWeight: 'medium',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'beta.500',
  fontWeight: 'black',
};

export const ReviewEstText: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'bold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'beta.500',
  fontWeight: 'black',
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  fontWeight: 'bold',
  fontSize: 'sm',
  color: 'alpha.800',
  bgGradient: 'linear(to-b, #fff, #B2B2B2)',
  border: 'none',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
  h: '38px',
  '+ input': {
    bg: isPrefix ? 'red' : 'blue',
  },
});

export const InputBetSlip: CSSObject = {
  border: '2px solid',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 'bold',
  boxShadow: '0px 3px 8px 0px rgba(0, 0, 0, 0.30)',
  h: '38px',
  pl: '2.8rem',
  ':first-child': {
    pl: '2',
  },
  '&&': {
    borderRightRadius: 'md',
    borderColor: '#B2B2B2',
    borderLeft: '2px solid',
    borderLeftColor: '#B2B2B2',
    borderLeftRadius: 'md',
    bg: 'alpha.700',
  },
  _hover: {
    bg: 'alpha.600',
    boxShadow: '0px 0px 8px 0px rgba(67, 187, 255, 0.50)',
  },
  _focus: {
    bg: 'beta.800',
    borderColor: 'beta.500',
    boxShadow: '0px 0px 8px 0px rgba(251, 201, 21, 0.50)',
  },
};

export const FlexiReview: CSSObject = {
  pl: '8',
  maxW: '92px',
  alignItems: 'end',
};

export const FlexReviewExotic: CSSObject = {
  textAlign: 'right',
  "[class$='ReviewStake']": {
    color: 'alpha.100',
  },
};
