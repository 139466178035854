import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexWrapper: CSSObject = {
  bg: 'epsilon.400',
  borderBottomRadius: 'lg',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'delta.700',
    fontWeight: 'semibold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'epsilon.200',
    border: '2px',
    borderLeft: 'none',
    borderColor: 'delta.700',
    fontWeight: 'bold',
    borderRightRadius: 'md',
    color: 'delta.700',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',

    _placeholder: {
      color: 'delta.700',
      opacity: '0.5',
    },

    _hover: {
      bg: 'blackAlpha.200',
      borderColor: 'delta.700',
      border: '2px',
      borderLeft: 'none',
    },

    _focus: {
      bg: 'epsilon.100',
      borderColor: 'delta.700',
      border: '2px',
      borderLeft: 'none',

      _placeholder: {
        color: 'delta.700',
        opacity: '0.5',
      },
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    color: 'epsilon.200',
    fontWeight: 'extrabold',
    bg: 'delta.700',
    borderLeftRadius: 'md',
    w: '10',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: [null, null, '2'],
  mx: [null, null, '3'],
  zIndex: 'docked',
  borderBottomRadius: 'lg',
  boxShadow: '0px -4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, beta.200, beta.400)',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'beta.600',
  fontWeight: 'bold',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  h: '10',
};

export const TextAddToBetslipButton: CSSObject = {
  fontWeight: 'normal',
  color: 'epsilon.200',
  fontFamily: 'Ultra',
  textTransform: 'capitalize',
  fontSize: 'md',
  textShadow: '0px 1px 1px rgba(0, 0, 0, 0.30)',
};
