import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '3',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  bg: '#381B0E',
  _groupFocusWithin: {
    bg: '#381B0E',
  },
  color: 'white',
  fontSize: 'sm',
  ...(isPrefix
    ? {
        borderLeftRadius: 'md',
        borderRightRadius: 'none',
      }
    : {
        borderLeftRadius: 'none',
        borderRightRadius: 'base',
      }),
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'base',
  bg: '#D3B886',
  boxShadow:
    '0px 1px 0px 0px #8A6B4C, 0px 1px 4px 0px rgba(0, 0, 0, 0.30) inset',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'blackAlpha.300',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'white',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'alpha.600',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'semibold',
  color: 'white',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'alpha.600',
  fontWeight: 'extrabold',
};

export const InputBetSlip: CSSObject = {
  marginTop: '-0.5',
  bg: '#7A3B1E',
  border: '2px',
  borderColor: 'alpha.700',
  color: 'white',
  pl: '10',
  borderRadius: 'md',
  ':first-child': {
    pl: '3',
  },
  _hover: {
    boxShadow: 'inset 0px 1px 8px rgba(0, 0, 0, 0.4)',
    '&&': {
      bg: 'alpha.400',
    },
  },
  _focus: {
    borderColor: 'alpha.700',
    background: 'alpha.400',
    boxShadow: 'inset 0px 1px 8px rgba(0, 0, 0, 0.4)',
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const FlexiReview: CSSObject = {
  pl: '1',
};
