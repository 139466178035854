import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Box } from '@chakra-ui/react';
import PersonalDetailsProps from './types';
import { ContainerForms } from '../styles/account.styles';
import Input from '../../../components/FormElements/Input';
import AccountWrapper from '../../../wrappers/AccountWrapper/AccountWrapper';
import { personalDetailsStyles } from './styles/PersonalDetails.styles';
import { usePunterData } from '@/store/AuthStore';

const PersonalDetails: FC<PersonalDetailsProps> = () => {
  const intl = useIntl();
  const punterProfile = usePunterData();

  return !punterProfile ? null : (
    <AccountWrapper
      pageTitle={intl.formatMessage({
        id: 'account.personaldetails.pageTitle',
      })}
      pageHeader={intl.formatMessage({
        id: 'account.personaldetails.header',
      })}
    >
      <ContainerForms>
        <Box {...personalDetailsStyles.wrapperBox}>
          {punterProfile.title && (
            <Input
              isDisabled
              isFormik={false}
              type="text"
              name="title"
              value={punterProfile.title}
              label={intl.formatMessage({
                id: 'account.personaldetails.titleInputLabel',
              })}
              data-cy="personalDetailsTitle"
            />
          )}
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="firstName"
            value={punterProfile.first_name}
            label={intl.formatMessage({
              id: 'account.personaldetails.firstNameInputLabel',
            })}
            data-cy="personalDetailsFirstName"
          />
          {punterProfile.middle_name && (
            <Input
              isDisabled
              isFormik={false}
              type="text"
              name="middleName"
              value={punterProfile.middle_name}
              label={intl.formatMessage({
                id: 'account.personaldetails.middleNameInputlabel',
              })}
              data-cy="personalDetailsLastName"
            />
          )}
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="lastName"
            value={punterProfile.last_name}
            label={intl.formatMessage({
              id: 'account.personaldetails.lastNameInputLabel',
            })}
            data-cy="personalDetailsLastName"
          />
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="mobile"
            value={punterProfile.mobile}
            label={intl.formatMessage({
              id: 'account.personaldetails.mobileInputLabel',
            })}
            data-cy="personalDetailsMobile"
          />
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="email"
            value={punterProfile.email}
            label={intl.formatMessage({
              id: 'account.personaldetails.emailInputLabel',
            })}
            data-cy="personalDetailsEmail"
          />
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="address"
            value={`${punterProfile.street_number} ${punterProfile.street_name}, ${punterProfile.suburb}, ${punterProfile.state} ${punterProfile.post_code}`}
            label={intl.formatMessage({
              id: 'account.personaldetails.addressInputLabel',
            })}
            data-cy="personalDetailsAddress"
          />
          <Input
            isDisabled
            isFormik={false}
            type="text"
            name="dateOfBirth"
            value={dayjs(punterProfile.date_of_birth).format('DD/MM/YYYY')}
            label={intl.formatMessage({
              id: 'account.personaldetails.dobInputLabel',
            })}
          />
        </Box>
      </ContainerForms>
    </AccountWrapper>
  );
};

export default PersonalDetails;
