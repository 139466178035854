import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    background: 'beta.500',
    boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.25)',
    px: '10px',
    h: '28px',
    mb: '4',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: '800',
    textDecoration: 'none',
    color: 'gamma.200',

    _hover: {
      textDecoration: 'underline',
    },

    sx: {
      '&:not([href])': {
        color: 'alpha.500',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
