import { CSSObject } from '@chakra-ui/react';

export const RunnerListItemWrapper: CSSObject = {
  color: 'beta.800',
  borderRadius: 'lg',
  py: '3',
  px: '3',
  bg: 'beta.100',
  fontWeight: 'bold',
  mb: '4',
  boxShadow:
    '0px 3px 4px 0px var(--bc-colors-blackAlpha-300), 0px 0px 0px 3px white inset, 0px 0px 0px 5px var(--bc-colors-beta-200) inset',
};

export const RunnerName: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'beta.800',
  textTransform: 'capitalize',
  fontFamily: 'body',
};

export const FlexDeductionsContainer: CSSObject = {
  fontWeight: 'semibold',
  fontSize: 'xs',
  color: 'alpha.500',
  textTransform: 'uppercase',
  flexDir: 'column-reverse',
};

export const TextDeductions: CSSObject = {
  fontSize: '2xs',
  fontWeight: 'medium',
  color: 'alpha.500',
};

export const ScratchedText: CSSObject = {
  fontWeight: 'semibold',
  color: 'alpha.500',
};

export const TextScratchedInfo: CSSObject = {
  fontWeight: 'medium',
  fontSize: 'xs',
  color: 'alpha.500',
};

export const HorseHarnessRunnerSilk: CSSObject = {
  borderColor: 'alpha.500',
  py: '1',
  boxSize: '8',
  maxHeight: '10',
  mr: '1.5',
  bg: 'white',
  boxShadow: '0px 3px 6px 0px var(--bc-colors-blackAlpha-500)',
};

export const RunnerPropositionWrapper: CSSObject = {
  columnGap: '1',
};

export const TextBarrierNumber: CSSObject = {
  color: 'beta.800',
  fontSize: '2xs',
  position: 'relative',
  bottom: '3px',
};

export const FlexFlucsPopupContainer: CSSObject = {
  pt: '1',
  '.badgeCollapse': {
    borderLeft: '1px solid',
    borderColor: ['transparent', null, 'whiteAlpha.200'],
    pl: ['0', null, '2'],
  },
};
