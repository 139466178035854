import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  textTransform: 'uppercase',
  color: 'yellow.900',
  fontSize: 'sm',
  fontWeight: 'extrabold',
};

export const SelectionPopupContainer: CSSObject = {
  zIndex: 'docked',
};
