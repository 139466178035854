import { SRMultiStylesSchema } from './SRMulti.styles';

export const srMultiStyles: SRMultiStylesSchema = {
  headingLabel: {
    color: 'beta.800',
    fontSize: 'md',
    fontWeight: 'normal',
    fontFamily: 'accent',
    mt: '1',
  },
  columnHeadings: {
    mt: '-2',
    color: 'beta.800',
    fontWeight: 'bold',
    fontSize: 'xs',
    minWidth: '12',
    textAlign: 'center',
  },
  headingContainer: {
    mx: '1',
  },
};
