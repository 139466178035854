import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'md',
    bgGradient: 'linear(to-b, alpha.400, alpha.600)',
    px: '4',
    pt: '1',
    pb: '1.5',
    mb: '4',
    h: '9',
    display: 'flex',
    alignItems: 'center',
    boxShadow:
      '0px 0px 0px 2px #FDD101 inset, 0px 0px 0px 4px #26830B inset, 0px 0px 0px 5px rgba(255, 255, 255, 0.30) inset',
  },
  breadcrumbItemBreadcrumb: {
    textDecoration: 'none',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.40)',
    sx: {
      '&:first-of-type': {
        textDecoration: 'underline',
        color: 'white',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'white',
    textShadow: '0px 1px 3px rgba(0, 0, 0, 0.40)',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
