import React, { useEffect, useMemo, useState } from 'react';
import {
  Flex,
  FormControl,
  Icon,
  Switch,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
  Box,
} from '@chakra-ui/react';
import { ErrorWarning } from '@styled-icons/remix-fill/ErrorWarning';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme as useThemeStyled } from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { SortDown, SortUp } from '@styled-icons/fa-solid';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import useInterval from '../../../hooks/useIntervalLegacy';
import {
  getMarketByType,
  getStrings,
  logError,
  marketHasSPOnly,
} from '@/helpers/utils';

import {
  getMarketsList,
  getOtherRacesSameVenue,
  getRaceMeta,
  getRaceResults,
  getRaceRunnerList,
  getRaceRunnerListPolling,
  getRacingVenueList,
} from '../services/races.actions';

import TabBar from '../../../components/TabBar/TabBar';
import PrimaryWrapper from '../../../wrappers/PrimaryWrapper';
import TabbedDisplay from '../../../components/TabbedDisplay';
import RaceStatus from '../../../components/RaceStatus';
import RaceRunnerListItem from '../components/RaceRunnerListItem';
import SVGIcon from '../../../components/SVGIcon';
import RaceResults from '../components/RaceResults';

import {
  ButtonGroupWrapper,
  FlexHeaderLeft,
  FlexHeaderMetaWrapper,
  FlexPageHeaderTitleWrapper,
  InfoContainer,
  RaceAlertBanner,
  RaceAlertBannerContent,
  RaceAlertText,
  RaceAndTimerContainer,
  RaceDetailsHeading,
  raceDetailsStyles,
  RaceHeaderWrapper,
  RaceName,
  RaceResultListWrapper,
  RaceRunnerHeadingContainer,
  RaceRunnerList,
  RaceRunnerListContainer,
  RaceRunnerListHeadings,
  RaceRunnerListWrapper,
  RaceRunnerPropositionHeadingContainer,
  RaceRunnerWinPlaceContainer,
  TextRaceConditions,
  TextTitle,
} from './styles/RaceDetails.styles';

import {
  RaceDetailsProps,
  TRunnerAndExoticResults,
  TToteMultiResults,
} from './types';
import {
  EBetTypesDisplayNames,
  EGeneralStatus,
  ERaceType,
  TRace,
  TRaceResults,
  TRaceToteResult,
  TRunnerResult,
  TVenue,
} from '@/lib/DBModels';

import Exotics from '../bets/Exotics/Exotics';
import { setRaceRunnerList } from '../services/races.slices';
import { useTable } from '../bets/components/Table/Table.hooks';
import { ExoticResults } from '../bets/Exotics/components/Results/Results';
import {
  RunnerListItemContainer,
  RunnerListItemWrapper,
  RunnerName,
} from '../components/RaceRunnerListItem/styles/RaceRunnerListItem.styles';
import { getEventRuleText } from '@/components/Betslip/Services/Betslip.utils';
import { useDisplayMarkets } from '@/components/TabBar/services/TabBar.hooks';
import SRMulti from '../bets/SRMulti/SRMulti';
import { useQuerySupportedBets } from '@/api/punter/supportedBets/supportedBets.hooks';
import Blended from '../bets/Blended/Blended';
import EvenShot from '../bets/EvenShot/EvenShot';
import { OnRaceBets } from '../components/OnRaceBets/OnRaceBets';
import { Button } from '@/components/Button/Button';
import { badgeStyles } from '../components/Badges/styles/Badges.styles';
import { useQueryPromotions } from '@/api/punter/deposit/deposit.hooks';
import IconSvg from '@/components/common/IconSvg';
import MysteryBetContainer from '../components/MysteryBet/MysteryBet';
import { useAuth } from '@/hooks/useAuth';
import { keys } from '@/api/api.keys';
import { queryBetsForRace } from '@/api/racing/bets/bets';
import { useFeatureFlag } from '@/store/FeatureFlagStore';
import {
  actions,
  TToteMultiType,
  TTotoeMultiSelection,
  useTotePropositionId,
  useToteSelections,
} from '@/store/ToteMultis';
import { useIsToteMulti } from '../bets/Exotics/services/Exotics.utils';
import { tickForToteMulti } from '@/components/TabBar/styles/TabBar.styles';

const pastRace = (status?: EGeneralStatus): boolean =>
  status === EGeneralStatus.Closed ||
  status === EGeneralStatus.Settled ||
  status === EGeneralStatus.Scratched ||
  status === EGeneralStatus.Voided;

const RaceDetails: React.FC<RaceDetailsProps> = () => {
  const [
    {
      Racing: { RaceDetails: Strings },
      Generic: { ErrorOccurred: ErrorOccurredToast },
    },
  ] = getStrings();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [raceDataLoading, setRaceDataLoading] = useState(true);
  const theme = useThemeStyled();
  const { venueListLoading, raceRunnerList } = useAppSelector(
    (state) => state.racing
  );
  const { data: supportedBets } = useQuerySupportedBets();

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const { displayMarkets } = useDisplayMarkets();

  const [showInsights, setShowInsights] = useState(true);
  const [showFlucs, setShowFlucs] = useState(true);
  const [showTotes, setShowTotes] = useState(isLargerThan768);

  // Set twice, secondarily with fallback incase params are empty/invalid for some reason
  const { racingType, raceNumber, betType, venueName } = useParams();
  const raceType = (racingType as ERaceType) || ERaceType.Horse;

  // Get the current number from the URL (for the case of navigating to a race other than #1, we can init with correct race number)
  const raceNumberStringParts = raceNumber?.replace(/[^0-9]/g, '');
  const raceNumberFromURL = Number(raceNumberStringParts);

  const [searchParams] = useSearchParams();
  const { raceId, venueId, meetingDate, propId } = {
    venueId: searchParams.get('venueId'),
    raceId: searchParams.get('raceId'),
    meetingDate: searchParams.get('meetingDate'),
    propId: searchParams.get('propId'),
  };

  const { clear, setToteSelections } = actions;

  const isToteMulti = useIsToteMulti();
  const selectedTotePropositionId = useTotePropositionId();
  const toteMultiSelections = useToteSelections();

  const raceVenue = raceRunnerList.venues.find(
    (ven) => ven.venue_id === venueId
  );
  const selectedQuaddieData =
    raceVenue?.race_venue_totes?.totes_by_type[betType];

  useEffect(() => {
    if (!isToteMulti || !selectedQuaddieData) {
      clear();
      return;
    }

    if (
      selectedTotePropositionId &&
      selectedQuaddieData[0]?.proposition_id === selectedTotePropositionId &&
      betType !== EBetTypesDisplayNames.RunningDouble
    )
      return;

    if (propId === selectedTotePropositionId) return;

    clear();

    let selectedToteMultiData = selectedQuaddieData;
    if (betType === EBetTypesDisplayNames.RunningDouble) {
      selectedToteMultiData = selectedQuaddieData?.filter(
        (data) => data.proposition_id === propId
      );
    }

    if (selectedToteMultiData) {
      const { proposition_id: propositionId, legs } =
        selectedToteMultiData[0] ?? {};

      if (!propositionId || !legs) return;
      const selections: TTotoeMultiSelection[] = legs.map((leg) => ({
        raceId,
        raceNumber: `R${leg}`,
        selection: new Set(),
      }));

      setToteSelections(
        propositionId as string,
        betType as TToteMultiType,
        selections
      );
    }
  }, [
    betType,
    clear,
    isToteMulti,
    raceId,
    selectedQuaddieData,
    setToteSelections,
    propId,
    selectedTotePropositionId,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clear(), []);

  const [selectedRaceNumber, setSelectedRaceNumber] =
    useState(raceNumberFromURL);
  const [pollTimer, setPollTimer] = useState<number | null>(5000);

  const [tabContent, setTabContent] = useState<{
    [key: string]: React.ReactNode;
  }>({});

  const { sortRunnerList, sortObj, sortScratchedAndNormalRunners, eventRule } =
    useTable();

  const isResulted = (raceRunnerList?.runnerResults?.length || 0) > 0;
  const results = useMemo(
    () =>
      raceRunnerList?.runnerResults
        ?.filter((result) =>
          ['1', '2', '3', '4'].includes(result?.result_place ?? '')
        )
        ?.sort((a, b) =>
          (a?.result_place ?? '').localeCompare(b?.result_place ?? '')
        ),
    [raceRunnerList?.runnerResults]
  );

  const getRunnerAndExoticResults = (
    raceResults: TRaceResults | null
  ): TRunnerAndExoticResults => {
    const runnerResults: TRunnerResult[] = [];
    raceResults?.runner_results?.forEach((runner) => {
      if (runner) runnerResults.push(runner);
    });
    const exoticResults: TRaceToteResult[] = [];
    raceResults?.exotic_results?.forEach((runner) => {
      if (runner) exoticResults.push(runner);
    });
    return { runnerResults, exoticResults };
  };

  const toteResults = raceRunnerList?.toteMultiResults;

  const { isAuthenticated, isAuthLoading } = useAuth();

  // Promotions
  const { data: promotions } = useQueryPromotions(
    { promo_type: 'money_back' },
    { enabled: !!isAuthenticated }
  );

  const { data: betsForRace, isLoading: betsQueryLoading } = useQuery(
    [keys.betsForRace, raceId, !!isAuthenticated],
    () =>
      queryBetsForRace({
        race_id: raceId || '',
      }),
    { enabled: !!isAuthenticated && Boolean(raceId) }
  );

  // Page load/race change API Calls for fresh data
  useEffect(() => {
    setRaceDataLoading(true);
    let stillMounted = true;
    const requestCancellers: ((reason?: string | undefined) => void)[] = [];
    (async () => {
      if (!raceId || !meetingDate || !venueId) {
        navigate('/');
        return;
      }

      const venueListReq = dispatch(
        getRacingVenueList({ raceType, date: meetingDate })
      );
      const raceMetaReq = dispatch(getRaceMeta(raceId));
      const otherRacesReq = dispatch(getOtherRacesSameVenue(raceId));
      const raceRunnersReq = dispatch(getRaceRunnerList(raceId));
      const marketsListReq = dispatch(getMarketsList(raceId));
      const raceResultsReq = dispatch(getRaceResults(raceId));

      requestCancellers.push(venueListReq.abort);
      requestCancellers.push(raceMetaReq.abort);
      requestCancellers.push(otherRacesReq.abort);
      requestCancellers.push(raceRunnersReq.abort);
      requestCancellers.push(raceResultsReq.abort);
      requestCancellers.push(marketsListReq.abort);

      const [venues, raceMeta, otherRaces, raceRunners, raceResults, markets] =
        await Promise.all([
          venueListReq.unwrap(),
          raceMetaReq.unwrap(),
          otherRacesReq.unwrap(),
          raceRunnersReq.unwrap(),
          raceResultsReq.unwrap(),
          marketsListReq.unwrap(),
        ]);

      if (!venues || !otherRaces || !raceMeta || !raceRunners) {
        navigate('/');
        return;
      }

      if (!stillMounted) return;

      const runnerAndExoticResults = getRunnerAndExoticResults(raceResults);
      const toteMultis = raceResults?.tote_multi_results as TToteMultiResults[];
      dispatch(
        setRaceRunnerList({
          venues,
          otherRaces,
          raceMeta,
          raceRunners: sortScratchedAndNormalRunners(raceRunners, {
            col: 'number',
            order: 'DESC',
          }),
          runnerResults: runnerAndExoticResults.runnerResults,
          exoticResults: runnerAndExoticResults.exoticResults,
          toteMultiResults: toteMultis,
          markets,
        })
      );

      setPollTimer(5000);
      setRaceDataLoading(false);
    })().catch(logError);
    return () => {
      requestCancellers.forEach((reqCancel) => reqCancel());
      stillMounted = false;
    };
  }, [
    raceId,
    meetingDate,
    dispatch,
    navigate,
    raceType,
    selectedRaceNumber,
    sortScratchedAndNormalRunners,
    venueId,
  ]);

  // Interval function that will poll for runners/results every pollTimer ms
  useInterval(async () => {
    if (!raceId || !meetingDate) return;

    const [raceRunners, raceResults, raceMeta, otherRaces, venues] =
      await Promise.all([
        dispatch(getRaceRunnerListPolling(raceId)).unwrap(),
        dispatch(getRaceResults(raceId)).unwrap(),
        dispatch(getRaceMeta(raceId)).unwrap(),
        dispatch(getOtherRacesSameVenue(raceId)).unwrap(),
        dispatch(getRacingVenueList({ raceType, date: meetingDate })).unwrap(),
      ]);
    if (!raceRunners || !raceRunnerList || !raceMeta) return;

    // If current race is not in venue list -> venue_races then navigate to home (race has been set to hidden)
    if (
      venues
        ?.find((ven) => ven.venue_id === venueId)
        ?.venue_races?.find((v) => v.race_id === raceId) === undefined
    ) {
      // venues
      navigate('/');
      return;
    }

    if (raceMeta.status === EGeneralStatus.Closed) {
      navigate(
        `/racing/${racingType}/${venueName}/${raceNumber}?venueId=${venueId}&raceId=${raceId}&meetingDate=${meetingDate}`
      );
    }

    const runnerAndExoticResults = getRunnerAndExoticResults(raceResults);

    dispatch(
      setRaceRunnerList({
        ...raceRunnerList,
        venues: venues as TVenue[],
        otherRaces: otherRaces as TRace[],
        raceMeta,
        raceRunners: sortScratchedAndNormalRunners(raceRunners, sortObj),
        runnerResults: runnerAndExoticResults.runnerResults,
        exoticResults: runnerAndExoticResults.exoticResults,
        toteMultiResults:
          raceResults?.tote_multi_results as TToteMultiResults[],
      })
    );
  }, pollTimer);

  // Build tab titles once per page render
  const pendingTabTitles = useMemo(
    () => displayMarkets.map((market) => market.name),
    [displayMarkets]
  );
  const resultedTabTitles = useMemo(
    () => [Strings.resultsTabs.results],
    [Strings]
  );
  const tabTitles = useMemo(
    () => (isResulted ? resultedTabTitles : pendingTabTitles),
    [isResulted, pendingTabTitles, resultedTabTitles]
  );

  // Rebuild Markets JSX each time raceDetailsState data changes
  useEffect(() => {
    const contentJSX: { [key: string]: React.ReactNode } = {};
    if (!raceRunnerList?.raceRunners) return;
    const activeRaceRunnersCount = (raceRunnerList?.raceRunners ?? []).filter(
      (runner) => runner?.status !== EGeneralStatus.Scratched
    ).length;

    const isWinMarketAvailable = raceRunnerList.raceRunners.every(
      (runner) => runner.win_proposition !== null
    );
    const isPlaceMarketAvailable = raceRunnerList.raceRunners.every(
      (runner) => runner.place_proposition !== null
    );

    const { RacingWin, RacingPlace } = EBetTypesDisplayNames;
    const winMarket = getMarketByType(raceRunnerList.markets ?? [], RacingWin);
    const placeMarket = getMarketByType(
      raceRunnerList.markets ?? [],
      RacingPlace
    );

    // Render helpers
    const winMarketHasSP = winMarket && marketHasSPOnly(winMarket);
    const placeMarketHasSP = placeMarket && marketHasSPOnly(placeMarket);

    const hasBadges = raceRunnerList.raceRunners
      .map((runner) => !!runner?.form_data?.badges)
      .some(Boolean);
    const hasFlucs = raceRunnerList.raceRunners
      .map((runner) => !!runner?.flucs_data)
      .some(Boolean);

    const winOdds: number[] = raceRunnerList.raceRunners
      .filter((status) => status.status !== EGeneralStatus.Scratched)
      .map((runner) => runner.win_odds ?? 0);
    const favouritePrice = Math.min(...winOdds);

    const hasWinTote = raceRunnerList.raceRunners
      .map(
        (runner) =>
          !!runner?.win_proposition?.price_types?.includes('tote_single_mid') ||
          !!runner?.win_proposition?.price_types?.includes('tote_single_best')
      )
      .some(Boolean);

    const hasBestTote = raceRunnerList.raceRunners
      .map(
        (runner) =>
          !!runner?.win_proposition?.price_types?.includes('tote_single_best')
      )
      .some(Boolean);

    const hasPlaceTote = raceRunnerList.raceRunners
      .map(
        (runner) =>
          !!runner?.place_proposition?.price_types?.includes('tote_single_mid')
      )
      .some(Boolean);

    const isToteActive: boolean = isLargerThan768 ? true : showTotes;
    const shouldRenderWinMarkets = isLargerThan768 ? true : !showTotes;

    tabTitles.forEach((tab) => {
      const exoticResults = raceRunnerList?.exoticResults;

      contentJSX[tab] = (
        <RaceRunnerListWrapper>
          {isResulted && results && (
            <RaceResultListWrapper>
              <RaceResults
                raceMarkets={raceRunnerList.markets}
                runnerResults={results}
                supportedBets={supportedBets ?? {}}
                isGreyhoundSilk={raceType === ERaceType.Greyhound}
                numberOfPlaces={raceRunnerList?.raceMeta.number_of_places || 0}
              />
              {exoticResults && exoticResults.length > 0 && (
                <ExoticResults
                  results={exoticResults}
                  multiResults={toteResults as TToteMultiResults[]}
                />
              )}
            </RaceResultListWrapper>
          )}
          {(tab === EBetTypesDisplayNames.WinPlace ||
            tab === EBetTypesDisplayNames.Win) && (
            <OnRaceBets
              betsForRace={betsForRace}
              betsQueryLoading={betsQueryLoading}
              isAuth={isAuthenticated}
              isAuthLoading={isAuthLoading}
            />
          )}
          <RaceRunnerList resultsShowing={isResulted && results !== null}>
            <RaceRunnerListHeadings
              resultsShowing={isResulted && results !== null}
            >
              <RaceRunnerHeadingContainer
                onClick={() => sortRunnerList('barrier_number')}
                mr="auto"
              >
                <RaceDetailsHeading>{Strings.runnerHeading}</RaceDetailsHeading>
                {sortObj.col === 'barrier_number' && (
                  <Icon
                    as={sortObj.order === 'ASC' ? SortDown : SortUp}
                    height={3}
                    width={3}
                    color="black"
                  />
                )}
              </RaceRunnerHeadingContainer>
              <Flex
                flexDir={['column', 'row']}
                mr="1"
                gap="2"
                alignItems={['flex-end', 'center']}
              >
                <Flex>
                  {hasFlucs && (
                    <RaceRunnerHeadingContainer>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        ml="3"
                      >
                        <RaceDetailsHeading
                          htmlFor="flucs"
                          mr="1"
                          textTransform="capitalize"
                        >
                          <FormattedMessage id="racing.generic.flucs" />
                        </RaceDetailsHeading>
                        <Switch
                          id="flucs"
                          size="sm"
                          onChange={() => setShowFlucs(!showFlucs)}
                          defaultChecked={showFlucs}
                        />
                      </FormControl>
                    </RaceRunnerHeadingContainer>
                  )}
                  {!isResulted && hasBadges && (
                    <RaceRunnerHeadingContainer>
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        ml="3"
                      >
                        <RaceDetailsHeading
                          htmlFor="insights"
                          mr="1"
                          textTransform="capitalize"
                          w="auto"
                        >
                          <FormattedMessage id="racing.generic.insights" />
                        </RaceDetailsHeading>
                        <Switch
                          id="insights"
                          size="sm"
                          onChange={() => setShowInsights(!showInsights)}
                          defaultChecked={showInsights}
                        />
                      </FormControl>
                    </RaceRunnerHeadingContainer>
                  )}
                  {!isResulted &&
                    (hasWinTote || hasPlaceTote) &&
                    supportedBets?.tote_singles !== false && (
                      <RaceRunnerHeadingContainer>
                        <FormControl
                          display="flex"
                          alignItems="center"
                          justifyContent="end"
                          ml="3"
                        >
                          <RaceDetailsHeading
                            htmlFor="insights"
                            mr="1"
                            textTransform="capitalize"
                          >
                            <FormattedMessage id="racing.generic.tote" />
                          </RaceDetailsHeading>
                          <Switch
                            id="insights"
                            size="sm"
                            onChange={() => setShowTotes(!showTotes)}
                            defaultChecked={showTotes}
                          />
                        </FormControl>
                      </RaceRunnerHeadingContainer>
                    )}
                </Flex>
                <RaceRunnerWinPlaceContainer>
                  {isWinMarketAvailable && shouldRenderWinMarkets && (
                    <RaceRunnerPropositionHeadingContainer
                      onClick={() => sortRunnerList('win_odds')}
                      showingPlaceOdds={activeRaceRunnersCount >= 5}
                    >
                      <RaceDetailsHeading w="8">
                        {winMarketHasSP ? (
                          <FormattedMessage id="racing.raceDetails.winSPHeading" />
                        ) : (
                          <FormattedMessage id="racing.raceDetails.winHeadingFO" />
                        )}
                      </RaceDetailsHeading>
                      {sortObj.col === 'win_odds' && (
                        <Icon
                          as={sortObj.order === 'ASC' ? SortDown : SortUp}
                          height={3}
                          width={3}
                          color="black"
                        />
                      )}
                    </RaceRunnerPropositionHeadingContainer>
                  )}

                  {activeRaceRunnersCount >= 5 &&
                    isPlaceMarketAvailable &&
                    shouldRenderWinMarkets && (
                      <RaceRunnerPropositionHeadingContainer
                        onClick={() => sortRunnerList('place_odds')}
                      >
                        <RaceDetailsHeading w="8">
                          {placeMarketHasSP ? (
                            <FormattedMessage id="racing.raceDetails.placeSPHeading" />
                          ) : (
                            <FormattedMessage id="racing.raceDetails.placeHeadingFO" />
                          )}
                        </RaceDetailsHeading>
                        {sortObj.col === 'place_odds' && (
                          <Icon
                            as={sortObj.order === 'ASC' ? SortDown : SortUp}
                            height={3}
                            width={3}
                            color="black"
                          />
                        )}
                      </RaceRunnerPropositionHeadingContainer>
                    )}

                  {!isResulted &&
                    supportedBets?.tote_singles !== false &&
                    hasWinTote &&
                    showTotes &&
                    isToteActive && (
                      <RaceRunnerPropositionHeadingContainer>
                        <RaceDetailsHeading w="8">
                          <FormattedMessage
                            id={
                              hasBestTote
                                ? 'racing.raceDetails.bestToteWinHeading'
                                : 'racing.raceDetails.toteWinHeading'
                            }
                          />
                        </RaceDetailsHeading>
                      </RaceRunnerPropositionHeadingContainer>
                    )}

                  {!isResulted &&
                    supportedBets?.tote_singles !== false &&
                    hasPlaceTote &&
                    showTotes &&
                    isToteActive && (
                      <RaceRunnerPropositionHeadingContainer>
                        <RaceDetailsHeading w="8">
                          <FormattedMessage id="racing.raceDetails.totePlaceHeading" />
                        </RaceDetailsHeading>
                      </RaceRunnerPropositionHeadingContainer>
                    )}
                </RaceRunnerWinPlaceContainer>
              </Flex>
            </RaceRunnerListHeadings>
            <RaceRunnerListContainer>
              {raceRunnerList?.raceRunners.map((runner) => (
                <RaceRunnerListItem
                  key={runner.race_runner_id}
                  runner={runner}
                  isInsightsActive={!isResulted && hasBadges && showInsights}
                  isFavourite={runner.win_odds === favouritePrice}
                  isFlucsVisible={showFlucs}
                  isToteActive={showTotes}
                  shouldShowPlaceOdds={activeRaceRunnersCount >= 5}
                  supportedBets={supportedBets ?? {}}
                />
              ))}

              {eventRule && (
                <RunnerListItemWrapper>
                  <RunnerListItemContainer>
                    <RunnerName>
                      <Icon boxSize="5" as={ErrorWarning} pr="1" />
                      <Text as="span" fontWeight="normal">
                        <FormattedMessage id={getEventRuleText(eventRule)} />
                      </Text>
                    </RunnerName>
                  </RunnerListItemContainer>
                </RunnerListItemWrapper>
              )}
            </RaceRunnerListContainer>
          </RaceRunnerList>
        </RaceRunnerListWrapper>
      );
    });
    setTabContent(contentJSX);
    setSelectedRaceNumber(raceNumberFromURL);
  }, [
    toteResults,
    isResulted,
    navigate,
    setTabContent,
    raceRunnerList,
    tabTitles,
    Strings,
    dispatch,
    raceType,
    sortObj,
    sortRunnerList,
    venueId,
    theme,
    results,
    venueListLoading,
    ErrorOccurredToast,
    raceNumberFromURL,
    eventRule,
    showInsights,
    showFlucs,
    showTotes,
    isLargerThan768,
    supportedBets,
    betsForRace,
    isAuthenticated,
    isAuthLoading,
    betsQueryLoading,
  ]);

  // Checks API Payload to find current venue as not returned in race_meta
  const currentVenue = useMemo(() => {
    if (!raceRunnerList?.venues) return null;
    const venue = raceRunnerList?.venues?.find((ven) =>
      ven?.venue_races?.some((race) => race?.race_id === raceId)
    );
    if (!venue) return null;
    return venue;
  }, [raceId, raceRunnerList]);

  // This function will need an array of objs with raceId & race number (relative to track)
  // Rebuild Race Navigation Buttons whenever page rebuilds
  const raceNavigationData = useMemo(() => {
    if (!raceRunnerList?.raceMeta || !currentVenue) return [];

    function raceHasPromo(
      race_id: string,
      active_runner_count: number | undefined
    ) {
      const racePromotions = promotions?.data.map((promotion) =>
        promotion.race_ids?.includes(race_id) ? promotion : undefined
      );
      return racePromotions
        ?.map(
          (promotion) =>
            promotion &&
            active_runner_count &&
            !(
              promotion.min_active_runners &&
              active_runner_count &&
              promotion.min_active_runners > active_runner_count
            )
        )
        .some((value) => value);
    }

    const { race_number: raceNumberMeta, status } = raceRunnerList.raceMeta;
    const activeRunners = raceRunnerList.raceRunners.filter(
      (runner) =>
        runner.status &&
        runner.status !== EGeneralStatus.Abandoned &&
        runner.status !== EGeneralStatus.Scratched &&
        runner.status !== EGeneralStatus.Removed
    );

    const currPath = `/racing/${racingType}/${currentVenue.venue_display_name}/R`;

    const navData = [
      {
        display: raceNumberMeta,
        status,
        onClick: () => null,
        hasPromo: raceHasPromo(raceId ?? '', activeRunners.length),
        promotion: raceHasPromo(raceId ?? '', activeRunners.length)
          ? promotions?.data.find((promotion) =>
              promotion?.race_ids?.includes(raceId ?? '')
            )
          : null,
      },
      ...raceRunnerList.otherRaces.map((race) => {
        let isToteMultiTile =
          isToteMulti &&
          selectedQuaddieData &&
          selectedQuaddieData[0]?.legs?.includes(race.race_number);

        let rdPropId = '';
        if (betType === EBetTypesDisplayNames.RunningDouble) {
          const selectedRunningDouble = selectedQuaddieData?.find(
            (sq) => sq.proposition_id === propId
          );
          isToteMultiTile = selectedRunningDouble?.legs?.includes(
            race.race_number
          );
          if (isToteMultiTile) {
            rdPropId = selectedRunningDouble?.proposition_id ?? '';
          }
        }

        const promotionExists = raceHasPromo(
          race?.race_id ?? '',
          race?.active_runner_count ?? undefined
        );

        return {
          display: race.race_number,
          hasPromo: promotionExists,
          promotion: promotionExists
            ? promotions?.data.find((promotion) =>
                promotion?.race_ids?.includes(race.race_id)
              )
            : null,
          status: currentVenue.venue_races?.find(
            (r) => r.race_id === race.race_id
          )?.status,
          onClick: () => {
            setPollTimer(null);
            setSelectedRaceNumber(race?.race_number || 1);
            navigate(
              isToteMultiTile
                ? `${currPath}${race.race_number}/${betType}?venueId=${
                    currentVenue.venue_id
                  }&raceId=${race.race_id}&meetingDate=${meetingDate}${
                    rdPropId ? `&propId=${rdPropId}` : ''
                  }`
                : `${currPath}${race.race_number}?venueId=${currentVenue.venue_id}&raceId=${race.race_id}&meetingDate=${meetingDate}`
            );
          },
        };
      }),
    ];

    return navData.sort((a, b) => (a.display || 0) - (b.display || 0));
  }, [
    raceRunnerList,
    currentVenue,
    navigate,
    meetingDate,
    selectedQuaddieData,
    racingType,
    isToteMulti,
    promotions,
    raceId,
    propId,
    betType,
  ]);

  // Button Group Buttons
  const Buttons = (
    <>
      {raceNavigationData.map((btnData) => {
        let className =
          isToteMulti &&
          betType !== EBetTypesDisplayNames.RunningDouble &&
          selectedQuaddieData &&
          selectedQuaddieData[0]?.legs?.includes(btnData.display)
            ? 'quaddie'
            : '';
        if (betType === EBetTypesDisplayNames.RunningDouble) {
          const selectedRunningDouble = selectedQuaddieData?.find(
            (sq) => sq.proposition_id === propId
          );
          if (!selectedRunningDouble) return null;

          if (selectedRunningDouble?.legs?.includes(btnData.display)) {
            className = 'quaddie';
          }
        }

        if (raceNumber?.slice(1) === btnData.display?.toString()) {
          className += ' active';
        }

        const selection = toteMultiSelections.find(
          (s) => s.raceNumber === `R${btnData.display}`
        );
        let raceHasBeenAdded = false;
        if ((selection?.selection.size ?? 0) > 0) {
          raceHasBeenAdded = true;
        }

        return (
          <Button
            data-active={selectedRaceNumber === btnData.display}
            data-closed={pastRace(btnData.status)}
            data-hasPromo={btnData?.hasPromo}
            {...raceDetailsStyles.buttonStyledGroupedProps}
            key={btnData.display}
            onClick={btnData.onClick}
            data-cy={`raceNumberButton-${
              selectedRaceNumber === btnData.display ? 'active' : 'default'
            }`}
            className={className}
          >
            {raceHasBeenAdded && <Box {...tickForToteMulti} />}

            {btnData.display}
            {btnData?.hasPromo && (
              <Tooltip
                hasArrow
                closeDelay={100}
                {...badgeStyles.tooltipWrapper}
                isDisabled={!btnData.promotion}
                label={
                  <VStack alignItems="start">
                    <Flex
                      sx={{
                        textTransform: 'uppercase',
                        color: '#333',
                        flexDir: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <IconSvg
                        name="money-back"
                        className="svgIcon"
                        {...badgeStyles.moneybackBadge}
                      />
                      <Text {...badgeStyles.moneybackTitle}>
                        <FormattedMessage id="racing.racedetails.moneyBack" />
                      </Text>
                    </Flex>
                    <Text {...badgeStyles.textDescription}>
                      {btnData?.promotion?.name ?? ''}
                    </Text>
                    <Text {...badgeStyles.textDescription}>
                      {btnData?.promotion?.description ?? ''}
                    </Text>
                  </VStack>
                }
                placement="bottom-end"
              >
                <Flex {...raceDetailsStyles.flexIconWrapper}>
                  <IconSvg name="money-back" className="svgIcon" boxSize="6" />
                </Flex>
              </Tooltip>
            )}
          </Button>
        );
      })}
    </>
  );

  const formData = raceRunnerList.raceMeta?.form_data;
  const raceInfo = !!(
    formData?.track_condition ||
    formData?.weather_condition ||
    formData?.race_distance
  );

  const evenShotMarket = getMarketByType(
    raceRunnerList.markets ?? [],
    EBetTypesDisplayNames.EvenShot
  );

  const mysteryBetMarket = getMarketByType(
    raceRunnerList.markets ?? [],
    EBetTypesDisplayNames.MysteryBet
  );

  const isMysteryBetEnabled = useFeatureFlag('MYSTERY_BET_ENABLED');

  const shouldShowMysteryBet =
    isMysteryBetEnabled &&
    isAuthenticated &&
    supportedBets?.mystery_bet &&
    !!mysteryBetMarket &&
    !betType;

  return (
    <PrimaryWrapper
      pageTitle={Strings.pageTitle}
      pageHeader={
        <RaceHeaderWrapper>
          <FlexPageHeaderTitleWrapper>
            <FlexHeaderLeft>
              <TextTitle data-cy="venueName">{venueName}</TextTitle>
              {raceRunnerList && (
                <FlexHeaderMetaWrapper>
                  <RaceName data-cy="raceName" noOfLines={1}>
                    {raceRunnerList?.raceMeta.display_name?.toLowerCase()}
                  </RaceName>
                </FlexHeaderMetaWrapper>
              )}
            </FlexHeaderLeft>

            {raceRunnerList && (
              <RaceAndTimerContainer>
                {raceInfo && (
                  <FlexHeaderMetaWrapper>
                    {formData?.track_condition && (
                      <TextRaceConditions data-cy="raceTrack">
                        <span>
                          <FormattedMessage id="racing.raceDetails.track" />
                        </span>
                        {formData?.track_condition}
                      </TextRaceConditions>
                    )}
                    {formData?.weather_condition && (
                      <TextRaceConditions data-cy="raceWeather">
                        <span>
                          <FormattedMessage id="racing.raceDetails.weather" />
                        </span>
                        {formData.weather_condition}
                      </TextRaceConditions>
                    )}
                    {formData?.race_distance && (
                      <TextRaceConditions data-cy="raceDistance">
                        <span>
                          <FormattedMessage id="racing.raceDetails.distance" />
                        </span>
                        {formData.race_distance}
                        <b>m</b>
                      </TextRaceConditions>
                    )}
                  </FlexHeaderMetaWrapper>
                )}

                {raceRunnerList?.raceMeta?.status &&
                  raceRunnerList?.raceMeta?.start_time && (
                    <RaceStatus
                      status={raceRunnerList?.raceMeta.status}
                      startTime={raceRunnerList?.raceMeta.start_time}
                    />
                  )}
              </RaceAndTimerContainer>
            )}
          </FlexPageHeaderTitleWrapper>
          <InfoContainer>
            <ButtonGroupWrapper>{Buttons}</ButtonGroupWrapper>
          </InfoContainer>
          {raceRunnerList.raceMeta?.status !== EGeneralStatus.Settled &&
            EGeneralStatus.Abandoned &&
            EGeneralStatus.Closed && <TabBar supportedBets={supportedBets} />}
        </RaceHeaderWrapper>
      }
    >
      {raceRunnerList?.raceMeta.status === EGeneralStatus.Abandoned && (
        <RaceAlertBanner>
          <RaceAlertBannerContent>
            <SVGIcon
              svgName="iconAlertYellow"
              styles={theme.pages.raceDetails.RaceAlertIcon}
            />
            <RaceAlertText>
              {Strings.raceStatusPrefix}
              &nbsp;
              {raceRunnerList.raceMeta?.status ?? ''}
            </RaceAlertText>
          </RaceAlertBannerContent>
        </RaceAlertBanner>
      )}

      {shouldShowMysteryBet && (
        <MysteryBetContainer
          race={raceRunnerList.raceMeta}
          betsForRace={betsForRace}
          market={mysteryBetMarket}
          venueName={venueName ?? ''}
        />
      )}

      {betType === 'SRMulti' && <SRMulti />}
      {betType === 'Blended' && <Blended />}
      {betType === 'Even Shot' && (
        <EvenShot race={raceRunnerList.raceMeta} market={evenShotMarket} />
      )}
      {betType &&
        betType !== 'SRMulti' &&
        betType !== 'Blended' &&
        betType !== 'Even Shot' && (
          <Exotics sortRunnerList={() => sortRunnerList('barrier_number')} />
        )}

      {!betType && (
        <TabbedDisplay
          resultsShowing={isResulted && results !== null}
          initTab={tabTitles[0]}
          tabs={tabTitles}
          content={tabContent}
          layout="fullWidth"
          loading={raceDataLoading}
        />
      )}
    </PrimaryWrapper>
  );
};

export default RaceDetails;
