import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    zIndex: '9',
    mx: 'auto',
    mb: '1',
    pl: ['2', '0'],
    pt: '2.5',
    pb: '1',
    borderRadius: 'lg',
    sx: {
      scrollbarWidth: 'none',
    },
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecorationColor: 'white',
    gap: '1.5',
    position: 'relative',
    zIndex: '9999',
    color: 'beta.800',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    fontSize: 'xs',
    fontWeight: 'extrabold',
    color: 'beta.800',
    zIndex: '9999',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
      '&:not([href])': {
        color: 'delta.500',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'beta.300',
  pr: '1.5',
  h: '.8rem',
  w: 'auto',
};
