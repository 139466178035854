import { CSSObject } from '@chakra-ui/react';
import betslipBgTexture from '@/assets/templebet/images/backgrounds/betslips-bg-testure.png';
import betslipCornerRope from '@/assets/templebet/images/betslip/rope.png';
import { CustomButtonProps } from '@/components/Button/Button';

export const FlexBetSlipModalContainer: CSSObject = {
  bg: `linear-gradient(180deg, #FFF1CB 0%, var(--bc-colors-beta-200) 100%), url(${betslipBgTexture})`,
  boxShadow:
    '0px 0px 0px 3px var(--bc-colors-beta-300) inset, 0px 0px 0px 6px #FFF1CB inset, 0px 5px 0px 0px var(--bc-colors-beta-300)',
  bgSize: '100% auto',
  backgroundPositionY: 'top',
  backgroundBlendMode: 'darken',
  h: '100%',
  maxW: '272px',
  maxH: ['100dvh', null, 'full'],
  borderRadius: '2xl',
  border: '6px solid',
  borderColor: '#FFF1CB',
  contain: 'layout',
  _before: {
    content: '""',
    position: 'absolute',
    left: '-11px',
    top: '-8px',
    background: `url(${betslipCornerRope})`,
    bgSize: '100%',
    height: '12',
    aspectRatio: '1',
    filter: 'drop-shadow(3px 1px 7px black);',
  },
  _after: {
    content: '""',
    position: 'absolute',
    right: '-14px',
    top: '-2',
    background: `url(${betslipCornerRope})`,
    bgSize: '100%',
    height: '12',
    aspectRatio: '1',
    transform: 'rotateY(150deg)',
    filter: 'drop-shadow(3px 1px 7px black);',
  },
};

export const LowStakePrompt: CSSObject = {
  color: 'white',
};

export const FlexBetGroupContainer = (isKeypadVisible: boolean): CSSObject => ({
  p: '4',
  mb: isKeypadVisible && 'unset',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const BetSlipFooterMotion: CSSObject = {
  position: ['relative', null, null, null],
};

export const BetSlipFooterContainer: CSSObject = {
  bg: 'transparent ',
  '&&': {
    p: '4',
    pt: '2',
  },
  borderTopColor: 'whiteAlpha.500',
  borderTopWidth: '1.5px',
  borderStyle: 'dashed',
  position: 'relative',
  _before: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: 'full',
    borderTop: '1.5px',
    borderColor: 'blackAlpha.200',
    borderStyle: 'dashed',
  },
};

export const BetSlipFooterRowTitle: CSSObject = {
  fontSize: 'md',
  fontWeight: 'bold',
  color: 'beta.800',
  mt: '1',
};

export const BetSlipFooterRowValue = (): CSSObject => ({
  fontSize: 'md',
  fontWeight: 'bold',
  color: 'alpha.500',
  mt: '1',
});

export const buttonMakeDepositProps: CustomButtonProps = {
  variant: 'solid',
  h: '10',
  fontFamily: 'accent',
  fontWeight: 'normal',
  sx: {
    ':hover': {
      color: 'whiteAlpha.700',
    },
  },
};

export const LowStakeDivider: CSSObject = {
  borderColor: 'blackAlpha.300',
  opacity: '1',
  borderStyle: 'dashed',
};

export const buttonClearBetsProps: CustomButtonProps = {
  color: 'white',
  h: '6',
  borderRadius: 'md',
  fontWeight: 'hairline',
  margin: 'auto',
  mt: '-5',
  position: 'relative',
  lineHeight: 'normal',
  minW: '16',
  pr: '3',
  pl: '2',
  bgGradient:
    'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
  boxShadow:
    '0px 0px 0px 2px var(--bc-colors-beta-500) inset, 0px 0px 0px 3px var(--bc-colors-alpha-800) inset, 0px 0px 0px 4px var(--bc-colors-gammaAlpha-200) inset',
  _hover: {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
    boxShadow:
      '0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
  },
  _active: {
    boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
  },
  sx: {
    '.chakra-button__icon': {
      mr: '1',
      zIndex: 9,
    },
    '.chakra-icon': {
      boxSize: '3',
    },
    '&&': {
      fontSize: '2xs',
    },
  },
};

export const buttonConfirmBetsProps: CustomButtonProps = {
  variant: 'odds',
  height: '10',
  fontFamily: 'accent',
  fontSize: 'md',
  fontWeight: 'normal',
  textTransform: 'capitalize',
};

export const BetslipVStack: CSSObject = {
  gap: '2',
};
