import * as betgalaxy from './Group.styles.betgalaxy';
import * as betprofessor from './Group.styles.betprofessor';
import * as betroyale from './Group.styles.betroyale';
import * as goldenrush from './Group.styles.goldenrush';
import * as juicybet from './Group.styles.juicybet';
import * as puntcity from './Group.styles.puntcity';
import * as puntgenie from './Group.styles.puntgenie';
import * as questbet from './Group.styles.questbet';
import * as sterlingparker from './Group.styles.sterlingparker';
import * as vikingbet from './Group.styles.vikingbet';
import * as volcanobet from './Group.styles.volcanobet';
import * as wellbet from './Group.styles.wellbet';
import * as forsport from './Group.styles.forsport';
import * as fiestabet from './Group.styles.fiestabet';
import * as junglebet from './Group.styles.junglebet';
import * as oldgill from './Group.styles.oldgill';
import * as sugarcastle from './Group.styles.sugarcastle';
import * as buffalobet from './Group.styles.buffalobet';
import * as templebet from './Group.styles.templebet';
import * as slambet from './Group.styles.slambet';
import * as gigabet from './Group.styles.gigabet';
import * as luckystride from './Group.styles.luckystride';
import * as goldenbet888 from './Group.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  betroyale,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  wellbet,
  forsport,
  fiestabet,
  junglebet,
  oldgill,
  sugarcastle,
  buffalobet,
  templebet,
  slambet,
  gigabet,
  luckystride,
  goldenbet888,
};
