import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  borderRadius: 'md',
  border: '1px solid',
  borderImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'beta.400',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, whiteAlpha.400, whiteAlpha.400)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const secondary = {
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, beta.200, beta.500)',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'alpha.500',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.25)',
  _hover: {
    bgGradient: 'linear(to-b, beta.600, beta.600)',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(125, 253, 42, 0.7)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'beta.400',
  color: 'white',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'whiteAlpha.100',
    border: '2px',
    borderColor: 'white',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: '0px 0px 8px rgba(37, 254, 214, 0.7)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const primaryAccess = {
  borderRadius: 'md',
  border: '1px solid',
  borderImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
  bg: 'alpha.300',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'gamma.400',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'whiteAplha.400',
  },
  ':active, &[aria-expanded=true]': {
    bg: 'blackAlpha.700',
    borderImage: 'linear-gradient(to bottom, beta.500, beta.500)',
    borderBottom: 0,
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const bets = {
  borderRadius: 'md',
  border: '1px solid',
  borderImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
  bg: 'alpha.300',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'gamma.400',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bg: 'whiteAplha.400',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
const odds = {
  borderRadius: 'base',
  border: '1px solid',
  borderImage:
    'linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.11))',
  bgGradient:
    'linear(to-b, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'beta.400',
  fontFamily: 'ProximaNova',
  fontSize: 'sm',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(0, 0, 0, 0.5)',
  _hover: {
    bgGradient: 'linear(to-b, whiteAlpha.500, whiteAlpha.500)',
    border: 'none',
  },
  ':active, &[data-active=true]': {
    bgGradient: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bg: 'linear-gradient(to-b, blackAlpha.400, blackAlpha.400)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    color: 'whiteAlpha.400',
    textShadow: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};
export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    primaryAccess: () => ({
      ...primaryAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
