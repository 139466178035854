import { BoxProps, CSSObject } from '@chakra-ui/react';

export const BoxWrapper: BoxProps = {
  display: 'flex',
  alignItems: 'center',
};

export const FlexWrapper: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  minW: ['unset', null, 'fit-content'],
};

export const TextFormInfo: CSSObject = {
  mr: ['1', '2'],
};
