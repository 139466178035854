import React from 'react';
import { CSSObject, Icon } from '@chakra-ui/react';
import { Prohibited } from '@styled-icons/foundation/Prohibited';
import { BetCardSchema } from '@/components/Betslip/components/BetCard/styles/BetCard.styles';

export const betCardStyles: BetCardSchema = {
  buttonBonusBetsProps: {
    display: 'flex',
    variant: 'unstyled',
    size: 'xs',
    px: '3',
    alignSelf: 'flex-start',
    textTransform: 'capitalize',

    boxShadow:
      '0px 1px 1px 0px rgba(255, 255, 255, 0.70) inset, 0 2px 3px 0 rgba(0, 0, 0, 0.15)',
    color: 'alpha.800',
    borderRadius: 'md',
    bg: 'beta.400',
    spanProps: {
      fontFamily: 'roboto',
      textShadow: 'none',
      fontWeight: 'medium',
      fontSize: '2xs',
      px: '2',
      py: '1.5',
    },

    border: 'none',
    borderColor: 'gray.900',

    sx: {
      '.chakra-button__icon': {
        display: 'none',
      },

      '&&[data-isbonusbet="true"]': {
        bg: 'alpha.800',
        color: 'alpha.200',
        boxShadow: '0px 0px 5px 0px #FFDD31 inset',
        border: '1px',
        borderColor: 'beta.400',
        borderRadius: 'md',

        spanProps: {
          px: '2',
          py: '5px',
        },
        '.chakra-button__icon': {
          mr: '0',
          display: 'inline-block',
        },
        svg: {
          ml: '2',
        },

        _disabled: {
          w: '100%',
          mt: '-3',
          bg: 'beta.400',
          borderRadius: 'none',
          borderBottomRadius: 'md',

          spanProps: {
            textShadow: '0px 3px 3px rgba(0, 0, 0, 0.20)',
            py: '2',
            textTransform: 'uppercase',
          },

          boxShadow: '0 1px 0 0 #5e5f6e, inset 0 1px 4px 0 #0000004c',
          border: 'none',
          color: 'alpha.700',
          opacity: '1',
          '& > span': {
            _first: {
              mr: '1',
            },
          },
        },
      },
    },
  },
  buttonBetPropositionClosedProps: {
    px: '7',
    mx: '6',
    zIndex: '1',
    bg: 'rgba(0, 64, 114, 1)',
    boxShadow: 'md',
    color: 'white',
    borderRadius: 'md',
    fontSize: 'xs',
    display: 'flex',
    gap: '1',
    spanProps: {
      color: 'white',
      fontFamily: 'roboto',
      fontSize: 'xs',
      fontWeight: 'bold',
      px: '3.5',
      py: '2',
      textTransform: 'uppercase',
    },
    _hover: {
      bg: 'alpha.800',
      color: 'white',
      boxShadow: 'md',
    },
  },
  buttonStakeProps: {
    variant: 'secondary',
    fontSize: 'xs',
    fontFamily: 'Roboto',
    textShadow: '0px 1px 1px rgba(255, 255, 255, 0.50)',
    fontWeight: 'black',
  },
  flexWrapperMysteryBetFooterBetReview: {
    borderRadius: 'md',
    bg: 'rgba(1, 43, 76, 1)',
    color: 'rgba(255, 198, 30, 1)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    fontWeight: 'bold',
    fontSize: '2xs',
    w: '100%',
    minH: '6',
    lineHeight: '25px',
  },
  spanRaceInfo: {
    fontSize: '2xs',
  },
  textSlash: {
    fontSize: '2xs',
  },
  spanBetOdds: {
    fontSize: '2xs',
  },
  iconBetCardSvgMysteryBet: {
    sx: {
      '&&': {
        color: 'beta.400',
        mr: 0,
      },
    },
  },
  flexWrapperMysteryRollover: {
    sx: { '&&': { px: '0' } },
  },
  betCardSVG: {
    fill: 'alpha.500',
  },
};

export const BetCardVStack: CSSObject = {
  pb: '0',
};

export const StakeCollapse: CSSObject = {
  px: '0',
};

export const BetOdds: CSSObject = {
  mb: '2px',
};

export const BetCardContainer = (): CSSObject => ({
  bg: 'alpha.500',
  borderRadius: 'md',
  color: 'white',
  p: '2',
  boxShadow:
    '0px 1px 0px 0px rgba(255, 255, 255, 0.20), 0px 0px 4px 0px rgba(0, 0, 0, 0.30) inset',
  '&::before': {
    content: `""`,
    position: 'absolute',
    borderRadius: 'md',
    bgSize: '100%',
    bgRepeat: 'no-repeat',
    top: '-5',
    w: '100%',
    h: '8',
  },
});

export const IconButtonThemed: CSSObject = {
  w: 'unset',
  minW: 'unset',
  justifyContent: 'right',
  color: 'beta.400',
  mr: '-1',
  ml: '1',
  transition: 'all .2s ease-in-out',
  _hover: {
    color: 'beta.500',
  },
};

export const InfoBetOdds: CSSObject = {
  color: 'beta.400',
  display: 'flex',
  fontWeight: 'extrabold',
  fontSize: 'md',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const EventTitle: CSSObject = {
  fontWeight: 'extrabold',
  fontSize: 'sm',
  flex: '1',
  textTransform: 'capitalize',
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const EventMarketName = (isExtraInfo: boolean) => ({
  display: !isExtraInfo && 'none',
  fontSize: 'xs',
  color: 'beta.400',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  lineHeight: '4',
  mb: '-1',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
});

export const EventSubtitle: CSSObject = {
  color: 'white',
  textTransform: 'capitalize',
  fontSize: 'xs',
  pt: '0.5',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const ExoticsPlaceContainer: CSSObject = {
  fontWeight: 'bold',
  fontSize: 'xs',
  width: '25%',
  justifyContent: 'space-between',
};

export const TextExoticSelections: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'medium',
  color: 'beta.400',
};

export const BetPlaced: CSSObject = {
  bgImage: 'linear-gradient(180deg, #FFFFFF 0%, #CBD5E0 6.25%, #FFFFFF 100%);',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2), inset 0px 1px 0px #FFFFFF;',
  borderRadius: 'base',
  flexDirection: 'column',
  color: 'black',
  px: '2',
  py: '1.5',
};

export const TextExoticsLabel: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const TextExoticsInfo: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
};

export const DefaultStakeFlexbox: CSSObject = {
  gap: '0.5',
};

export const BetPlacedRow: CSSObject = {
  justifyContent: 'flex-start',
};

export const TextPlacedLabel: CSSObject = {
  flex: '1',
  color: 'gray.600',
};

export const BetPropositionClosedError: CSSObject = {
  bg: 'rgba(0, 103, 178, 0.7)',
  borderRadius: 'base',
  pb: '3.5',
  top: '-1px',
  left: '-1px',
  right: '-1px',
  h: 'calc(100% + 2px)',
  w: 'auto',
};

export const BetCardSVG = (header: boolean) => ({
  color: header ? 'beta.400' : 'beta.500',
  boxSize: '6',
  display: header ? 'block' : 'none',
  mr: '0',
});

export const FlexBonusBetsWrapper = (displayBonusBetsButton: boolean) => ({
  mt: displayBonusBetsButton && '0',
  px: '0',
});

export const IconPropositionClosed = () => (
  <Icon as={Prohibited} sx={{ boxSize: '5', '&:hover': { color: 'white' } }} />
);

export const EventRule: CSSObject = {
  ml: '2',
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
};

export const ExoticsNumberContainer: CSSObject = {
  minW: '6',
  fontWeight: 'bold',
  color: 'beta.400',
};
