import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    boxSize: '30px',
    minWidth: '7',
    minHeight: '7',
    fontSize: 'xs',
    borderRadius: 'md',
  },
};

export const FlexOdds: CSSObject = {
  mr: '0',
  h: '9',
  w: '14',
  minW: '12',
  display: 'flex',
  justifyContent: 'center',
  px: '2',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  lineHeight: 'normal',
  border: '2px solid',
  borderColor: 'white',
  bg: 'gray.300',
  borderRadius: 'md',
  color: 'blackAlpha.400',
  fontWeight: 'bold',
  fontSize: 'sm',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.20) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: '1',
  mb: '1',
  '.chakra-button': {
    display: 'flex',
    width: '8',
    height: '9',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontSize: 'sm',
    fontWeight: 'black',
    color: '#255505',
  },
};

export const HStackActions: CSSObject = {
  gap: '1',
  display: 'flex',
  alignItems: 'flex-end',
  '.fallback-wrapper': {
    borderRadius: '2px',
    background: 'blackAlpha.200',
    display: 'flex',
    width: '30px',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
};

export const CheckboxRunnerBoxed: CSSObject = {
  padding: '5px',
  '.chakra-checkbox__control': {
    width: '20px',
    height: '20px',
    bg: 'gray.50',
    borderWidth: '1px',
    borderColor: 'gray.300',
    '&[data-checked]': {
      width: '20px',
      height: '20px',
      bg: 'gray.50',
      color: 'gray.700',
      borderColor: 'gray.300',
    },
    '&[data-checked]:hover': {
      width: '20px',
      height: '20px',
      bg: 'gray.50',
      borderColor: 'gray.300',
    },
  },
  '.chakra-checkbox__control:hover': {
    width: '20px',
    height: '20px',
    bg: 'gray.50',
    borderColor: 'gray.300',
  },
};
