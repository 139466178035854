import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, alpha.300, alpha.400)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'beta.400',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'linear(to-b, alpha.400, alpha.400)',
    color: 'alpha.100',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.200',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(to-b, gamma.300, gamma.500)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.15) inset, 0px 3px 5px 0px rgba(0, 0, 0, 0.3)',
  color: 'beta.700',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'gamma.200',
  },
  ':active, &[data-active=true]': {
    bg: 'linear(to-b, blackAlpha.500, blackAlpha.500)',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.400',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.200',
  color: 'white',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: 'none',
  _hover: {
    border: '2px',
    borderColor: 'gamma.400',
    boxShadow: '0px 0px 8px 0px rgba(83, 249, 169, 0.4)',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.200',
    textShadow: 'none',
    border: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const primaryAccess = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, alpha.300 0%, alpha.400 100%)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 4px 10px 0px rgba(0, 0, 0, 0.3)',
  color: 'beta.400',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'alpha.400',
  },
  ':active, &[aria-expanded=true]': {
    bg: 'white',
    color: 'alpha.500',
    boxShadow: 'none',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const bets = {
  position: 'relative',
  borderRadius: 'md',
  border: 'none',
  bgGradient: 'linear(180deg, alpha.300 0%, alpha.400 100%)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 4px 10px 0px rgba(0, 0, 0, 0.3)',
  color: 'gamma.400',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.3)',
  _hover: {
    bgGradient: 'linear(180deg, beta.300 0%, beta.400 100%)',
    color: 'alpha.100',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'alpha.100',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  borderRadius: 'base',
  border: 'none',
  bgGradient: 'linear(180deg, beta.300 0%, beta.500 100%)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.3)',
  color: 'alpha.600',
  fontFamily: 'Staatliches',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bg: 'gamma.200',
  },
  ':active, &[data-active=true]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 4px 0px rgba(0, 0, 0, 0.35) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset',
    color: 'gray.300',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    primaryAccess: () => ({
      ...primaryAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
