import { CSSObject } from '@chakra-ui/react';

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.400',
};

export const BoxMoreText: CSSObject = {
  w: '8',
  cursor: 'default',
};

export const DeductionsText: CSSObject = {
  color: 'gray.400',
  fontSize: '2xs',
  fontWeight: 'semibold',
};

export const FooterBodyText: CSSObject = {
  color: 'gray.300',
};

export const footerWrapperExpanded: BoxProps = {
  sx: {
    pt: '0',
  },
};
