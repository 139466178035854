import { ButtonProps, CSSObject, theme } from '@chakra-ui/react';

const primary: CSSObject = {
  borderRadius: 'md',
  bg: 'alpha.400',
  color: 'beta.400',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'sm',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',

  '&&&': {
    _hover: {
      bg: 'alpha.500',
      color: 'beta.500',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
    _disabled: {
      pointerEvents: 'none',
    },
  },
};

const secondary: CSSObject = {
  borderRadius: 'md',
  bg: 'beta.400',
  boxShadow:
    '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  color: 'alpha.600',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.30)',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'sm',

  '&&&': {
    _hover: {
      bg: 'beta.600',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
      color: 'alpha.800',
      textShadow: '0px 1px 1px rgba(255, 255, 255, 0.30)',
    },
  },

  '&[data-active="false"]': {
    bg: 'beta.400',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    h: '36px',
  },

  '&[data-active="true"]': {
    bg: 'alpha.600',
    color: 'beta.400',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    h: '36px',
    '&&:hover': {
      bg: 'alpha.800',
      color: 'alpha.200',
    },
  },
};

const outline: CSSObject = {
  borderRadius: 'lg',
  bg: 'transparent',
  color: 'white',
  fontFamily: 'accent',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  fontSize: 'sm',
  border: '2px',
  borderColor: 'white',

  _hover: {
    bg: 'whiteAlpha.300',
    borderColor: 'alpha.400',
    boxShadow:
      '0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
  },
};

const tab: CSSObject = {
  '&, &[data-active="false"]': {
    ...secondary,
    fontFamily: 'body',
    fontWeight: 'bold',
    fontSize: 'xs',
    borderRadius: 'base',
  },

  '&:active, &[data-active="true"]': {
    bg: 'alpha.800',
    color: 'alpha.200',
    textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
    boxShadow:
      '0px 1px 2px 0px rgba(255, 255, 255, 0.40), 0px 1px 0px 0px #000 inset',
  },
};

const segment: CSSObject = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',

  _hover: {
    color: 'alpha.800',
  },

  _active: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.60) inset, 0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
    color: 'alpha.800',
  },
};

const filter: CSSObject = {
  border: '2px',
  borderColor: 'alpha.300',
  borderRadius: 'base',
  color: 'white',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '6',
  minW: 'auto',
  px: '2.5',
  py: '1',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'beta.400',
  },
  _active: {
    bgGradient: 'linear(to-b, beta.300, beta.400)',
    border: 'none',
    boxShadow:
      '0px 2px 2px 0px rgba(0, 0, 0, 0.25), 0px 2px 1px 0px rgba(255, 255, 255, 0.45) inset',
    color: 'alpha.800',
  },
};

const numb: CSSObject = {
  ...tab,
  '&&[data-closed="true"]': {
    bg: 'gray.300',
    color: 'gray.500',
    textShadow: 'px 1px 1px rgba(255, 255, 255, 0.50)',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.20) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    '&:active, &[data-active="true"]': {
      bg: 'alpha.600',
      color: 'beta.400',
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
      boxShadow:
        '0px 1px 0px 0px rgba(255, 255, 255, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    tab: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...tab,
    }),
    numb: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...numb,
    }),
    filter: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...filter,
    }),
    segment: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...segment,
    }),
    unstyled: () => ({}),
  },
  defaultProps: {},
};
