import { CSSObject } from '@chakra-ui/react';

export const BoxWrapper: CSSObject = {
  minH: undefined,
};

export const TitleContainer = (): CSSObject => ({
  w: 'full',
  bg: 'white',
  '&&': {
    px: '2',
    pb: '2',
  },
  mt: ['unset', '2'],
  borderTopRadius: 'base',
  overflow: 'visible',
  color: 'alpha.700',
  fontWeight: 'medium',
  pb: '0',
});

export const TextTitle = (): CSSObject => ({
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'alpha.700',
});

export const BoxHeading: CSSObject = {
  mb: '0',
};

export const WrapperContainer: CSSObject = {
  px: '0',
  borderTopRadius: 'none',
  borderBottomRadius: 'base',
  color: 'delta.50',
  borderTop: 'none',
};
