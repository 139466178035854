import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  mx: ['-2', '-1'],
  px: ['2', '1'],
  py: '2',
  my: '2',
  gap: '1',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};
