import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: 'base',
    bgGradient: 'linear(to-b, #FFE6B6, beta.500)',
    px: '2.5',
    pt: '1',
    pb: '1.5',
    mb: '4',
    h: '9',
    display: 'flex',
    alignItems: 'center',
    borderColor: '#FFE6B6',
    borderWidth: '2px',
    boxShadow:
      '0px 2px 7px 0px rgba(0, 0, 0, 0.30), 0px 0px 0px 2px #302410 inset, 0px 0px 0px 3.5px #FFE6B6 inset',
  },
  breadcrumbItemBreadcrumb: {
    textDecoration: 'none',
    sx: {
      '&:first-of-type': {
        textDecoration: 'underline',
        textDecorationColor: 'blackAlpha.700',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(238, 238, 238, 0.40)',
    sx: {
      '&:last-of-type': {
        textShadow: 'none',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'blackAlpha.700',
};
