import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  bg: 'alpha.700',
  pb: '3',
  mb: ['5', null, '3'],
  mx: ['-2', '0'],
  px: ['2', '0'],
  mt: '0',
  gap: '1',
  borderEnd: '1px',
  borderEndColor: 'gamma.100',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
};

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
};
