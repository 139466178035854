import { PromotionsStylesSchema } from './Promotions.styles';

export const promotionsStyles: PromotionsStylesSchema = {
  flexTabWrapper: {
    bg: 'blackAlpha.400',
    boxShadow:
      '0px 3px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.10)',
    borderRadius: 'lg',
    my: 2,
    fontFamily: 'Staatliches',
    fontWeight: '400',
  },
  heading: {
    fontWeight: 'semibold',
    fontSize: 'sm',
    mt: '-2',
  },
  promotionWrapper: {
    bg: 'alpha.500',
    my: '3',
    boxShadow:
      '0px 0px 0px 2px #FFC033 inset, 0px 0px 0px 3px #751410 inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 'md',
    sx: {
      img: {
        borderTopRadius: 'md',
        pt: '1px',
        px: '1px',
      },
    },
  },
  flexWrapper: {
    gap: '4',
    p: '3',
    justifyContent: 'space-between',
    borderTop: '3px solid',
    borderColor: 'beta.500',
    mx: '1px',
  },
  termsAndConditions: {
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
  },
};
