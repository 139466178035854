import { ButtonProps, theme } from '@chakra-ui/react';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.300 0%, gamma.400 100%)',
  color: 'beta.800',
  fontFamily: 'accent',
  lineHeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 4px rgba(255, 255, 255, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 2px #FFD69E inset, 0px 0px 0px 3px #BD873E inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(to-b, yellow.600 0%, yellow.600 100%)',
    textShadow: '0px 0px 8px rgba(251, 201, 21, 0.50)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  borderRadius: 'md',
  border: '2px solid',
  borderColor: 'beta.500',
  color: 'beta.100',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  boxShadow:
    '0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 0px 1px #FFC878 inset, 0px 0px 0px 2px rgba(255, 255, 255, 0.3) inset',
  _hover: {
    bgGradient: 'linear(180deg, beta.600 0%, beta.600 100%)',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.3)',
    color: 'gamma.300',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    border: 'none',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  bg: 'transparent',
  border: '2px',
  borderColor: 'alpha.200',
  color: 'white',
  fontFamily: 'accent',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
  _hover: {
    bg: 'blackAlpha.500',
  },
  _active: {
    bg: 'blackAlpha.600',
    boxShadow:
      '0px 0px 14px 0px rgba(0, 0, 0, 0.5) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.15)',
    color: 'gamma.300',
    border: 'none',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    border: 'none',
    color: 'alpha.300',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    '&&:hover': {
      bg: 'blackAlpha.400',
      color: 'alpha.300',
      textShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
    },
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
