import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonTabBarProps: CustomButtonProps = {
  variant: 'tab',
  minW: 'auto',
  px: '2',
  py: '1.5',
  maxH: '6',
  fontSize: '2xs',
};

export const BoxWrapper: CSSObject = {
  borderRadius: ['none', 'base'],
  mb: '-2',
  mx: ['-2', '0'],
  mr: ['-1', 'unset'],
  px: ['2', '0'],
  mt: '2',
  gap: '1',
  pb: '0.5',
};

export const buttonTabBarMeetingsProps: CustomButtonProps = {
  variant: 'tab',
  maxH: '6',
  minW: 'auto',
  fontSize: '2xs',
  px: '2',
  py: '1',
  _active: {
    textShadow: '0px -1px 0px rgba(0, 0, 0, 0.25)',
  },
};
