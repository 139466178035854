import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'primary',
  height: '38px',
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },

  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.800',
    border: '2px',
    borderLeft: 'none',
    borderRightColor: 'beta.400',
    borderTopColor: 'beta.400',
    borderBottomColor: 'beta.400',
    color: 'white',
    fontWeight: 'bold',
    borderRightRadius: 'base ',

    _placeholder: {
      color: 'white',
      opacity: '0.5',
    },

    _hover: {
      bg: 'blackAlpha.200',
      borderRightColor: 'beta.400',
      borderTopColor: 'beta.400',
      borderBottomColor: 'beta.400',
    },

    _focus: {
      borderRightColor: 'beta.400',
      borderTopColor: 'beta.400',
      borderBottomColor: 'beta.400',
      bg: 'alpha.900',
      boxShadow: 'none',
      _placeholder: {
        color: 'white',
        opacity: '0.5',
      },
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bg: 'beta.400',
    borderLeftRadius: 'base',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.40)',
    color: 'alpha.600',
  },
};

export const SelectionPopupContainer: CSSObject = {
  mt: '2.5',
  bg: 'alpha.500',
  boxShadow: '0px -4px 6px 0px rgba(0, 0, 0, 0.15)',
  borderBottomRadius: 'base',
  zIndex: '10',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, beta.300, beta.400)',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'alpha.700',
  fontWeight: 'bold ',
};

export const AddToBetslipButton: CSSObject = {
  fontSize: 'sm',
  bg: 'beta.600',
  color: 'blackAlpha.600',
  fontFamily: 'Caveman',
  borderRadius: 'base',
  border: '2px',
  borderColor: 'beta.400',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.25), inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
  _hover: {
    bg: 'beta.700',
    borderColour: 'transparent',
  },
  _active: {
    bg: 'alpha.600',
    color: 'white',
    boxShadow: 'inset 0px 2px 5px rgba(0, 0, 0, 0.5)',
  },
};

export const TextAddToBetslipButton: CSSObject = {
  w: '267px',
  fontWeight: 'black',
  textShadow: 'none',
};
