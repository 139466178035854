import { CSSObject } from '@chakra-ui/react';
import { RaceTableSchema } from '@/views/races/RaceMeetings/RaceTable/styles/RaceTable/RaceTable.styles';

export const raceTableStyles: RaceTableSchema = {
  iconSvgMoneyBackProps: { color: 'beta.500', mr: ['20', 0] },
  afterLinkRaceItemProps: { bg: [null, null, 'beta.500'] },
};

export const FlexHeadingRow: CSSObject = {};

export const FlexHeading: CSSObject = {
  color: 'white',
  top: ['12', '84px'],
  position: 'unset',
  mb: '1',
  px: '1',
};

export const TextHeading: CSSObject = {
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  color: 'gamma.200',
  pt: '1',
};

export const IconHeading: CSSObject = {
  boxSize: '8',
  color: 'alpha.800',
  bgGradient: 'linear(to-b, gamma.500, gamma.600)',
  border: 'none',
  borderRadius: 'md',
  p: '2',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset',
};

export const BoxPageWrapper: CSSObject = {
  m: '0',
  mb: '4',
  p: '0.5',
  px: '1',
  bg: 'alpha.200',
  borderRadius: 'lg',
  boxShadow:
    '0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 3px rgba(255, 255, 255, 0.6) inset',
};

export const BoxTableWrapper: CSSObject = {
  p: '0',
  'div:last-of-type': {
    div: {
      borderBottom: 'none',
      borderBottomLeftRadius: 'md',
      borderBottomRightRadius: ['md', null, 'none'],
      mb: 'px',
    },
  },
};

export const TextVenueName: CSSObject = {
  fontWeight: 'medium',
  color: 'gamma.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextVenueCountry: CSSObject = {
  fontWeight: 'medium',
  color: 'gamma.500',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
};

export const TextRaceResults: CSSObject = {
  color: 'white',
};

export const BadgeRaceItem = ({ state }: any): CSSObject => ({
  borderRadius: 'base',
  bg: (() => {
    if (state === 'result') {
      return 'black';
    }
    return 'blackAlpha.400';
  })(),
});

export const LinkRaceItem = () => ({
  bg: ['transparent', null, 'alpha.200'],
  borderLeft: 'none',
  borderTop: 'none',
  borderBottom: ['none', null, '1px solid'],
  borderBottomColor: ['unset', null, 'blackAlpha.300'],
  borderRightColor: ['unset', null, 'blackAlpha.300'],

  _hover: {
    background: ['blackAlpha.300', null, 'alpha.300'],
  },
  _last: {
    borderRight: 'none',
  },
});

export const FlexRow: CSSObject = {
  mb: '0',
  borderRadius: 'none',
  contain: 'unset',
  _last: {
    borderBottom: 'none',
  },
};

export const FlexButtonWrapper: CSSObject = {
  ml: 'auto',
  flexDir: 'column',
  columnGap: '4',
};

export const BoxVenueName: CSSObject = {
  bg: 'alpha.200',
  py: ['2', null, '1.5'],
  borderTop: 'none',
  borderBottom: ['1px dashed', null, '1px solid'],
  borderRight: ['none', null, '1px solid'],
  borderBottomColor: ['rgba(0, 0, 0, 0.25)', null, 'blackAlpha.300'],
  borderRightColor: ['unset', null, 'blackAlpha.300'],
};

export const CenterTH = (): CSSObject => ({
  bg: '#C72C24',
  color: 'gamma.200',
  textAlign: 'center',
  fontSize: '2xs',
  fontStyle: 'normal',
  fontWeight: 'extrabold',
  lineHeight: 'normal',
  py: '2.5',
  border: '1px solid',
  borderLeft: 'none',
  borderColor: 'blackAlpha.300',
  textTransform: 'uppercase',
});

export const ButtonChevron: CSSObject = {
  color: 'gamma.500',
  bg: 'blackAlpha.400',
  borderRadius: 'base',
  boxSize: '7',
  width: '7',
  _first: {
    '&&': {
      mb: '0.5',
    },
  },
};

export const BadgeRaceNumber: CSSObject = {
  bg: 'gray.300',
  color: 'gray.600',
  py: '1',
  borderRadius: 'base',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
};
