import * as betgalaxy from './Info.styles.betgalaxy';
import * as betprofessor from './Info.styles.betprofessor';
import * as goldenrush from './Info.styles.goldenrush';
import * as juicybet from './Info.styles.juicybet';
import * as vikingbet from './Info.styles.vikingbet';
import * as sugarcastle from './Info.styles.sugarcastle';
import * as buffalobet from './Info.styles.buffalobet';
import * as puntgenie from './Info.styles.puntgenie';
import * as slambet from './Info.styles.slambet';
import * as gigabet from './Info.styles.gigabet';
import * as luckystride from './Info.styles.luckystride';
import * as goldenbet888 from './Info.styles.goldenbet888';
import * as oldgill from './Info.styles.oldgill';
import * as templebet from './Info.styles.templebet';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betgalaxy,
  betprofessor,
  goldenrush,
  juicybet,
  vikingbet,
  sugarcastle,
  buffalobet,
  puntgenie,
  slambet,
  gigabet,
  luckystride,
  goldenbet888,
  oldgill,
  templebet,
};
