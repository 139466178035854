import { CSSObject } from '@chakra-ui/react';

export const BoxHeaderText: CSSObject = {
  fontSize: 'lg',
  fontWeight: 'normal',
  fontFamily: 'accentMusashi',
  letterSpacing: '1px',
  color: 'gamma.200',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  pb: '2',
  textTransform: 'uppercase',
};

export const BoxHeader: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  mb: ['4px', null, '0px'],
};
