import * as betprofessor from './Results.styles.betprofessor';
import * as betroyale from './Results.styles.betroyale';
import * as fiestabet from './Results.styles.fiestabet';
import * as goldenrush from './Results.styles.goldenrush';
import * as juicybet from './Results.styles.juicybet';
import * as puntcity from './Results.styles.puntcity';
import * as puntgenie from './Results.styles.puntgenie';
import * as questbet from './Results.styles.questbet';
import * as sterlingparker from './Results.styles.sterlingparker';
import * as vikingbet from './Results.styles.vikingbet';
import * as volcanobet from './Results.styles.volcanobet';
import * as junglebet from './Results.styles.junglebet';
import * as sugarcastle from './Results.styles.sugarcastle';
import * as oldgill from './Results.styles.oldgill';
import * as buffalobet from './Results.styles.buffalobet';
import * as slambet from './Results.styles.slambet';
import * as gigabet from './Results.styles.gigabet';
import * as templebet from './Results.styles.templebet';
import * as luckystride from './Results.styles.luckystride';
import * as goldenbet888 from './Results.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betprofessor,
  betroyale,
  fiestabet,
  goldenrush,
  juicybet,
  puntcity,
  puntgenie,
  questbet,
  sterlingparker,
  vikingbet,
  volcanobet,
  junglebet,
  sugarcastle,
  oldgill,
  buffalobet,
  slambet,
  gigabet,
  templebet,
  luckystride,
  goldenbet888,
};
