export const keys = {
  banners: 'banners',
  promos: 'promos',
  privacy: 'privacy',
  location: 'location',
  contentful: 'contentful',
  auth: 'auth',
  punterAccount: 'punterAccount',
  punterAccountOverview: 'punterAccountOverview',
  punterAccountMyBets: 'punterAccountMyBets',
  punterGetZohoToken: 'punterGetZohoToken',
  depositGetFatZebraToken: 'depositGetFatZebraToken',
  depositGetCards: 'depostiGetCards',
  offerings: 'offerings',
  racing: 'racing',
  betRequests: 'betRequests',
  ntj: 'ntj',
  ntg: 'ntg',
  venueList: 'venueList',
  runnerList: 'runnerList',
  matches: 'matches',
  marketingPreferences: 'marketingPreferences',
  sportDetails: 'sportDetails',
  transactions: 'transactions',
  sportList: 'sportList',
  sportDetailsMarketGroups: 'sportDetailsMarketGroups',
  sportDetailsMarkets: 'sportDetailsMarkets',
  sportDetailsPropositions: 'sportDetailsPropositions',
  sportDetailsProps: 'sportDetailsProps',
  sportDetailsMatches: 'sportDetailsMatches',
  supportedBets: 'supportedBets',
  statements: 'statements',
  raceMarkets: 'raceMarkets',
  homepage: 'homepage',
  promotions: 'promotions',
  cms: 'cms',
  upcomingMatches: 'upcomingMathces',
  upcomingMatchesBySport: 'upcomingMatchesBySport',
  upcomingSports: 'upcomingSports',
  sportCompetitions: 'sportCompetitions',
  offeringsNew: 'offeringsNew',
  blended: 'blended',
  evenShot: 'evenShot',
  betsForRace: 'betsForRace',
  cashoutValue: 'cashoutValue',
  depositLimit: 'depositLimit',
} as const;
