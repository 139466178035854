import { CSSObject } from '@chakra-ui/react';
import { ExoticsActionSchema } from '@/views/races/bets/Exotics/components/Action/styles/Action.styles';

export const exoticsActionStyles: ExoticsActionSchema = {
  buttonExoticsActionProps: {
    variant: 'solid',
    minW: '12',
    maxH: '8',
    fontSize: 'sm',
    fontWeight: 'black',
    fontFamily: 'body',
    borderRadius: 'md',
    _disabled: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'white',
      bg: 'gray.300',
      borderRadius: 'md',
      color: 'blackAlpha.400',
      maxH: '8',
      fontWeight: 'black',
      fontSize: 'sm',
      boxShadow:
        '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
      cursor: 'not-allowed',
      textShadow: 'unset',
      _hover: {
        bg: 'gray.300',
        boxShadow:
          '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
        color: 'blackAlpha.400',
        borderColor: 'white',
      },
      _after: {
        display: 'none',
      },
    },
  },
};

export const FlexOdds: CSSObject = {
  minW: '12',
  height: '8',
  p: '2',
  borderRadius: 'md',
  fontWeight: 'bold',
  fontSize: 'sm',
  bg: 'gray.300',
  borderWidth: '2px',
  borderColor: 'white',
  color: 'blackAlpha.500',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.15)',
  boxShadow:
    '0px 0px 0px 2px rgba(0, 0, 0, 0.30) inset, 0px 2px 6px 0px rgba(0, 0, 0, 0.35)',
  opacity: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ActionsContainer: CSSObject = {
  display: 'flex',
  flexDir: 'row',
  gap: ['1', null, '2'],
};

export const CheckboxRunnerBoxed: CSSObject = {
  '.chakra-checkbox__control': {
    color: 'gamma.400',
    borderColor: 'gamma.400',
    border: '1px solid',
    _hover: {
      bg: 'alpha.800',
    },
  },
  _checked: {
    '.chakra-checkbox__control': {
      border: '1px',
      borderColor: 'gamma.400',
      bg: 'alpha.700',
    },
  },

  '.chakra-checkbox__label': {
    marginInlineStart: '0',
  },
  flexDir: 'column',
  gap: '2',
  boxSize: '30px',
  borderRadius: 'base',
  alignItems: 'center',
  justifyContent: 'center',
};
