import { HomeLinksSchema } from './HomeLinks.styles';

export const homeLinkStyles: HomeLinksSchema = {
  wrapper: {
    flexDirection: 'row',
    justifyContent: ['flex-start', null, 'flex-start'],
    my: '1',
    mx: ['1', '1', '0'],
    py: '1',
    maxW: ['100dvw', null, 'unset'],
    overflowX: ['scroll', null, 'auto'],
    overflowY: 'hidden',
    px: '0',
    sx: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    gap: 2,
  },
  container: {
    display: 'flex',
    flexDir: 'row',
    mt: ['0', null, '1.5'],
    gap: '1',
    w: ['full', null, 'unset'],
  },
  racingBtnsContainer: {
    display: 'contents',
  },
  sportBtnsContainer: {
    display: 'contents',
  },
  racingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'beta.500',
    boxSize: '6',
  },
  sportIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'beta.500',
    boxSize: '6',
    filter: 'drop-shadow(0px 1px 0px var(--bc-colors-blackAlpha-500))',
    mr: 0,
  },
  linkIconBox: {
    minW: '50px',
    h: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'md',
    bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
    color: 'beta.500',
    _hover: {
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
    },
    _active: {
      boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
      backgroundImage:
        'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
    },
    sx: {
      display: {
        base: 'none',
        xl: 'flex',
      },
      '&:nth-child(-n+10)': {
        display: { md: 'flex' },
      },
    },
  },
  linkBox: {
    minW: ['calc(100%/1.5)', null, '58px'],
    h: '44px',
    pl: '9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'md',
    bg: 'linear-gradient(180deg, var(--bc-colors-alpha-500) 0%, var(--bc-colors-alpha-600) 100%)',
    boxShadow:
      '0px 4px 6px 0px var(--bc-colors-blackAlpha-500), 0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 4px #20273f inset, 0px 0px 0px 5px var(--bc-colors-whiteAlpha-400) inset',
    color: 'beta.500',
    _hover: {
      bg: 'linear-gradient(180deg, var(--bc-colors-alpha-800) 0%, var(--bc-colors-alpha-800) 100%)',
      boxShadow:
        '0px 0px 0px 2px var(--bc-colors-beta-400) inset, 0px 0px 0px 3.5px var(--bc-colors-alpha-700) inset',
    },
    _active: {
      boxShadow: '0px 1px 0px 0px var(--bc-colors-whiteAlpha-400)',
      backgroundImage:
        'linear-gradient(180deg, var(--bc-colors-alpha-900) 0%, var(--bc-colors-alpha-900) 100%)',
    },
  },
  linkBoxText: {
    display: 'none',
  },
  racingHeader: {
    display: 'none',
  },
  sportsHeader: {
    display: 'none',
  },
  sportsHeaderTile: {
    display: 'none',
  },
  sportIconOverride: {
    // color: 'white',
    // textAlign: 'center',
    // w: '55px',
    /// /h:'44px',
    // py:'12',
    // px:'8',
    // gap:'4',
    // borderRadius:'md',
  },
};
