import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    borderRadius: '4px',
    pl: '18px',
    pr: '10px',
    h: '38px',
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbLinkItem: {
    fontSize: '10px',
    fontWeight: '700',
    color: 'alpha.100',

    _hover: {
      textDecoration: 'underline',
    },

    sx: {
      '&:not([href])': {
        color: 'beta.500',
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'white',
};
