import { CSSObject } from '@chakra-ui/react';

export const ExoticsContainer: CSSObject = {
  px: '0',
  py: '3',
  bg: 'transparent',
  borderRadius: 'base',
  mb: '2',
  flex: '1',
};

export const SelectFieldContainer: CSSObject = {
  w: 'unset',
  gap: ['1', null, '1'],
};

export const CheckboxBoxed: CSSObject = {
  color: 'beta.500',
  right: 0,
  justifyContent: 'flex-end',
  alignItems: 'center',
  mr: '3',
  my: 'auto',
  w: 'auto',
  borderRadius: 'sm',
  borderColor: 'var(--bc-colors-gray-400)',
  '.chakra-checkbox__control': {
    bgGradient:
      'linear-gradient(180deg, var(--bc-colors-beta-700) 0%, var(--bc-colors-beta-800) 100%)',
    border: '1px solid',
    borderColor: 'var(--bc-colors-gray-400)',
    _hover: {
      borderColor: 'var(--bc-colors-gray-400)',
    },
    '&[data-checked]': {
      bg: 'beta.500',
      color: 'beta.900',
      border: '1px solid  var(--bc-colors-beta-800)',
    },
  },
};

export const TextBoxed: CSSObject = {
  color: 'beta.800',
  fontFamily: 'body',
  fontWeight: 'medium',
  fontSize: 'sm',
  mx: '1',
};
