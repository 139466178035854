import React from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { TTransaction } from '@/lib/DBModels';
import { TableText } from '../styles/DesktopTransactionsTable.styles';
import { SUB_TABS_DISPLAY_NAME } from '@/components/TabBar/TabBar';

export type TToteMultiDetailsProps = {
  transaction: TTransaction;
};

export function ToteMultiDetails({ transaction }: TToteMultiDetailsProps) {
  const {
    bet_id: betId,
    race_type: raceType,
    venue_name: venueName,
    race_start_time: raceStartTime,
    tote_multi_type: toteMultiType,
    legs,
    selections,
  } = transaction.details ?? {};

  const selectionData = legs?.map((leg, i) => ({
    race: leg,
    selections: selections ? selections[i].join(', ') : '',
  }));

  return (
    <>
      <TableText fontWeight="bold">{`${raceType} - ${venueName}`}</TableText>
      <TableText>{`${
        SUB_TABS_DISPLAY_NAME[toteMultiType] ?? toteMultiType
      } `}</TableText>
      {selectionData?.map((data, i) => (
        <TableText key={i}>{`R${data.race}: ${data.selections}`}</TableText>
      ))}
      <TableText sx={{ pb: 2 }}>
        <FormattedMessage
          id="account.transactions.eventDateTimeWithValue"
          values={{
            value: dayjs(raceStartTime).format('DD/MM/YYYY HH:mm:ss (z)'),
            time: (chunks) => <time dateTime={raceStartTime}>{chunks}</time>,
          }}
        />
      </TableText>
      <TableText sx={{ pt: 2 }}>
        <FormattedMessage
          id="account.transactions.betIdWithValue"
          values={{ value: betId }}
        />
      </TableText>
    </>
  );
}
