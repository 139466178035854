import { MysteryBetSchema } from '@/views/races/components/MysteryBet/styles/MysteryBet.styles';
import mysteryLogo from '@/assets/core/icons/mb-logo.svg';

export const mysteryBetStyles: MysteryBetSchema = {
  flexWrapperOdds: {
    gap: 2,
  },
  flexWrapperLogoOdds: {
    gap: 3,
  },
  buttonOdds: { variant: 'odds' },
  imageLogo: {
    w: '250px',
    src: mysteryLogo,
  },
  iconMystery: {},
  buttonMysteryLabel: {
    color: 'white',
    gap: '2',
    bg: '#00193c82',
    borderRadius: 'md',
    p: '2',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  flexWrapperMysteryRolloverView: {
    w: 'fit-content',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow: 'sm',
    px: '3',
    color: 'white',
    overflow: 'hidden',
    mx: 'auto',
  },
  flexWrapperMysteryPlacedBetView: {},
  flexWrapperMysteryBetSelectionView: {
    alignItems: 'center',
    justifyContent: 'center',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    boxShadow: 'sm',
    p: '2',
  },
  flexWrapperMysteryBetReviewView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'column',
    gap: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    my: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryErrorView: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDir: 'row',
    w: 'full',
    gap: '2',
    my: '2',
    bg: 'blackAlpha.400',
    borderRadius: 'md',
    p: '2',
    transition: '.3s ease-in-out',
    boxShadow: 'md',
  },
  flexWrapperMysteryTooltipIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    bgGradient: 'linear-gradient(to bottom, #FFF 0%, #B2B2B2 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.40) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: 'sm',
    boxSize: '10',
    p: '2',
    color: 'alpha.500',
    cursor: 'pointer',
  },
  flexWrapperWithTooltip: {
    gap: 2,
  },
  imageMysteryBet: {
    w: '5',
    transform: 'rotate(180deg)',
    cursor: 'pointer',
    mr: '4',
  },
  flexRaceInfo: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'white',
    fontWeight: 'bold',
  },
  textPriceInfo: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'beta.500',
    fontWeight: 'bold',
  },
  textRollover: {
    textTransform: 'uppercase',
    fontSize: 'sm',
    color: 'beta.500',
    fontWeight: 'bold',
  },
  textError: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xs',
  },
  iconBack: {
    boxSize: '10',
    color: 'beta.500',
  },
  iconRace: { color: 'alpha.100' },
  textSpanWhite: {
    color: 'white',
  },
  buttonRollOver: {
    variant: 'secondary',
  },
};
