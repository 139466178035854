import ProximaNovaAltBold from '@/assets/mega777/fonts/ProximaNova-Alt-Bold.otf';
import ProximaNovaAltLight from '@/assets/mega777/fonts/ProximaNova-Alt-Light.otf';
import ProximaNovaAltThin from '@/assets/mega777/fonts/ProximaNova-Alt-Thin.otf';
import ProximaNovaBlack from '@/assets/mega777/fonts/ProximaNova-Black.otf';
import ProximaNovaBold from '@/assets/mega777/fonts/ProximaNova-Bold.otf';
import ProximaNovaExtrabold from '@/assets/mega777/fonts/ProximaNova-Extrabold.otf';
import ProximaNovaThin from '@/assets/mega777/fonts/ProximaNova-Thin.otf';
import ProximaNovaRegular from '@/assets/mega777/fonts/ProximaNova-Regular.otf';

import StaatlichesRegular from '@/assets/mega777/fonts/Staatliches-Regular.ttf';

export const fontFace = `
        @font-face {
          font-family: 'Staatliches';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${StaatlichesRegular}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${ProximaNovaAltBold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url('${ProximaNovaAltLight}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNovaAlt';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ProximaNovaAltThin}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 900;
          font-display: swap;
          src: url('${ProximaNovaBlack}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 800;
          font-display: swap;
          src: url('${ProximaNovaExtrabold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('${ProximaNovaBold}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url('${ProximaNovaRegular}') format('opentype');
        }
        @font-face {
          font-family: 'ProximaNova';
          font-style: normal;
          font-weight: 100;
          font-display: swap;
          src: url('${ProximaNovaThin}') format('opentype');
        }
      `;
