import { EnKeysProps } from './en';

export const en: EnKeysProps = {
  'sports.allsports.azheading': 'A-Z',
  'header.betslipBtn.desc': 'Slip',
  'maintenance.heading': `Restoring Order`,
  'maintenance.error': `<bold>We’re currently performing maintenance on the site.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'error.heading': 'Echoes Of Errors!',
  'error.description': `<bold>We’re sorry about that. We’ve got someone on it.</bold>
  In the meantime, you can refresh the page, or try again later.`,
  'onboarding.locationnotallowed.header': 'Wandering beyond borders',
  'onboarding.locationnotallowed.subheader':
    'You seem to be accessing TitanBet from outside of Australia.',
  'betslip.betslipmodal.emptybetslipheading': "You're just one turn away",
  'betslip.betslipmodal.emptybetslipsubheading':
    'Your bet slip is empty. To get started, add your racing and sports selections.',
  'racing.errorMessages.noRacesAvailable': 'No races are currently available',
};
