import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    minH: 'unset',
    minW: 'unset',
    boxSize: '8',
    sx: {
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&[data-active="true"]': {
        '&&': {
          bg: 'alpha.800',
          boxShadow:
            '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.40) inset',
          color: 'white',
          textShadow: '0px 1px 0px rgba(0, 0, 0, 0.35)',
          border: 'none',
        },
      },

      '&[data-closed="true"]': {
        bg: 'gray.300',
        color: 'blackAlpha.400',
        textShadow: 'unset',
        px: '3',
      },
    },
  },
};

export const FlexPageHeaderTitleWrapper: CSSObject = {
  mb: ['0', '0', '1'],
};

export const ResultedStatusLabel: CSSObject = {
  color: 'white',
  bg: '#38A169',
};

export const RaceStatusText: CSSObject = {
  bg: '#DD6B20',
};

export const RaceAndTimerContainer: CSSObject = {
  bg: ['transparent', 'transparent', 'rgba(237, 242, 247, 1)'],
  borderRadius: 'base',
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.1), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
  mb: ['1.5', null],
};

export const RaceName: CSSObject = {
  fontWeight: 'bold',
  fontSize: ['2xs', 'xs'],
  color: 'alpha.700',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  py: '2',
  my: '-2',
};

export const ButtonGroupWrapper: CSSObject = {
  py: '1',
  gap: '1',
};

export const RaceRunnerList: CSSObject = {
  p: '2',
  border: '1px',
  borderColor: 'alpha.200',
  bg: 'alpha.50',
  borderRadius: 'base',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'capitalize',
  color: 'gray.700',
  fontSize: 'xs',
  fontWeight: 'medium',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      background: 'alpha.400',
    },
  },
  columnGap: '0',
  svg: {
    color: 'white',
  },
};

export const TextTitle: CSSObject = {
  mt: '0',
  fontWeight: 'medium',
  fontSize: 'md',
  textTransform: 'capitalize',
  color: 'alpha.700',
};

export const TextRaceConditions: CSSObject = {
  fontSize: '2xs',
  color: '#0C404C',

  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: '#A0AEC0',
    pos: 'relative',
    mx: '1.5',
  },
};
