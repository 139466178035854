import { CSSObject } from '@chakra-ui/react';

export const FlexWrapper: CSSObject = {
  bg: 'alpha.300',
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
  borderBottomRadius: 'lg',
  boxShadow: '0px -4px 6px 0px rgba(0, 0, 0, 0.15)',
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'white',
    fontWeight: 'bold',
  },
  '.chakra-input__group': {
    transition: '.3s ease-in-out',
    bgGradient: 'linear(to-b, beta.400, #D36A11)',
    border: 'none',
    color: 'white',
    borderRadius: 'lg',
    _placeholder: {
      color: 'white',
    },
    '&:active, &:focus, &:visited, &:hover': {
      // borderColor: 'alpha.600',
      '&&': {
        bg: 'beta.600',
        margin: '2px 2px 2px 0px',
        borderRightRadius: 'lg',
        borderColor: 'alpha.400',
        borderRight: '0.5',
      },
    },

    '.chakra-input__left-addon': {
      boxShadow: 'none',
      border: 'none',
      margin: '2px 0px 2px 2px',
      bg: 'transparent',
      fontWeight: 'extrabold',
      borderLeftRadius: 'lg',
      // @TODO Add to colors.ts/clarify with design team
      color: '#441200',
    },

    '.chakra-input': {
      m: '2px 2px 2px 0px',
      border: 'none',
    },
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bg: 'zeta.400',
  borderRadius: 'base',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bgGradient: 'linear(to-b, beta.400 0%, beta.600 100%)',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};
