import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';

import { AppDispatch } from '../../../redux/store';
import { setPunterAccountOverview } from '../../account/services/account.slices';

import { dollarsToCents, getStrings } from '@/helpers/utils';

import { apiGetRequest, apiPostRequest } from '../../../lib/api/api';
import {
  getAuthUser,
  signUpWithEmail,
  resetPassword,
  signIn,
  signUserOut,
} from '../../../lib/firebase/Authentication';

import { AccountVerificationSchema } from './schemas/accountVerificationSchema';
import { DepositLimitSchema } from './schemas/depositLimitSchema';
import { LoginSchema } from './schemas/loginSchema';

import { FirebaseAuthError, AuthErrorCode } from '../../../lib/firebase/types';
import { Nullable } from '../../../lib/HelperTypes';
import { TFullPunter, TPunterAccountOverview } from '../../../lib/DBModels';
import { DepositLimitData } from './onboarding.types';
import { handlePunterStatus } from '../../../lib/punter/PunterStatus';
import { HTTPError } from '../../../lib/api/types';
import { ENewRelicActions } from '../../../constants/newRelicActions';
import { getUserStoreActions } from '@/store/AuthStore';
import { TPunterAccount } from '@/api/punter/punter.types';

const [
  {
    Onboarding: { FirebaseErrorMessages, Step2, Step3 },
  },
] = getStrings();

/**
 * Signs a user up to a firebase account and creates their entity in the DB.
 *
 * @param email The new user's chosen email address
 * @param password the user's chosen password
 */
const signUserUp = async (
  email: string,
  password: string
): Promise<boolean> => {
  try {
    // Attempt to sign user up
    await signUpWithEmail(email.toLowerCase(), password);
  } catch (e) {
    const error = e as FirebaseAuthError;

    // Throw error if firebase fails
    switch (error.code) {
      default:
        toast.error(FirebaseErrorMessages.UnspecifiedError);
        break;
      case AuthErrorCode.EMAIL_EXISTS:
        toast.error(FirebaseErrorMessages.EmailInUse);
        break;
    }

    return false;
  }

  try {
    // 🚨🚨 This creates a user account on GET - see AppRoutes.tsx comments for race condition
    // Then create their account in the BE.
    // BE errors are handled in the api method
    await apiGetRequest<void>('/punter/account', {
      failMessage: FirebaseErrorMessages.ErrorCreatingAccount,
    });
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * Send the user's info to the BE, then triggers the verification with GreenID.
 *
 * @param verificationForm The form object with the user's info
 */
const verifyUser = async (
  verificationForm: AccountVerificationSchema
): Promise<boolean> => {
  const user = getAuthUser();

  try {
    if (!user?.email) throw new Error(Step2.VerificationErrorText);

    // 🚨🚨 This creates a user account on GET - see AppRoutes.tsx comments for race condition

    // First, we update the punter's profile for the BE to use to verify with GreenID
    const fullPunter = await apiPostRequest<
      TFullPunter,
      Omit<
        TFullPunter,
        | 'is_onboarded'
        | 'identity_verified'
        | 'email_marketing_enabled'
        | 'sms_marketing_enabled'
        | 'phone_marketing_enabled'
        | 'post_marketing_enabled'
      >
    >(
      '/punter/account',
      {
        email: user.email,
        first_name: verificationForm.firstName,
        last_name: verificationForm.lastName,
        mobile: verificationForm.mobile,
        post_code: verificationForm.postcode as string,
        state: verificationForm.state as string,
        street_name: verificationForm.streetName as string,
        street_number: verificationForm.streetNumber as string,
        suburb: verificationForm.suburb as string,
        date_of_birth: dayjs(verificationForm.dateOfBirth).format('YYYY-MM-DD'),
      },
      { failMessage: Step2.VerificationError }
    );

    return !!fullPunter;
  } catch (e) {
    return false;
  }
};

/**
 * Sets a user's deposit limit for their new account.
 *
 * @param depositLimitForm The user's detail form
 */
const setDepositLimit = createAsyncThunk<
  TFullPunter,
  DepositLimitSchema | undefined,
  { dispatch: AppDispatch }
>('punter/setDepositLimit', async (depositLimitForm) => {
  const punter = await apiPostRequest<TFullPunter, Nullable<DepositLimitData>>(
    '/punter/account/deposit-limit',
    depositLimitForm?.limitAmount && depositLimitForm?.limitDuration
      ? {
          amount: dollarsToCents(depositLimitForm.limitAmount),
          frequency: parseInt(
            depositLimitForm.limitDuration.trim().replace(' ', ''),
            10
          ),
        }
      : {
          amount: null,
          frequency: null,
        },
    { failMessage: Step3.DepositLimitError }
  );

  const { setPunterData } = getUserStoreActions();
  setPunterData(punter as TPunterAccount);

  // Explicitly call verify to invoke BE to verify with GreenID the values we just sent
  await apiGetRequest('/punter/account/verify', {
    failMessage: Step2.VerificationError,
  });

  return punter;
});

/**
 * Handles signing a user into the app.
 *
 * @param loginForm The user's login details
 */
const signUserIn = createAsyncThunk<
  TPunterAccountOverview | null,
  LoginSchema,
  { dispatch: AppDispatch }
>('punter/login', async (loginForm: LoginSchema, thunkAPI) => {
  const { NODE_ENV } = window.BETCLOUD_ENV;
  try {
    await signIn(loginForm.email.toLowerCase(), loginForm.password);

    if (window.newrelic) {
      window.newrelic.addPageAction(ENewRelicActions.Login, {
        success: true,
        status_code: 200,
        punter_email: loginForm.email.toLowerCase(),
        environment: NODE_ENV,
      });
    }
  } catch (e) {
    const error = e as FirebaseAuthError;
    const errorResponse = (e as any)?.response?.data; // Assuming the error response is in `response.data`
    // Check for specific INVALID_LOGIN_CREDENTIALS error
    if (
      errorResponse?.error?.message === 'INVALID_LOGIN_CREDENTIALS' ||
      errorResponse?.error?.message === 'INVALID_PASSWORD'
    ) {
      toast.error(FirebaseErrorMessages.WrongCredentials);
    } else {
      if (window.newrelic) {
        window.newrelic.addPageAction(ENewRelicActions.Login, {
          error,
          success: false,
          punter_email: loginForm.email.toLowerCase(),
          environment: NODE_ENV,
        });
      }

      // Throw error if firebase fails
      switch (error.code) {
        default:
          toast.error(FirebaseErrorMessages.UnspecifiedError);
          break;
        case AuthErrorCode.TOO_MANY_REQUESTS:
          toast.error(FirebaseErrorMessages.TooManyRequests);
          break;
        case AuthErrorCode.INVALID_EMAIL:
        case AuthErrorCode.INVALID_PASSWORD:
          toast.error(FirebaseErrorMessages.WrongCredentials);
          break;
        case AuthErrorCode.USER_DISABLED:
          toast.error(FirebaseErrorMessages.UserDisabled);
          break;
        case AuthErrorCode.USER_NOT_FOUND:
          toast.error(FirebaseErrorMessages.UserNotFound);
          break;
      }

      return null;
    }
  }

  try {
    const accountOverview = await apiGetRequest<TPunterAccountOverview>(
      '/punter/account/overview'
    );

    // This checks for the disabled & excluded punters.
    // Throws a error & toast if not normal
    handlePunterStatus(accountOverview);

    thunkAPI.dispatch(setPunterAccountOverview(accountOverview));

    return accountOverview;
  } catch (e) {
    // Sign out if error getting account from BE or
    // if the punter is excluded. See handlePunterStatus

    const err = e as HTTPError;

    if (err.response?.data?.detail?.msg) {
      toast.error(err.response?.data?.detail?.msg);
    }

    await signUserOut();

    return null;
  }
});

// 🚨🚨 This creates a user account on GET - see AppRoutes.tsx comments for race condition
const getUserProfile = createAsyncThunk<
  void,
  undefined,
  { dispatch: AppDispatch }
>('punter/getPunterProfile', async () => {
  const punter = await apiGetRequest<TFullPunter>('/punter/account', {
    failMessage: FirebaseErrorMessages.UserNotFound,
  });

  const { setPunterData } = getUserStoreActions();
  setPunterData(punter as TPunterAccount);
});

/**
 * Handles resetting a user's password.
 *
 * @param email The user's email address
 */
const sendResetPasswordLink = async (email: string): Promise<boolean> => {
  try {
    await resetPassword(email.toLowerCase());

    return true;
  } catch (e) {
    const error = e as FirebaseAuthError;

    // Throw error if firebase fails
    switch (error.code) {
      case AuthErrorCode.INVALID_EMAIL:
      default:
        toast.error(FirebaseErrorMessages.UserNotFound);
        break;
      case AuthErrorCode.USER_DISABLED:
        toast.error(FirebaseErrorMessages.UserDisabled);
        break;
    }

    return false;
  }
};

export {
  signUserUp,
  verifyUser,
  setDepositLimit,
  sendResetPasswordLink,
  signUserIn,
  getUserProfile,
};
