import { CSSObject } from '@chakra-ui/react';

export const DeductionsText: CSSObject = {
  color: 'gray.400',
  mb: '1',
  fontWeight: '600',
};

export const FooterMoreOpenText: CSSObject = {
  color: 'alpha.500',
  fontSize: 'xs',
  'div > svg': {
    color: 'beta.500',
  },
};

export const BoxMoreText: CSSObject = {
  w: '8',
  color: 'beta.500',
};

export const FooterBodyText: CSSObject = {
  color: 'gray.300',
};
