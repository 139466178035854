/* eslint-disable import/no-extraneous-dependencies */
import React, { cloneElement, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormErrorMessage,
  FormHelperText,
  Icon,
  FormLabelProps,
  FormErrorMessageProps,
  HelpTextProps,
  CSSObject,
} from '@chakra-ui/react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';
import { AlertCircleOutline } from '@styled-icons/evaicons-outline';
import {
  formLabelError,
  FormLabelThemed,
  FormControlGroup,
} from './FormControl.styles';

export type TFormControl = {
  id: string;
  field?: [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];
  label?: string;
  error?: string;
  helperText?: string;
  propsLabel?: FormLabelProps;
  propsError?: FormErrorMessageProps;
  propsHelper?: HelpTextProps;
  sxWrapper?: CSSObject;
  children: ReactElement;
};

/**
 * The FormControl component will wrap all of our form elements, E.G
 * Radios, Checkboxes, Inputs, Selects, Textarea's, etc.
 *
 * This provides consistent styling & functionality by adding
 * such things as Labels, Error messages & Helper text. It also
 * hooks into Chakra's isInvalid functionality to provide error ui
 * to all parts of the children components.
 */
export default function FormControl({
  id,
  field,
  label,
  error,
  helperText,
  propsLabel,
  propsError,
  propsHelper,
  sxWrapper,
  children,
}: TFormControl) {
  const [, meta] = field ?? [];
  const isInvalid = (meta?.touched && !!meta?.error) || !!error;
  const pattern = /\w+\.\w+(?!\.$)/g;
  const errors = meta?.error || error;
  const isIntl = errors && pattern.test(errors);

  return (
    <FormControlGroup isInvalid={isInvalid} sx={{ ...sxWrapper }}>
      {!!label && (
        <FormLabelThemed htmlFor={id} {...propsLabel}>
          {label}
        </FormLabelThemed>
      )}

      {field ? cloneElement(children, { field }) : children}

      <FormErrorMessage {...formLabelError} {...propsError}>
        <Icon
          as={AlertCircleOutline}
          mr="1"
          pos="relative"
          top="-1px"
          data-cy="error"
        />
        {isIntl ? <FormattedMessage id={errors} /> : errors}
      </FormErrorMessage>
      {!!helperText && (
        <FormHelperText {...propsHelper}>{helperText}</FormHelperText>
      )}
    </FormControlGroup>
  );
}
