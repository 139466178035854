/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, {
  createRef,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { Fade } from '@chakra-ui/react';
import { Animation, TAnimationResponse } from '@/lib/animation';
import { getThemeConfig } from '@/helpers/getThemeConfig';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setBonusChanceModal } from '@/redux/Betslip.slices';
import { useFeatureFlag } from '@/store/FeatureFlagStore';
import { TBetSlipBonusChanceType } from '../../Services/Betslip.types';
import { usePunterBalance } from '../../Services/Betslip.hooks';

const brand = getThemeConfig().name?.toLocaleLowerCase();

/** Types */
type TLuckyDipViewProps = {
  animationData: TAnimationResponse | undefined;
  lottieRef: RefObject<LottieRefCurrentProps>;
  initialSegment: [number, number];
  handleOnComplete: () => void;
};

type TUseLuckyDipResponse = {
  hasLD?: boolean;
} & TLuckyDipViewProps;

/** View */
const LuckyDipView = memo(
  ({
    animationData,
    lottieRef,
    initialSegment,
    handleOnComplete,
  }: TLuckyDipViewProps) => (
    <Fade in>
      <Lottie
        lottieRef={lottieRef}
        animationData={animationData}
        loop={false}
        initialSegment={initialSegment}
        onComplete={handleOnComplete}
        autoPlay
      />
    </Fade>
  )
);

/** Controller */
type TLuckyDipRefsProps = {
  lottieRef: RefObject<LottieRefCurrentProps>;
  hasInitiated: boolean;
  segments: [number, number][];
  onCompleteCount: number;
  bonusChance: Partial<TBetSlipBonusChanceType> | undefined;
  animationLib: Animation | undefined;
  animation: TAnimationResponse | undefined;
  shouldDispatchEvent: boolean;
};

const useLuckyDip = (): TUseLuckyDipResponse => {
  const hasLD = useFeatureFlag('LUCKY_DIP_ENABLED');
  const dispatch = useAppDispatch();
  const bonusChance = useAppSelector((store) => store.betSlip.bonusChance);
  const { isLDVisible } = bonusChance ?? {};
  const { refetch } = usePunterBalance();

  /** State used to trigger re-renders, data being read from luckyDipRefs */
  const [, setAnimation] = useState<TAnimationResponse>();
  const [, setSegments] = useState<[number, number][]>([]);
  const [shouldDispatchEvent, setShouldDispatchEvent] =
    useState<boolean>(false);

  /**
   * References that powers the Lucky Dip animation, guaranteeing
   * consistency across re-renders.
   */
  const luckyDipRefs = useRef<TLuckyDipRefsProps>({
    lottieRef: createRef(),
    hasInitiated: false,
    segments: [],
    onCompleteCount: 0,
    bonusChance: undefined,
    animationLib: undefined,
    animation: undefined,
    shouldDispatchEvent: false,
  });

  /** Storing Bonus Chance data in ref due to re-renders */
  useEffect(() => {
    if (
      bonusChance !== null &&
      luckyDipRefs.current.bonusChance === undefined
    ) {
      luckyDipRefs.current.bonusChance = bonusChance;
    }
  }, [bonusChance]);

  /** Exit event */
  useEffect(
    () => () => {
      dispatch(setBonusChanceModal(null));
    },
    [dispatch]
  );

  /** Initialization hook that sets up the animation and segments */
  useEffect(() => {
    if (luckyDipRefs.current.hasInitiated === false) {
      if (
        luckyDipRefs.current.animationLib === undefined &&
        isLDVisible === true
      ) {
        luckyDipRefs.current.hasInitiated = true;
        const { game } = luckyDipRefs.current.bonusChance ?? {};
        const data = { brand, animation: 'luckydip', isWin: !!game };
        luckyDipRefs.current.animationLib = new Animation(data);

        luckyDipRefs.current.animationLib
          ?.initAnimation()
          .then((animation) => {
            const segments = [
              luckyDipRefs.current.animationLib?.getInitFrames() ?? [0, 0],
              luckyDipRefs.current.animationLib?.getResultFrames() ?? [0, 0],
            ];
            luckyDipRefs.current.segments = segments;
            luckyDipRefs.current.animation = animation;

            setSegments(segments);
            setAnimation(animation);
          })
          .catch(() => undefined);
      }
    }
  }, [isLDVisible]);

  /**
   * Dispatches either the win or lose events and updates the `shouldDispatch`
   * ref to prevent re-renders from causing multiple dispatches.
   * This is managed within a `useEffect` hook to address closures
   * created by `setTimeout`.
   */
  useEffect(() => {
    if (
      shouldDispatchEvent &&
      luckyDipRefs.current.shouldDispatchEvent === false
    ) {
      luckyDipRefs.current.shouldDispatchEvent = true;
      const { bonusChance: bonusChanceRef } = luckyDipRefs.current;
      const { game } = bonusChanceRef ?? {};

      dispatch(
        setBonusChanceModal({ ...bonusChanceRef, isVisible: Boolean(game) })
      );
    }
  }, [dispatch, shouldDispatchEvent]);

  /**
   * Callback function invoked upon completion. It may be executed multiple
   * times, initially for the first segment playback, followed by either
   * the win or lose segment playback.
   */
  const handleOnComplete = useCallback(() => {
    luckyDipRefs.current.onCompleteCount += 1;

    if (luckyDipRefs.current.onCompleteCount === 1) {
      // Play win/lose segment
      const secondarySegment = luckyDipRefs.current.segments?.[1];
      if (secondarySegment?.length) {
        luckyDipRefs.current.lottieRef.current?.playSegments(
          secondarySegment,
          true
        );
      }
    } else if (luckyDipRefs.current.onCompleteCount === 2) {
      // Pause the lottie animation & show the game if a winner
      luckyDipRefs.current.lottieRef.current?.pause();
      setTimeout(() => {
        setShouldDispatchEvent(true);
      }, 2000);
    }
    Promise.all([refetch()]).catch(() => undefined);
  }, [refetch]);

  return {
    lottieRef: luckyDipRefs.current.lottieRef,
    hasLD,
    animationData: luckyDipRefs.current.animation,
    initialSegment: luckyDipRefs.current.segments?.[0],
    handleOnComplete,
  };
};

/** Container */
function LuckyDipContainer() {
  const values = useLuckyDip();
  const { animationData, hasLD, initialSegment } = values;

  if (!animationData || !hasLD || !initialSegment.length) return null;
  return <LuckyDipView {...values} />;
}
export default memo(LuckyDipContainer);
