import { getFeatureFlagStoreActions } from '@/store/FeatureFlagStore';
import { DeepPartial as TDeepPartial } from './types';

export type TFeatureFlags = TDeepPartial<{
  BETBOOST_GAME_ENABLED: string;
  BET_RECOVERY_ENABLED: string;
  BET_STOP_ENABLED: string;
  BONUS_CHANCE_ENABLED: string;
  DEPOSIT_MATCH_ENABLED: string;
  KYC_ON_SIGN_UP_ENABLED: string;
  MOBILE_SPLASH_PAGE_ENABLED: string;
  ODDSBOOST_GAME_ENABLED: string;
  RAAINE_INTERCEPT_RULE_ENABLED: string;
  SGM_ENABLED: string;
  TOKEN_ADD_ENABLED: string;
  WEB_SPLASH_PAGE_ENABLED: string;
  REACT_APP_HAS_NEW_BETSLIP: string;
  ODDS_BOOST_ENABLED_ON_APPROVAL: string;
  PRIMER_ENABLED: string;
  FZ_SAVED_CARDS_DISABLED: string;
  LUCKY_DIP_ENABLED: string;
  MYSTERY_BET_ENABLED: string;
  SPORTS_V3_ENABLED: string;
  TOTE_MULTI_ENABLED: string;
}>;

const { setFeatureFlags } = getFeatureFlagStoreActions();

const fetchFeatureFlags = (endpoint: string) =>
  fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data);

export const updateAndPollFeatureFlags = async (
  endpoint: string,
  // 1 min
  interval = 60000
): Promise<void> => {
  const updateFeatureFlags = async (): Promise<void> => {
    try {
      const featureFlags = await fetchFeatureFlags(endpoint);
      const boolFeatureFlags = Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        Object.entries(featureFlags).map(([key, value]) => [
          key,
          String(value).toLowerCase() === 'true',
        ])
      );
      setFeatureFlags(boolFeatureFlags);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const polling = async (): Promise<void> => {
    try {
      await updateFeatureFlags();
    } catch (error) {
      console.error('Error in polling', error);
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(polling, interval);
    }
  };

  await polling();
};
