import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';
import bgOrange from '@/assets/juicybet/images/backgrounds/orangeBg.png';

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: ['blackAlpha.600', 'alpha.700'],
    fontWeight: 'bold',
  },
  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'white',
    border: '2px',
    borderColor: 'alpha.700',
    color: 'alpha.900',
    borderRightRadius: 'lg',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    _placeholder: {
      color: 'gray.300',
    },
    '&:active, &:focus, &:visited, &:hover': {
      borderColor: 'alpha.600',
      boxShadow: '0px 2px 8px 0px var(--bc-colors-alpha-300)',
      '&&': {
        bg: 'alpha.600',
        borderColor: 'alpha.400',
        border: '2px',
      },
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bg: 'alpha.700',
    borderLeftRadius: 'lg',
    color: 'white',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '3'],
  mx: [null, null, '3'],
  mt: '4',
  bgColor: ['transparent', 'alpha.50', 'alpha.50'],
  bgImage: [bgOrange, 'none', 'none'],
  borderRadius: 'base',
  zIndex: '9',
};

export const InfoHeader: CSSObject = {
  bgGradient: ['unset', 'linear(to-b, alpha.400, alpha.500)'],
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: ['blackAlpha.600', 'white'],
  fontWeight: 'medium',
  lineHeight: '4',
};

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
};

export const TextAddToBetslipButton: CSSObject = {
  letterSpacing: 'wider',
  w: '240px',
  fontWeight: 'normal',
};
