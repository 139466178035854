import * as sterlingparker from './LinkItem.styles.sterlingparker';
import * as volcanobet from './LinkItem.styles.volcanobet';
import * as betprofessor from './LinkItem.styles.betprofessor';
import * as puntgenie from './LinkItem.styles.puntgenie';
import * as junglebet from './LinkItem.styles.junglebet';
import * as fiestabet from './LinkItem.styles.fiestabet';
import * as puntcity from './LinkItem.styles.puntcity';
import * as betgalaxy from './LinkItem.styles.betgalaxy';
import * as betroyale from './LinkItem.styles.betroyale';
import * as juicybet from './LinkItem.styles.juicybet';
import * as goldenrush from './LinkItem.styles.goldenrush';
import * as vikingbet from './LinkItem.styles.vikingbet';
import * as questbet from './LinkItem.styles.questbet';
import * as wellbet from './LinkItem.styles.wellbet';
import * as sugarcastle from './LinkItem.styles.sugarcastle';
import * as oldgill from './LinkItem.styles.oldgill';
import * as buffalobet from './LinkItem.styles.buffalobet';
import * as gigabet from './LinkItem.styles.gigabet';
import * as slambet from './LinkItem.styles.slambet';
import * as templebet from './LinkItem.styles.templebet';
import * as goldenbet888 from './LinkItem.styles.goldenbet888';

import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  sterlingparker,
  volcanobet,
  betprofessor,
  puntgenie,
  junglebet,
  fiestabet,
  puntcity,
  betgalaxy,
  betroyale,
  juicybet,
  goldenrush,
  vikingbet,
  questbet,
  wellbet,
  sugarcastle,
  oldgill,
  buffalobet,
  gigabet,
  slambet,
  templebet,
  goldenbet888,
};
