import * as betprofessor from './Form.styles.betprofessor';
import * as fiestabet from './Form.styles.fiestabet';
import * as sugarcastle from './Form.styles.sugarcastle';
import * as oldgill from './Form.styles.oldgill';
import * as buffalobet from './Form.styles.buffalobet';
import * as goldenrush from './Form.styles.goldenrush';
import * as juicybet from './Form.styles.juicybet';
import * as junglebet from './Form.styles.junglebet';
import * as betroyale from './Form.styles.betroyale';
import * as gigabet from './Form.styles.gigabet';
import * as slambet from './Form.styles.slambet';
import * as luckystride from './Form.styles.luckystride';
import * as templebet from './Form.styles.templebet';
import * as goldenbet888 from './Form.styles.goldenbet888';
import { EThemesOG } from '@/constants/themeConfig';

export const styleImports: Partial<Record<EThemesOG, any>> = {
  betroyale,
  betprofessor,
  fiestabet,
  sugarcastle,
  oldgill,
  buffalobet,
  goldenrush,
  juicybet,
  junglebet,
  gigabet,
  slambet,
  luckystride,
  templebet,
  goldenbet888,
};
