import { CSSObject } from '@chakra-ui/react';
import { BreadcrumbSchema } from './Breadcrumb.styles';

export const breadcrumbStyles: BreadcrumbSchema = {
  breadcrumbWrapper: {
    border: '2px',
    borderColor: 'whiteAlpha.800',
    borderRadius: 'base',
    bg: 'whiteAlpha.500',
    mb: '3',
    px: '2',
    h: '7',
  },
  breadcrumbItemBreadcrumb: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
  breadcrumbLinkItem: {
    color: 'alpha.700',
    sx: {
      '&:last-of-type': {
        textDecoration: 'none',
      },
    },
  },
};

export const ChevronRightIcon: CSSObject = {
  color: 'alpha.700',
};
