import { CSSObject } from '@chakra-ui/react';
import { CustomButtonProps } from '@/components/Button/Button';

export const buttonAddToBetslipProps: CustomButtonProps = {
  variant: 'secondary',
  sx: {
    '&&': {
      h: 10,
    },
  },
};

export const FlexWrapper: CSSObject = {
  px: ['3', null, '2.5'],
  '&&': {
    py: '3',
  },
};

export const FlexInputWrapper: CSSObject = {
  label: {
    color: 'alpha.700',
    fontWeight: 'bold',
  },
  '.chakra-input': {
    transition: '.3s ease-in-out',
    bg: 'alpha.400',
    border: '2px',
    borderColor: 'alpha.700',
    color: 'white',
    borderRightRadius: 'lg',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    fontWeight: 'semibold',
    _placeholder: {
      color: 'alpha.700',
    },
    '&:active, &:focus, &:visited': {
      color: 'white',
      bg: 'alpha.600',
      border: '2px',
      borderColor: 'alpha.700',
    },
  },

  '.chakra-input__left-addon': {
    border: 'none',
    fontWeight: 'extrabold',
    bg: 'alpha.700',
    borderLeftRadius: 'lg',
    color: 'white',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.25)',
  },
};

export const SelectionPopupContainer: CSSObject = {
  my: [null, null, '4'],
  mx: [null, null, '4'],
  mt: '4',
  bg: 'zeta.400',
  borderRadius: 'base',
  zIndex: 'docked',
};

export const InfoHeader: CSSObject = {
  bg: 'var(--local-gradient-brown, linear-gradient(180deg, #8D4522 0%, #76391C 100%))',
  h: '7',
  px: '2',
  py: '1.5',
};

export const InfoText: CSSObject = {
  fontSize: 'xs',
  color: 'white',
  fontWeight: 'medium',
};

export const TextAddToBetslipButton: CSSObject = {
  letterSpacing: 'wider',
  w: '240px',
  fontWeight: 'normal',
};
