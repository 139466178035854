import { ButtonProps, theme } from '@chakra-ui/react';
import CloseButton from '@/assets/goldenbet888/images/close-filter.svg';

const primary = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
  bg: 'gamma.300',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  color: 'blackAlpha.700',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  _hover: {
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
    bg: 'gamma.300',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    color: 'blackAlpha.700',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.08), 0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.500',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    bgGradient: 'linear(180deg, gamma.500 0%, gamma.600 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(117, 20, 16, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  },

  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const secondary = {
  borderRadius: 'md',
  bgGradient: 'linear(180deg, alpha.200 0%, alpha.400 100%)',
  boxShadow:
    '0px 0px 0px 2px rgba(190, 40, 44, 1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.65) inset, 0px 0px 0px 3px rgba(255, 212, 113, 1) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'gamma.200',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bgGradient: 'linear(180deg, alpha.600 0%, alpha.600 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(190, 40, 44, 1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.65) inset, 0px 0px 0px 3px rgba(255, 212, 113, 1) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'gamma.200',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'gamma.200',
    textShadow: 'none',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
    boxShadow:
      '0px 0px 0px 2px rgba(190, 40, 44, 1) inset, 0px 0px 0px 1px rgba(255, 255, 255, 0.65) inset, 0px 0px 0px 3px rgba(255, 212, 113, 1) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  },

  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const outline = {
  borderRadius: 'md',
  border: '2px',
  borderColor: 'gamma.500',
  boxShadow: 'none',
  color: 'gamma.200',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  _hover: {
    bg: 'rgba(255, 255, 191, 0.2)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    border: '2px',
    borderColor: 'gamma.500',
  },
  _disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
};

const quickAccess = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  color: 'blackAlpha.700',
  fontFamily: 'accentStaat',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  _hover: {
    bgGradient: 'linear(to-b, beta.500 0%, beta.500 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    color: 'blackAlpha.700',
  },
  ':active, &[data-active="true"], &[aria-expanded="true"]': {
    bg: 'gamma.200',
    boxShadow: 'none',
    color: 'alpha.800',
    textShadow: 'unset',
    borderBottomRadius: 'none',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const bets = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, beta.300 0%, beta.400 100%)',
  color: 'blackAlpha.700',
  fontFamily: 'accentStaat',
  lineHeight: 'normal',
  fontWeight: 'normal',
  fontSize: 'sm',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
  boxShadow:
    '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
  _hover: {
    bgGradient: 'linear(to-b, beta.500 0%, beta.500 100%)',
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.35)',
    color: 'blackAlpha.700',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.700',
    boxShadow:
      '0px 2px 0px 0px rgba(0, 0, 0, 0.3) inset, 0px 1px 0px 0px rgba(255, 255, 255, 0.08)',
    color: 'beta.300',
    textShadow: 'unset',
  },
  _disabled: {
    opacity: 0.4,
    boxShadow:
      '0px 0px 0px 2px rgba(255, 192, 51, 1) inset, 0px 0px 0px 3px rgba(0, 77, 48, 1) inset, 0px 0px 0px 4px rgba(255, 255, 255, 0.6) inset, 0px 6px 10px 0px rgba(0, 0, 0, 0.2)',
    pointerEvents: 'none',
  },
  '&&&': {
    '& > svg, & > span': { zIndex: 9 },
  },
};

const odds = {
  borderRadius: 'md',
  bgGradient: 'linear(to-b, gamma.500 0%, gamma.600 100%)',
  boxShadow:
    '0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
  color: 'gamma.900',
  fontFamily: 'accentStaat',
  fontSize: 'sm',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
  _hover: {
    bg: 'gamma.300',
    bgGradient: 'linear(to-b, gamma.300 0%, gamma.300 100%)',
    boxShadow:
      '0px 0px 0px 1px rgba(255, 255, 255, 0.35) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'gamma.900',
    fontFamily: 'accentStaat',
    textShadow: '0px 1px 0px rgba(255, 255, 255, 0.4)',
  },
  ':active, &[data-active="true"]': {
    bg: 'blackAlpha.500',
    boxShadow:
      '0px 1px 0px 0px rgba(255, 255, 255, 0.15), 0px 2px 4px 0px rgba(0, 0, 0, 0.3) inset',
    color: 'gamma.500',
    textShadow: 'unset',
  },
  _disabled: {
    bg: 'blackAlpha.400',
    color: 'rgba(255, 255, 191, 0.6)',
    textShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    boxShadow: 'none',
    pointerEvents: 'none',
    opacity: '1',
  },
  '&&&': {
    '& > span, & > svg': { zIndex: 9 },
  },
};

const tab = {
  boxShadow: 'none',
  fontSize: 'sm',
  textShadow: 'none',
  fontWeight: 'normal',
  borderRadius: 'none',
  fontFamily: 'accentStaat',
  h: '8',
  textTransform: 'uppercase',
  color: 'gamma.200',
  borderBottom: '3px solid',
  borderBottomColor: 'transparent',
  px: '3',
  pt: '1',
  _hover: {
    color: 'gamma.500',
  },
  ':active, &[data-active="true"]': {
    color: 'gamma.500',
    fontWeight: 'normal',
    borderBottom: '3px solid',
    borderBottomColor: 'gamma.500',
  },
};

const filter = {
  borderRadius: 'base',
  color: 'gamma.200',
  fontSize: '2xs',
  fontWeight: 'extrabold',
  h: '7',
  minW: 'auto',
  p: '2',
  mt: '0.5',
  textTransform: 'uppercase',
  border: '2px',
  borderColor: 'gamma.500',
  bg: 'alpha.700',
  _hover: {
    color: 'alpha.700',
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
  },
  _active: {
    bgGradient: 'linear(to-b, gamma.300, gamma.400)',
    color: 'alpha.700',
    border: 'none',
    '&:after': {
      border: 'none',
      content: '""',
      position: 'absolute',
      display: 'block',
      boxSize: '14px',
      top: '7px',
      right: '2',
      borderRadius: 'full',
      pr: '2',
      backgroundImage: `url(${CloseButton})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
};

const segment = {
  borderRadius: 'base',
  fontSize: '2xs',
  fontWeight: 'bold',
  h: '5',
  px: '3',
  py: '3px',
  textTransform: 'uppercase',
  bg: 'transparent',
  _hover: {
    color: 'alpha.400',
  },
  _active: {
    bg: 'alpha.400',
    color: 'alpha.700',
  },
};

export const Button = {
  baseStyle: {},
  sizes: {},
  variants: {
    solid: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    primary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...primary,
    }),
    secondary: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.solid(props as any),
      ...secondary,
    }),
    outline: (props: ButtonProps) => ({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...theme.components.Button.variants.outline(props as any),
      ...outline,
    }),
    quickAccess: () => ({
      ...quickAccess,
    }),
    bets: () => ({
      ...bets,
    }),
    odds: () => ({
      ...odds,
    }),
    tab: () => ({
      ...tab,
    }),
    filter: () => ({
      ...filter,
    }),
    segment: () => ({
      ...segment,
    }),
    unstyled: () => ({}),
  },
  defaultProps: { colorScheme: null },
};
