import { CSSObject } from '@chakra-ui/react';
import { RaceDetailsSchema } from '@/views/races/RaceDetails/styles/RaceDetails.styles';

export const raceDetailsStyles: RaceDetailsSchema = {
  buttonStyledGroupedProps: {
    boxSize: '30px',
    variant: 'numb',
    boxShadow:
      '0px -3px 4px 0px rgba(0, 0, 0, 0.20) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset',
    sx: {
      '&&&': {
        textShadow: 'box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5)',
        h: '30px',
        w: '30px',
        minW: '30px',
        px: '0',
      },
      '&[data-hasPromo="true"]': {
        minW: 12,
        '.btn-span': {
          pl: 0.5,
        },
      },
      '&:active, &[data-active="true"]': {
        '.btn-span': {
          color: 'beta.400',
          textShadow: '0px 1px 2px rgba(0, 0, 0, 0.50)',
        },
      },
    },
  },
};

export const ButtonGroupWrapper: CSSObject = {
  gap: '1',
};

export const CountdownTimerContainer: CSSObject = {
  '.chakra-badge': {
    boxSizing: 'content-box',
  },
};

export const RaceAndTimerContainer: CSSObject = {
  borderRadius: 'base',
  bg: ['transparent', 'transparent', 'blackAlpha.400'],
  boxShadow: [
    null,
    null,
    '0px 1px 0px 0px rgba(255, 255, 255, 0.10), 0px 2px 5px 0px rgba(0, 0, 0, 0.35) inset',
  ],
};

export const TextRaceConditions: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'normal',
  fontFamily: 'Roboto',
  textShadow: 'none',
  _after: {
    content: `""`,
    boxSize: '1',
    borderRadius: 'full',
    bg: 'alpha.800',
    pos: 'relative',
    mx: '1.5',
  },
  span: {
    fontWeight: 'black',
    mr: [0.5, 1],
  },
};

export const ResultedStatusLabel: CSSObject = {
  color: 'alpha.700',
  bg: 'beta.100',
  fontFamily: 'roboto',
  textShadow: 'none',
};

export const RaceStatusText: CSSObject = {
  color: 'white',
  bg: 'red.700',
  fontFamily: 'roboto',
};

export const RaceDetailsHeading: CSSObject = {
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'normal',
  svg: {
    color: 'white',
  },
};

export const RaceRunnerListWrapper: CSSObject = {
  m: '0',
  py: '2',
  pt: '0',
};

export const RaceRunnerList: CSSObject = {
  borderRadius: 'base',
  bg: 'white',
  p: '2.5',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
};

export const TextTitle: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const RaceName: CSSObject = {
  fontFamily: 'Roboto',
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  textTransform: 'capitalize',
};

export const InfoContainer: CSSObject = {
  mb: '-2',
  mt: '1',
  pb: '2.5',
};

export const RaceRunnerHeadingContainer: CSSObject = {
  alignItems: 'center',
  columnGap: '0',
  '.chakra-switch__track': {
    background: 'gray.400',
    '& .chakra-switch__thumb': {
      background: 'white',
    },
    '&[data-checked]': {
      bgGradient: 'linear(to-b, alpha.500, alpha.600)',
    },
  },
  svg: {
    color: 'gray.700',
  },
};

export const RaceRunnerListHeadings: CSSObject = {
  minH: '36px',
  mx: '2',
  pb: '2',
  alignItems: 'center',
};

export const RaceRunnerPropositionHeadingContainer: CSSObject = {
  '&&': {
    px: '0',
    w: '12',
  },
  svg: {
    color: 'gray.700',
  },
};
