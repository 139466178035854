import { CSSObject } from '@chakra-ui/react';

export const FormsHStack: CSSObject = {
  px: '2',
  pb: '0',
  '&&': {
    mt: '2.5',
  },
};

export const ThemedInputAddon = (isPrefix: boolean): CSSObject => ({
  transition: 'all .2s ease-in-out',
  bgGradient: 'linear(to-b, alpha.400, alpha.700)',
  color: 'black',
  fontSize: 'sm',
  mb: '1.5',
  h: '9',
  borderLeftRadius: isPrefix ? 'md' : 'none',
  borderRightRadius: isPrefix ? 'none' : 'md',
});

export const ReviewContainer: CSSObject = {
  px: '1',
  borderRadius: 'md',
  bg: 'blackAlpha.400',
  boxShadow: '0 1px 0 0 #5e5f6e, inset 0 1px 4px 0 #0000004c',
  textTransform: 'uppercase',
};

export const BoxReviewStakeContainer: CSSObject = {
  borderBottom: '1px dashed',
  borderColor: 'whiteAlpha.200',
};

export const ReviewStakeText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewStake: CSSObject = {
  fontSize: 'md',
  color: 'alpha.600',
  fontWeight: 'extrabold',
};

export const ReviewEstText: CSSObject = {
  fontSize: 'xs',
  fontWeight: 'bold',
};

export const ReviewEst: CSSObject = {
  fontSize: 'xl',
  color: 'alpha.600',
  fontWeight: 'extrabold',
};

export const InputBetSlip: CSSObject = {
  bg: 'beta.600',
  marginTop: '-0.5',
  borderWidth: '2px',
  borderLeftColor: 'alpha.500',
  borderRightColor: 'alpha.500',
  borderTopColor: 'alpha.400',
  borderBottomColor: 'alpha.700',
  color: 'white',
  pl: '2.75rem',
  borderRadius: 'md',
  fontSize: 'sm',
  ':first-child': {
    pl: '3',
  },
  _hover: {
    '&&': {
      bg: 'blackAlpha.200',
      borderLeftColor: 'alpha.500',
      borderRightColor: 'alpha.500',
      borderTopColor: 'alpha.400',
      borderBottomColor: 'alpha.700',
    },
    '.chakra-input__left-element': {
      bg: 'epsilon.300',
    },
  },
  _focus: {
    borderColor: 'alpha.500',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.3)',
    '&&': {
      background: 'epsilon.300',
    },
    '&::placeholder': { color: 'white', opacity: '0.8' },
  },
  '&::placeholder': { color: 'white', opacity: '0.5' },
};

export const FlexiReview: CSSObject = {
  pl: '1',
};
